import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ADD_NEW, STR_ALL, STR_AVAILABLE_STOCK, STR_BALANCE, STR_BILLS, STR_BONUS_MANAGEMENT, STR_CLEARED, STR_CLIENTS, STR_CUSTOMER_DEPOSITS, STR_DASHBOARD, STR_FILTER_BY_NAME, STR_FILTER_BY_NAME_OR_PHONE, STR_LEADS, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_MY_CUSTOMERS, STR_NO_RECORDS, STR_PAID, STR_PENDING, STR_PROCESSED, STR_SALES_ALL, STR_SALES_RECORDS, STR_SALES_TODAY, STR_STOCKLIST, STR_TODOS, STR_UNCLEARED, STR_UNPAID, STR_UNPROCESSED, STR_WATEJA } from "../Strings";
import { MdSearch } from "react-icons/md";
import OneRecord from '../ones/OneRecord';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [filter, setFilter] = useState("");
  const [status, setStatus] = useState("all");

  const [balance, setBalance] = useState(0);


  async function init() {
    //run initializations here
    setReady(false);

    await Promise.all([
      appContext.getAllCustomerDeposits(),
    ])

    setReady(true);
  }

  function getBalance() {
    let _balance = 0;

    if (appContext.allCustomerDeposits) {
      for (const _rec of appContext.allCustomerDeposits) {
        _balance += Number(_rec.depositBalance);

      }
    }
    setBalance(_balance);
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    getBalance();
  }, [appContext.allCustomerDeposits])

  if (ready) {
    return (
      <div
        className="container"

      >
        <h6 className="mSectionTitle">{STR_CUSTOMER_DEPOSITS[appContext.language]}</h6>

        <div className="row">
          <div className="col-md-12 col-12">
            <div className="card">
              <div className="card-body">
                <h6
                  style={{
                    fontSize: "30px",
                  }}
                  className="font-bold text-secondary"
                >
                  {formatMoney(balance)} <span style={{ fontSize: "14px" }}>TZS</span>
                  <span className="font-regular text-muted" style={{ display: "block", fontSize: "16px" }}>{STR_BALANCE[appContext.language]} </span>
                </h6>


              </div>
            </div>
          </div>





          <div className="col-12 col-md-12 mb-3 mt-3">
            <div className="row mb-4">

              <div
                className="col-md-3 mt-1 mb-1"
              >
                <div
                  className="input-group"
                >
                  <span
                    className="input-group-text font-semi-bold text-secondary"
                    style={{
                      fontSize: "22px"
                    }}
                  >
                    <MdSearch size={24} />
                  </span>
                  <input
                    className="form-control"
                    onChange={(e) => setFilter(e.target.value)}
                    value={filter}
                    placeholder={STR_FILTER_BY_NAME[appContext.language]}
                  />
                </div>
              </div>

            </div>

            {
              (appContext.allCustomerDeposits && appContext.allCustomerDeposits.length > 0) ?
                appContext.allCustomerDeposits.map((item, i) => {
                  if (filter && filter.trim().length > 0) {
                    if (
                      (
                        String(item.name).toUpperCase().indexOf(String(filter).toUpperCase()) > -1 ||
                        String(item.phone).toUpperCase().indexOf(String(filter).toUpperCase()) > -1
                      ) &&
                      (status === 'all' || item.status === status) // Add status filtering logic
                    ) {
                      return <OneRecord type="customer-deposit" data={item} key={i} />;
                    } else {
                      return null;
                    }
                  } else {
                    // When no filter is applied, still filter based on status
                    if (status === 'all' || item.status === status) {
                      return <OneRecord type="customer-deposit" data={item} key={i} />;
                    } else {
                      return null;
                    }
                  }

                })
                : <h6 style={{ fontSize: "14px", }}>{STR_NO_RECORDS[appContext.language]}</h6>
            }

          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}