import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatDate, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ACCOUNTS_RECEIVABLE, STR_AMOUNT_PAID, STR_ASK_CONTINUE, STR_BALANCE, STR_CALL, STR_CUSTOMER_WILL_BE_CLIENT, STR_DASHBOARD, STR_DATE, STR_EDIT, STR_INITIAL_AMOUNT, STR_INVALID_AMOUNT, STR_INVALID_DATE, STR_MARK_AS_COMPLETED, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_PAYABLE, STR_PAYMENTS, STR_PROMISE, STR_PROMISE_DUE_DATE, STR_PURCHASES, STR_RECEIVABLE, STR_RECORD_PAYMENTS, STR_REPORT_FOLLOWUP, STR_REPORT_SALES, STR_REPORT_VISIT, STR_SEND_EMAIL, STR_SEND_SMS, STR_SUBMIT, STR_SUCCESS, STR_TODOS, STR_TURN_TO_CLIENT, STR_VIEW_MORE_INFO, STR_VISITS, STR_WATEJA } from "../Strings";
import CustomersHome from '../views/CustomersHome';
import { MdCall, MdCheckCircle, MdEdit, MdEmail, MdFacebook, MdMessage, MdOutlineFacebook, MdOutlineWhatsapp, MdWhatsapp } from "react-icons/md";
import { BiInfoCircle, BiLogoLinkedin, BiLogoWhatsapp, BiPurchaseTag } from "react-icons/bi";
import { SlUserFollowing } from "react-icons/sl";
import { BsInstagram, BsTelegram } from "react-icons/bs";
import CustomerWidget from '../ones/CustomerWidget';

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);


  async function init() {
    //run initializations here
    setReady(false);
    await getData()
    setReady(true);
  }

  async function getData() {
    await callApi("get-todo-data", { id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }

  async function markAsCompleted() {
    setLoading(true)
    await callApi("mark-todo-as-completed", { id }).then(response => {
      if (response.status === 1) {
        getData();
        appContext.getMyTodos();
      } else {
        appContext.tellError(response.msg);
      }
    })
    setLoading(false);
  }


  useEffect(() => {
    init();
  }, [id])


  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready && data) {
    return (
      <div
        className="container mw-700"
      >
        <div className="text-center">
          <span className="badge bg-secondary" style={{ fontSize: "22px" }}>{formatDate(data.dateDue).substring(0, 10)}</span>
        </div>
        <h6
          className="mSectionTitle"
        >
          {data.title}
        </h6>
        <hr />


        <div className="row">
          <div className="col-md-12 mb-2 mt-2">
            <h6
              className='text-start font-regular'
              style={{
                fontSize: "18px",
                borderLeft: "6px solid var(--primaryColor)",
                paddingLeft: "5px"
              }}
            >
              {data.description}
            </h6>
          </div>

          {
            (data.status === 'pending') ?
              <div className="col-md-12 mb-2 mt-2">
                <button
                  className="m-2 btn btn-block btn-rounded btn-secondary btn-padded"
                  onClick={() => {
                    appContext.activateDialog({
                      message: STR_MARK_AS_COMPLETED[appContext.language] + "?",
                      onConfirm: markAsCompleted,
                    })
                  }}
                >
                  {STR_MARK_AS_COMPLETED[appContext.language]}
                </button>
              </div>
              : ""
          }

        </div>

      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}