const MobileInterface = {
  openLink(url) {

    if (window.MobileInterface) {
      //use android interface
      window.MobileInterface.openLink(url)
    } else {
      //try ios interface
      try {
        window.webkit.messageHandlers.openLink.postMessage(url);
      } catch (error) {
        //fallback
        window.open(url, '_blank');
      }
    }
  },

  setOneSignalExternalUserId(id) {

    if (window.MobileInterface) {
      //use android interface
      window.MobileInterface.setOneSignalExternalUserId(id);
    } else {
      //try ios interface
      try {
        window.webkit.messageHandlers.setOneSignalExternalUserId.postMessage(id);
      } catch (error) {
        //fallback
        //erro
      }
    }
  },
}

export default MobileInterface;