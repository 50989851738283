import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ACCOUNTANT, STR_ADD_NEW, STR_ADD_STOCK, STR_ADD_USER, STR_AMOUNT, STR_AVAILABLE_QUANTITY, STR_BUSINESS, STR_BUSINESS_NAME, STR_CASHIER, STR_CLIENT, STR_COACH, STR_COMPANY, STR_CREATE_PAYABLE, STR_CREATE_RECEIVABLE, STR_CUSTOMER_LEVEL, STR_CUSTOMER_TYPE, STR_DASHBOARD, STR_DATE, STR_DESCRIPTION, STR_EMAIL_ADDRESS, STR_GOVERNMENT, STR_INCLUDE_COUNTRY_CODE_ON_PHONE, STR_INCLUDE_LINK_TO_PROFILE, STR_INVALID_AMOUNT, STR_INVALID_BUSINESS_NAME, STR_INVALID_DATE, STR_INVALID_NAME, STR_INVALID_PHONE, STR_INVALID_TITLE, STR_INVALID_UNIT, STR_INVALID_UNIT_PRICE, STR_LEAD, STR_LOCATION, STR_MANAGER, STR_MARKETER, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NAME, STR_NO_BUSINESS_WARNING, STR_NOTES, STR_OTHER_COMM_CHANNELS, STR_OWNER, STR_PERSON, STR_PHONE_NUMBER, STR_PLEASE_WAIT, STR_RECORD_CAPITAL, STR_RECORD_OPERATION_COST, STR_ROLE, STR_SELLER, STR_START_FREE_TRIAL, STR_SUBMIT, STR_SUCCESS, STR_TITLE, STR_TITLE_RECORD_CUSTOMER, STR_TITLE_REGISTER_BUSINESS, STR_TODOS, STR_UNIT, STR_UNIT_PRICE, STR_WATEJA } from "../Strings";
import { TiWarningOutline } from "react-icons/ti";
import { MdClose } from "react-icons/md";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);

  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("manager");
  const [username, setUsername] = useState("");


  async function init() {
    //run initializations here
    setReady(false);
    //..

    setReady(true);
  }

  async function record() {
    if (!loading) {
      if(username.trim().length > 0) {
        if (phone.trim().length === 10) {
          setLoading(true);
          await callApi(
            'add-user-to-business',
            {
              phone: `255${parseInt(phone, 10)}`,
              businessId: appContext.currentBusinessId,
              role,
              username,
            }
          ).then(response => {
            if (response.status === 1) {
              appContext.tellMessage(STR_SUCCESS[appContext.language]);
              appContext.getBusinessUsers();
              appContext.navBack();
            } else {
              appContext.tellError(response.msg);
            }
          })
          setLoading(false);
        } else {
          appContext.tellError(STR_INVALID_PHONE[appContext.language]);
        }
      } else {
        appContext.tellError(STR_INVALID_NAME[appContext.language]);
      }
    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language]);
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready) {
    return (

      <div className="container mw-700">
        <h6 className="mSectionTitle">{STR_ADD_USER[appContext.language]}</h6>

        <div className="row">

          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_PHONE_NUMBER[appContext.language]}*</label>
            <input
              className="form-control"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              maxLength={10}
              placeholder="07XXXXXXXXXX or 06XXXXXXXX"
              type="text"
            />
          </div>

          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_NAME[appContext.language]}*</label>
            <input
              className="form-control"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
            />
          </div>

          <div className="col-md-12 mb-2 mt-2">
            <label>{STR_ROLE[appContext.language]}</label>
            <select
              className="form-control"
              value={role}
              onChange={(e) => {
                setRole(e.target.value);
              }}
            >
              <option value="manager">{STR_MANAGER[appContext.language]}</option>
              <option value="owner">{STR_OWNER[appContext.language]}</option>
              <option value="seller">{STR_SELLER[appContext.language]}</option>
              <option value="marketer">{STR_MARKETER[appContext.language]}</option>
              <option value="cashier">{STR_CASHIER[appContext.language]}</option>
              <option value="accountant">{STR_ACCOUNTANT[appContext.language]}</option>
              <option value="coach">{STR_COACH[appContext.language]}</option>
            </select>
          </div>

          <div className="col-md-12 mb-4 text-end">
            <button
              className="btn btn-primary btn-padded btn-rounded"
              onClick={record}
            >
              {STR_SUBMIT[appContext.language]}
            </button>
          </div>

        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}