import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_CALL, STR_CHANGE_PASSWORD, STR_CREATE_GOAL, STR_CREATE_TODO, STR_DASHBOARD, STR_EDIT, STR_MANAGE_ACTIVE_GOAL, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_REMOVE, STR_REPORT_FOLLOWUP, STR_REPORT_SALES, STR_REPORT_VISIT, STR_SEND_EMAIL, STR_SEND_SMS, STR_SUCCESS, STR_TODOS, STR_TURN_TO_CLIENT, STR_VIEW_ACTIVITIES, STR_VIEW_MORE_INFO, STR_WATEJA } from "../Strings";
import CustomersHome from '../views/CustomersHome';
import { MdAnalytics, MdBlock, MdCall, MdCheckCircle, MdEdit, MdEmail, MdFacebook, MdMessage, MdOutlineFacebook, MdOutlineWhatsapp, MdPassword, MdTask, MdWhatsapp } from "react-icons/md";
import { BiInfoCircle, BiLogoLinkedin, BiLogoWhatsapp, BiPurchaseTag } from "react-icons/bi";
import { SlUserFollowing } from "react-icons/sl";
import { BsInstagram, BsTelegram } from "react-icons/bs";
import { TbTarget } from "react-icons/tb";

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    await getData()
    setReady(true);
  }

  async function getData() {
    await callApi("get-user-data", { uid: id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }

  function viewActivities() {
    appContext.navTo({
      item: 'more',
      subItem: 'business_user_activities',
      extraItem: data.id,
    })
  }


  function createGoal() {

  }

  async function removeUser() {
    setLoading(true);
    await callApi(
      "remove-business-user",
      {
        businessUserId: data.id,
        businessId: appContext.currentBusinessId,
      }
    ).then(response => {
      if(response.status === 1) {
        appContext.tellMessage(STR_SUCCESS[appContext.language]);
        appContext.getBusinessUsers();
        appContext.navBack();
      } else {
        appContext.tellError(response.msg);
      }
    })
    setLoading(false);
  }

  function manageActiveGoal() {
    appContext.navTo({
      item: 'view',
      subItem: 'manage-active-user-goal',
      extraItem: data.uid,
    })
  }

  

  useEffect(() => {
    init();
  }, [id])

  useEffect(() => {
    //..
  }, [data])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  if (ready && data) {
    return (
      <div
        className="container mw-700"
      >
        <h6
          className="font-semi-bold mt-4"
          style={{
            fontSize: "24px",
            textTransform: "capitalize",
          }}
        >
          {appContext.getBusinessUserInfo(data).username}
        </h6>
        <h6
          className="font-semi-bold text-muted"
          style={{
            fontSize: "18px",
            textTransform: "capitalize",
          }}
        >
          {data.phone}
        </h6>

        <span
          className='badge bg-success'
        >
          {appContext.getBusinessUserInfo(data).role}
        </span>


        <div className="row mt-4">

          <div className="col-4 col-md-3 mb-2 mt-2">
            <button
              className="btn btn-sm btn-block"
              onClick={manageActiveGoal}
              style={{
                height: "100%",
              }}
            >
              <TbTarget size={30} /><br />
              {STR_MANAGE_ACTIVE_GOAL[appContext.language]}
            </button>
          </div>



          <div className="col-4 col-md-3 mb-2 mt-2">
            <button
              className="btn btn-sm btn-block"
              onClick={() => {
                appContext.activateDialog({
                  message: STR_REMOVE[appContext.language],
                  onConfirm: removeUser,
                })
              }}
              style={{
                height: "100%",
              }}
            >
              <MdBlock size={30} /><br />
              {STR_REMOVE[appContext.language]}
            </button>
          </div>

          <div className="col-4 col-md-3 mb-2 mt-2" style={{ display: "none" }}>
            <button
              className="btn btn-sm btn-block"
              onClick={createGoal}
              style={{
                height: "100%",
              }}
            >
              <MdEdit size={30} /><br />
              {STR_EDIT[appContext.language]}
            </button>
          </div>

          <div className="col-4 col-md-3 mb-2 mt-2" style={{ }}>
            <button
              className="btn btn-sm btn-block"
              onClick={viewActivities}
              style={{
                height: "100%",
              }}
            >
              <MdAnalytics size={30} /><br />
              {STR_VIEW_ACTIVITIES[appContext.language]}
            </button>
          </div>

        </div>

      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}