import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ADD_NEW, STR_BUSINESS, STR_BUSINESS_NAME, STR_CLIENT, STR_COMPANY, STR_CUSTOMER_LEVEL, STR_CUSTOMER_TYPE, STR_DASHBOARD, STR_EMAIL_ADDRESS, STR_ENTRANCE_DATE, STR_GOVERNMENT, STR_INCLUDE_COUNTRY_CODE_ON_PHONE, STR_INCLUDE_LINK_TO_PROFILE, STR_INVALID_BUSINESS_NAME, STR_INVALID_ENTRANCE_DATE, STR_INVALID_NAME, STR_INVALID_PHONE, STR_LEAD, STR_LOCATION, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NAME, STR_NO_BUSINESS_WARNING, STR_NOTES, STR_OTHER_COMM_CHANNELS, STR_PERSON, STR_PHONE_NUMBER, STR_PLEASE_WAIT, STR_REFERRER, STR_RETAIL, STR_SOURCE, STR_START_FREE_TRIAL, STR_SUBMIT, STR_SUCCESS, STR_TITLE_RECORD_CUSTOMER, STR_TITLE_REGISTER_BUSINESS, STR_TODOS, STR_WATEJA, STR_WHOLESALE } from "../Strings";
import { TiWarningOutline } from "react-icons/ti";
import { MdClose } from "react-icons/md";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [source, setSource] = useState("");
  const [referrerId, setReferrerId] = useState("");
  const [recordedDate, setRecordedDate] = useState("");
  const [customerType, setCustomerType] = useState("retail");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [customerLevel, setCustomerLevel] = useState("lead");
  const [notes, setNotes] = useState("");
  const [otherCommunicationChannels, setOtherCommunicationChannels] = useState([]);

  const commChannelTemp = {
    id: JSON.parse(appContext.systemParams.supportedCommunicationChannels)[0],
    value: "",
  };

  async function init() {
    //run initializations here
    setReady(false);
    //..

    setReady(true);
  }

  function addCommunicationChannel() {
    setOtherCommunicationChannels(_prev => {
      const _new = [..._prev];
      _new.push(commChannelTemp);

      return _new;
    })
  }

  function deleteCommunicationChannel(_index) {
    setOtherCommunicationChannels(_prev => {
      const _new = [..._prev];
      _new.splice(_index, 1);

      return _new;
    })
  }

  function handleCommunicationChannelValueChange(_index, _field, _value) {
    setOtherCommunicationChannels(_prev => {
      const _new = [..._prev];
      _new[_index][_field] = _value;

      return _new;
    })
  }

  function cleanCommunicationChannels(_channels) {
    const _cleaned = [];

    for (const _channel of _channels) {
      if (
        _channel.id &&
        _channel.id.trim().length > 0 &&
        _channel.value &&
        _channel.value.trim().length > 0
      ) {
        _cleaned.push(_channel);
      }
    }

    return _cleaned;
  }

  async function registerCustomer() {
    if (!loading) {
      if (name.trim().length > 0) {
        if (phone.trim().length > 0) {
          if (recordedDate.trim().length > 0) {
            //..
            setLoading(true);
            await callApi(
              "register-customer",
              {
                name,
                source,
                recordedDate,
                phone,
                customerType,
                customerLevel,
                email,
                location,
                notes,
                referrerId,
                businessId: appContext.currentBusinessId,
                otherCommunicationChannels: JSON.stringify(cleanCommunicationChannels(otherCommunicationChannels))
              }
            ).then((response) => {
              if (response.status === 1) {
                appContext.tellMessage(STR_SUCCESS[appContext.language]);
                appContext.getMyCustomers();
                appContext.getMyCustomersRecordedToday();
                appContext.getMyCustomersRecordedThisWeek();
                appContext.getMyCustomersRecordedThisMonth();
                appContext.navBack();
              } else {
                appContext.tellError(response.msg)
              }
            })
            setLoading(false);
            //..
          } else {
            appContext.tellError(STR_INVALID_ENTRANCE_DATE[appContext.language]);
          }
        } else {
          appContext.tellError(STR_INVALID_PHONE[appContext.language]);
        }
      } else {
        appContext.tellError(STR_INVALID_NAME[appContext.language]);
      }
    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language]);
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready) {
    return (

      <div className="container mw-700">
        <h6 className="mSectionTitle">{STR_TITLE_RECORD_CUSTOMER[appContext.language]}</h6>

        <div className="row">

          <div className="col-md-6 mb-4">
            <label className="form-label">{STR_ENTRANCE_DATE[appContext.language]}*</label>
            <input
              className="form-control"
              value={recordedDate}
              onChange={(e) => setRecordedDate(e.target.value)}
              type="date"
            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="form-label">{STR_SOURCE[appContext.language]}</label>
            <select
              className="form-control"
              value={source}
              onChange={(e) => setSource(e.target.value)}
            >
              <option value=""></option>
              {
                (appContext.systemParams.supportedCustomerSources) ?
                  JSON.parse(appContext.systemParams.supportedCustomerSources).map((item, i) => {
                    return (
                      <option key={i} value={item}>{item}</option>
                    )
                  })
                  : ""
              }
            </select>
          </div>

          <div className="col-md-6 mb-4">
            <label className="form-label">{STR_NAME[appContext.language]}*</label>
            <input
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="form-label">{STR_CUSTOMER_TYPE[appContext.language]}*</label>
            <select
              className="form-control"
              value={customerType}
              onChange={(e) => setCustomerType(e.target.value)}
            >
              <option value="retail">{STR_RETAIL[appContext.language]}</option>
              <option value="wholesale">{STR_WHOLESALE[appContext.language]}</option>
            </select>
          </div>

          <div className="col-md-6 mb-4">
            <label className="form-label">{STR_PHONE_NUMBER[appContext.language]}*</label>
            <input
              className="form-control"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="form-label">{STR_CUSTOMER_LEVEL[appContext.language]}*</label>
            <select
              className="form-control"
              value={customerLevel}
              onChange={(e) => setCustomerLevel(e.target.value)}
            >
              <option value="lead">{STR_LEAD[appContext.language]}</option>
              <option value="client">{STR_CLIENT[appContext.language]}</option>
            </select>
          </div>

          <div className="col-md-6 mb-4">
            <label className="form-label">{STR_EMAIL_ADDRESS[appContext.language]}</label>
            <input
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="form-label">{STR_LOCATION[appContext.language]}</label>
            <input
              className="form-control"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>

          <div className="col-md-12 mb-4">
            <label className="form-label">{STR_NOTES[appContext.language]}</label>
            <textarea
              className="form-control"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            ></textarea>
          </div>

          <div className="col-md-12 mb-4">
            <label className="form-label">{STR_OTHER_COMM_CHANNELS[appContext.language]}</label>
            <div
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid var(--secondaryColor)",
                borderRadius: "10px",
              }}
            >
              {
                otherCommunicationChannels.map((item, i) => {
                  return (
                    <div className="card" key={i}>
                      <div className="card-body">

                        <div className="text-end">
                          <MdClose
                            size={24}
                            onClick={() => {
                              deleteCommunicationChannel(i)
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </div>

                        <div className="row">
                          <div className="col-5">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                handleCommunicationChannelValueChange(i, 'id', e.target.value)
                              }}
                              value={item.id}
                            >
                              <option value=""></option>
                              {
                                JSON.parse(appContext.systemParams.supportedCommunicationChannels).map((item, i) => {
                                  return (
                                    <option key={i} value={item}>{item}</option>
                                  )
                                })
                              }
                            </select>
                          </div>

                          <div className="col-7">
                            <input
                              className="form-control"
                              onChange={(e) => {
                                handleCommunicationChannelValueChange(i, 'value', e.target.value)
                              }}
                              value={item.value}
                              type="text"
                            />
                            {
                              (item.id === 'whatsapp' || item.id === 'telegram') ?
                                <div className="form-text">
                                  {STR_INCLUDE_COUNTRY_CODE_ON_PHONE[appContext.language]}
                                </div>
                                :
                                <div className="form-text">
                                  {STR_INCLUDE_LINK_TO_PROFILE[appContext.language]}
                                </div>
                            }
                          </div>

                        </div>
                      </div>
                    </div>
                  )
                })
              }

              <button
                className="btn btn-sm btn-secondary"
                onClick={addCommunicationChannel}
              >
                {STR_ADD_NEW[appContext.language]}
              </button>
            </div>
          </div>

          <div className="col-md-12 mb-4 text-end">
            <button
              className="btn btn-primary btn-padded btn-rounded"
              onClick={registerCustomer}
            >
              {STR_SUBMIT[appContext.language]}
            </button>
          </div>

        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}