import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatDate, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ACCOUNTS_RECEIVABLE, STR_AMOUNT_PAID, STR_ASK_CONTINUE, STR_BALANCE, STR_BILLS, STR_CALL, STR_CUSTOMER_WILL_BE_CLIENT, STR_DASHBOARD, STR_DATE, STR_EDIT, STR_INITIAL_AMOUNT, STR_INVALID_AMOUNT, STR_INVALID_DATE, STR_MARK_AS_COMPLETED, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NO_RECORDS, STR_PAYABLE, STR_PAYMENTS, STR_PROMISE, STR_PROMISE_DUE_DATE, STR_PURCHASES, STR_RECEIVABLE, STR_RECORD_PAYMENTS, STR_REPORT_FOLLOWUP, STR_REPORT_SALES, STR_REPORT_VISIT, STR_SEND_EMAIL, STR_SEND_SMS, STR_SUBMIT, STR_SUCCESS, STR_TODOS, STR_TURN_TO_CLIENT, STR_VIEW_MORE_INFO, STR_VISITS, STR_WATEJA } from "../Strings";
import CustomersHome from '../views/CustomersHome';
import { MdCall, MdCheckCircle, MdEdit, MdEmail, MdFacebook, MdMessage, MdOutlineFacebook, MdOutlineWhatsapp, MdWhatsapp } from "react-icons/md";
import { BiInfoCircle, BiLogoLinkedin, BiLogoWhatsapp, BiPurchaseTag } from "react-icons/bi";
import { SlUserFollowing } from "react-icons/sl";
import { BsInstagram, BsTelegram } from "react-icons/bs";
import CustomerWidget from '../ones/CustomerWidget';
import OneRecord from '../ones/OneRecord';

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);


  async function init() {
    //run initializations here
    setReady(false);
    await getData()
    setReady(true);
  }

  async function getData() {
    await callApi("get-vendor-data", { id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }



  useEffect(() => {
    init();
  }, [id])


  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready && data) {
    return (
      <div
        className="container"
      >

        <h6
          className="mSectionTitle"
        >
          {data.name}<br />
          <span className="text-muted" style={{ fontSize: "16px" }}>{data.phone}</span>
        </h6>
        <hr />


        <div className="row">
          <div className="col-md-12 mb-2 mt-2">
            <h6
              className='font-bold text-secondary'
              style={{
                fontSize: "24px",
              }}
            >
              {STR_BILLS[appContext.language]}
            </h6>
          </div>

          {
            (data.bills && data.bills.length > 0) ?
              data.bills.map((item, i) => {
                return <OneRecord type="bill" data={item} key={i} />;

              })
              : <h6 style={{ fontSize: "14px", }}>{STR_NO_RECORDS[appContext.language]}</h6>
          }

        </div>

      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}