import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatDate, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ACCOUNTS_MANAGER, STR_ADD_NEW, STR_ALL, STR_AVAILABLE_STOCK, STR_BALANCE, STR_BILLS, STR_BONUS_MANAGEMENT, STR_CLEARED, STR_CLIENTS, STR_CREDIT, STR_DASHBOARD, STR_DEBIT, STR_FILTER_BY_NAME, STR_FILTER_BY_NAME_OR_PHONE, STR_LEADS, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_MY_CUSTOMERS, STR_NO_RECORDS, STR_PAID, STR_PENDING, STR_PROCESSED, STR_SALES_ALL, STR_SALES_RECORDS, STR_SALES_TODAY, STR_SELECT_ACCOUNT, STR_STOCKLIST, STR_TODOS, STR_TOTAL_CREDIT, STR_TOTAL_DEBIT, STR_TRANSACTIONS, STR_UNCLEARED, STR_UNPAID, STR_UNPROCESSED, STR_WATEJA } from "../Strings";
import { MdSearch } from "react-icons/md";
import OneRecord from '../ones/OneRecord';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [filter, setFilter] = useState("");
  const [status, setStatus] = useState("all");


  async function init() {
    //run initializations here
    setReady(false);

    appContext.setSelectedAccountId("");
    appContext.setSelectedAccountData(null);

    await Promise.all([
      appContext.getAllBusinessAccounts()
    ])

    setReady(true);
  }


  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if (appContext.selectedAccountData) {
      setStatus("all");
      setFilter("");
    }
  }, [appContext.selectedAccountData])

  if (ready) {
    return (
      <div
        className="container"

      >
        <h6 className="mSectionTitle">{STR_ACCOUNTS_MANAGER[appContext.language]}</h6>

        <div className="row">
          <div className="col-md-12 mt-2 mb-4">
            <label className="form-label">{STR_SELECT_ACCOUNT[appContext.language]}</label>
            <select
              className="form-control"
              value={appContext.selectedAccountId}
              onChange={(e) => {
                appContext.setSelectedAccountId(e.target.value);
              }}
            >
              <option value=""></option>
              {
                (appContext.allBusinessAccounts && appContext.allBusinessAccounts.length > 0) ?
                  appContext.allBusinessAccounts.map((item, i) => {
                    return (
                      <option key={i} value={item.id}>{item.title}</option>
                    )
                  })
                  : ""
              }
            </select>
          </div>

          {
            (appContext.selectedAccountData) ?
              <>
                <div className="col-md-12 col-12">
                  <div className="card">
                    <div className="card-body">
                      <h6
                        style={{
                          fontSize: "18px",
                          textTransform: "uppercase",
                        }}
                        className="font-semi-bold"
                      >
                        {appContext.selectedAccountData.title}
                      </h6>
                      <hr />

                      <div className="row">
                        <div className="col-md-6 mt-2 mb-2">
                          <h6
                            style={{
                              fontSize: "30px",
                            }}
                            className="font-bold text-secondary"
                          >
                            {formatMoney(appContext.selectedAccountData.totalDebit)} <span style={{ fontSize: "14px" }}>TZS</span>
                            <span className="font-regular text-muted" style={{ display: "block", fontSize: "16px" }}>{STR_TOTAL_DEBIT[appContext.language]} </span>
                          </h6>
                        </div>

                        <div className="col-md-6 mt-2 mb-2">
                          <h6
                            style={{
                              fontSize: "30px",
                            }}
                            className="font-bold text-secondary"
                          >
                            {formatMoney(appContext.selectedAccountData.totalCredit)} <span style={{ fontSize: "14px" }}>TZS</span>
                            <span className="font-regular text-muted" style={{ display: "block", fontSize: "16px" }}>{STR_TOTAL_CREDIT[appContext.language]} </span>
                          </h6>
                        </div>

                        <div className="col-md-6 mt-2 mb-2">
                          <h6
                            style={{
                              fontSize: "30px",
                            }}
                            className="font-bold text-secondary"
                          >
                            {formatMoney(appContext.selectedAccountData.balance)} <span style={{ fontSize: "14px" }}>TZS</span>
                            <span className="font-regular text-muted" style={{ display: "block", fontSize: "16px" }}>{STR_BALANCE[appContext.language]} </span>
                          </h6>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-12 mb-3 mt-3">
                  <div className="row mb-4">
                    <div className="col-md-3 mt-1 mb-1">
                      <select
                        className="form-control"
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                      >
                        <option value="all">{STR_ALL[appContext.language]}</option>
                        <option value="credit">{STR_CREDIT[appContext.language]}</option>
                        <option value="debit">{STR_DEBIT[appContext.language]}</option>
                      </select>
                    </div>

                    <div className="col-md-3 mt-1 mb-1">
                      <div className="input-group">
                        <span
                          className="input-group-text font-semi-bold text-secondary"
                          style={{
                            fontSize: "22px"
                          }}
                        >
                          <MdSearch size={24} />
                        </span>
                        <input
                          className="form-control"
                          onChange={(e) => setFilter(e.target.value)}
                          value={filter}
                          placeholder={STR_FILTER_BY_NAME[appContext.language]}
                        />
                      </div>
                    </div>
                  </div>

                  <h1 className="mSectionTitle mNoMargin">{STR_TRANSACTIONS[appContext.language]}</h1>

                  <div
                    style={{ overflowX: 'auto' }}
                    onTouchStart={(e) => e.stopPropagation()}
                    onTouchEnd={(e) => e.stopPropagation()}
                  >
                    <table className="table table-striped" style={{ minWidth: '600px' }}>
                      <thead>
                        <tr>
                          <th style={{ fontSize: "12px" }} className="font-bold">
                            Title
                          </th>
                          <th style={{ fontSize: "12px" }} className="font-bold">
                            Amount in TZS
                          </th>
                          <th style={{ fontSize: "12px" }} className="font-bold">
                            Debit/Credit
                          </th>
                          <th style={{ fontSize: "12px" }} className="font-bold">
                            Date
                          </th>
                          <th style={{ fontSize: "12px" }} className="font-bold">
                            Bill
                          </th>
                          <th style={{ fontSize: "12px" }} className="font-bold">
                            Sale
                          </th>
                          <th style={{ fontSize: "12px" }} className="font-bold">
                            Vendor
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {
                          appContext.selectedAccountData.transactions
                            // Apply the filter by title or amount if set
                            ?.filter((item) => {
                              if (!filter) return true; // No filter applied
                              const filterText = filter.toLowerCase();
                              return (
                                item.title?.toLowerCase().includes(filterText) ||
                                formatMoney(item.amount).includes(filterText)
                              );
                            })
                            // Apply the status filter if set
                            .filter((item) => {
                              if (status === "all") return true;
                              if (status === "credit" && item.credit === 'yes') return true;
                              if (status === "debit" && item.debit === 'yes') return true;
                              return false;
                            })
                            // Map filtered data to rows
                            .map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{item.title}</td>
                                  <td>{formatMoney(item.amount)}</td>
                                  <td>
                                    {(item.debit === 'yes') ? 'Debit' : ''}
                                    {(item.credit === 'yes') ? 'Credit' : ''}
                                  </td>
                                  <td>{formatDate(item.dateCreated).substring(0, 10)}</td>
                                  <td>
                                    {item.billId ? (
                                      <button
                                        className="btn btn-sm btn-dark"
                                        onClick={() => {
                                          appContext.navTo({
                                            item: 'view',
                                            subItem: 'bill',
                                            extraItem: item.billId,
                                          });
                                        }}
                                      >
                                        Bill
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    {item.invoiceId ? (
                                      <button
                                        className="btn btn-sm btn-dark"
                                        onClick={() => {
                                          appContext.navTo({
                                            item: 'view',
                                            subItem: 'sale',
                                            extraItem: item.invoiceId,
                                          });
                                        }}
                                      >
                                        Sale
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    {item.vendorId ? (
                                      <button
                                        className="btn btn-sm btn-dark"
                                        onClick={() => {
                                          appContext.navTo({
                                            item: 'view',
                                            subItem: 'vendor',
                                            extraItem: item.vendorId,
                                          });
                                        }}
                                      >
                                        Vendor
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>

              </>
              :
              (appContext.selectedAccountId) ?
                <div className="col-12 mSupportLoading">
                  {getInlineLoader()}
                </div>
                : ""

          }
        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}