import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_CALL, STR_DASHBOARD, STR_DATE, STR_DESCRIPTION, STR_EDIT, STR_INVALID_DATE, STR_INVALID_DESCRIPTION, STR_INVALID_FOLLOWUP_STEP, STR_INVALID_PROMISE_DESCRIPTION, STR_INVALID_PROMISE_DUE_DATE, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NO, STR_PLEASE_WAIT, STR_PROMISE_DESCRIPTION, STR_PROMISE_DUE_DATE, STR_REPORT_FOLLOWUP, STR_REPORT_SALES, STR_REPORT_VISIT, STR_SEND_EMAIL, STR_SEND_SMS, STR_STEP, STR_SUBMIT, STR_SUCCESS, STR_TODOS, STR_TURN_TO_CLIENT, STR_VIEW_MORE_INFO, STR_WAS_ANY_PROMISE_MADE, STR_WATEJA, STR_YES } from "../Strings";
import CustomersHome from './CustomersHome';
import { MdCall, MdCheckCircle, MdEdit, MdEmail, MdFacebook, MdMessage, MdOutlineFacebook, MdOutlineWhatsapp, MdWhatsapp } from "react-icons/md";
import { BiInfoCircle, BiLogoLinkedin, BiLogoWhatsapp, BiPurchaseTag } from "react-icons/bi";
import { SlUserFollowing } from "react-icons/sl";
import { BsInstagram, BsTelegram } from "react-icons/bs";
import CustomerWidget from '../ones/CustomerWidget';

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(null);
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [promiseMade, setPromiseMade] = useState("no");
  const [promiseDescription, setPromiseDescription] = useState("");
  const [promiseDueDate, setPromiseDueDate] = useState("");

  async function init() {
    //run initializations here
    setReady(false);
    await getData()
    setReady(true);
  }

  async function getData() {
    await callApi("get-customer-data", { uid: id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }

  async function reportVisit() {
    const _runner = async () => {
      setLoading(true);
      await callApi(
        "report-customer-visit",
        {
          date,
          description,
          promiseMade,
          promiseDescription,
          promiseDueDate,
          customerId: data.id,
        }
      ).then(response => {
        if (response.status === 1) {
          appContext.tellMessage(STR_SUCCESS[appContext.language]);
          appContext.navBack();
        } else {
          appContext.tellError(response.msg);
        }
      })
      setLoading(false);
    }

    if (!loading) {
      if (date.trim().length > 0) {
        if (description.trim().length > 0) {
          if (promiseMade === 'no') {
            await _runner()
          } else {
            if (promiseDescription.trim().length > 0) {
              if (promiseDueDate.trim().length > 0) {
                await _runner()
              } else {
                appContext.tellError(STR_INVALID_PROMISE_DUE_DATE[appContext.language]);
              }
            } else {
              appContext.tellError(STR_INVALID_PROMISE_DESCRIPTION[appContext.language]);
            }
          }
        } else {
          appContext.tellError(STR_INVALID_DESCRIPTION[appContext.language]);
        }
      } else {
        appContext.tellError(STR_INVALID_DATE[appContext.language]);
      }

    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language])
    }
  }


  useEffect(() => {
    init();
  }, [id])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready && data) {
    return (
      <div
        className="container mw-700"
      >
        <h6 className="mSectionTitle">{STR_REPORT_VISIT[appContext.language]}</h6>

        <CustomerWidget data={data} />

        <div className="row">

          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_DESCRIPTION[appContext.language]}</label>
            <textarea
              className="form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>

          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_DATE[appContext.language]}</label>
            <input
              className="form-control"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              type="date"
            />
          </div>

          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_WAS_ANY_PROMISE_MADE[appContext.language]}</label>
            <select
              className="form-control"
              value={promiseMade}
              onChange={(e) => setPromiseMade(e.target.value)}
            >
              <option value="no">{STR_NO[appContext.language]}</option>
              <option value="yes">{STR_YES[appContext.language]}</option>
            </select>
          </div>

          {
            (promiseMade === 'yes') ?
              <>
                <div className="col-md-12 mb-2 mt-2">
                  <label className="form-label">{STR_PROMISE_DESCRIPTION[appContext.language]}</label>
                  <textarea
                    className="form-control"
                    value={promiseDescription}
                    onChange={(e) => setPromiseDescription(e.target.value)}
                  ></textarea>
                </div>

                <div className="col-md-12 mb-2 mt-2">
                  <label className="form-label">{STR_PROMISE_DUE_DATE[appContext.language]}</label>
                  <input
                    className="form-control"
                    value={promiseDueDate}
                    onChange={(e) => setPromiseDueDate(e.target.value)}
                    type="date"
                  />
                </div>
              </>
              : ""
          }

          <div className="col-md-12 mb2 mt-2 text-end">
            <button
              className="btn btn-primary btn-padded btn-rounded"
              onClick={() => {
                appContext.activateDialog({
                  message: STR_REPORT_VISIT[appContext.language] + "?",
                  onConfirm: reportVisit
                })
              }}
            >
              {STR_SUBMIT[appContext.language]}
            </button>
          </div>

        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}