import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ACCOUNTING_JOURNAL, STR_ACCOUNTS_MANAGER, STR_BILLS, STR_BONUS_MANAGEMENT, STR_BUSINESS, STR_CASH_EXPENSES, STR_CASH_INCOME, STR_COMMISSION_MANAGEMENT, STR_CUSTOMER_DEPOSITS, STR_DASHBOARD, STR_GET_DETAILS, STR_INVALID_SEARCH_FILTER, STR_INVALID_TIN, STR_LEADS_DISCOVERY, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NO_RECORDS, STR_REPORTS, STR_SEARCH, STR_SEARCH_BY_OTHER_DETAILS, STR_SEARCH_BY_TIN, STR_TIN, STR_TODOS, STR_USERS, STR_VENDORS, STR_WATEJA } from "../Strings";
import CustomersHome from '../views/CustomersHome';
import { MdBusiness, MdPeople } from "react-icons/md";
import OneRecord from '../ones/OneRecord';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [leadsFeed, setLeadsFeed] = useState(null);
  const [leadsSearchResults, setLeadsSearchResults] = useState(null);
  const [tinLookUpInfo, setTinLookUpInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [tin, setTin] = useState("");

  async function init() {
    //run initializations here
    setReady(false);
    await getLeadsFeed();
    setReady(true);
  }

  async function getLeadsFeed() {
    await callApi('get-leads-feed', {}).then((response) => {
      if (response.status === 1) {
        setLeadsFeed(response.data);
      }
    })
  }

  async function getLeadsSearchResults() {
    if (filter && filter.trim().length > 0) {

      setLoading(true);
      await callApi('get-leads-search-results', { filter }).then((response) => {
        if (response.status === 1) {
          setLeadsFeed(response.data);
        } else {
          appContext.tellError(response.msg);
        }
      })
      setLoading(false);
    } else {
      appContext.tellError(STR_INVALID_SEARCH_FILTER[appContext.language])
    }
  }

  async function getTinLookUpInfo() {
    if (tin && tin.trim().length > 0) {
      setTinLookUpInfo(null);

      setLoading(true);
      await callApi('lookup-tin-info', { tin }).then((response) => {
        if (response.status === 1) {
          setTinLookUpInfo(response.data);
        } else {
          appContext.tellError(response.msg);
        }
      })
      setLoading(false);
    } else {
      appContext.tellError(STR_INVALID_TIN[appContext.language])
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready) {
    return (
      <div
        className="container"
      >
        <div className="row">

          <div className="col-md-12">
            <h6 className="mSectionTitle">{STR_LEADS_DISCOVERY[appContext.language]}</h6>
          </div>

          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h6
                  style={{
                    fontSize: "16px",
                  }}
                  className="font-semi-bold"
                >
                  {STR_SEARCH_BY_TIN[appContext.language]}
                </h6>

                <div className="mt-2 mb-2">
                  <label className="form-label">{STR_TIN[appContext.language]}</label>
                  <input
                    className="form-control"
                    onChange={(e) => setTin(e.target.value)}
                    value={tin}
                    placeholder="XXXXXXXXX"
                  />
                </div>

                <div className="mt-2 mb-2 text-end">
                  <button
                    className="btn btn-dark btn-sm btn-rounded"
                    onClick={() => {
                      appContext.navTo({
                        item: 'view',
                        subItem: 'lead',
                        extraItem: tin,
                      })
                    }}
                  >
                    {STR_GET_DETAILS[appContext.language]}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-2 mb-2">
            <h6
              style={{
                fontSize: "16px",
              }}
              className="font-semi-bold"
            >
              {STR_SEARCH_BY_OTHER_DETAILS[appContext.language]}
            </h6>

            <div className="mt-2 mb-2">
              <input
                className="form-control"
                onChange={(e) => setFilter(e.target.value)}
                value={filter}
              />
            </div>

            <div className="mt-2 mb-2 text-end">
              <button
                className="btn btn-dark btn-sm btn-rounded"
                onClick={() => {
                  getLeadsSearchResults()
                }}
              >
                {STR_SEARCH[appContext.language]}
              </button>
            </div>
          </div>

          <div className="col-md-12">
            {
              (leadsFeed && leadsFeed.length > 0) ?
                leadsFeed.map((item, i) => {
                  return (
                    <OneRecord data={item} key={i} type='lead' />
                  )
                })
                :
                <h6>
                  {STR_NO_RECORDS[appContext.language]}
                </h6>
            }
          </div>

        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}