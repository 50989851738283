import { useContext } from "react";
import { AppContext } from "../App";

export default function BusinessNameStrip(props) {

	const appContext = useContext(AppContext);

	if(appContext.currentBusinessData && appContext.currentBusinessData.name) {
		return (
			<div
				style={{
					width: "100%",
					height: "auto",
					padding: "0",
				}}
			>
				<h6
					className="mOneLineText font-bold mb-4"
					style={{
						fontSize: "20px",
						borderLeft: "10px solid var(--primaryColor)",
						paddingLeft: "10px",
						textTransform: "uppercase",
					}}
				>
					{appContext.currentBusinessData.name}
				</h6>
			</div>
		)
	}
}