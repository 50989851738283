import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_DASHBOARD, STR_EXPENSES, STR_LEDGER, STR_MAIN_MENU, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_SALES, STR_STOCK, STR_TODOS, STR_WATEJA } from "../Strings";
import Sales from '../views/Sales';
import Ledger from '../views/Ledger';
import Stock from '../views/Stock';
import AccountingDashBoard from '../views/AccountingDashboard';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    //..

    setReady(true);
  }

  useEffect(() => {
    init();
  }, [])

  if (ready) {
    return (
      <div
        className="MainLanding"
      >
        <TabbedView
          tabs={
            [
              //{ id: 0, label: STR_MAIN_MENU[appContext.language], content: <AccountingDashBoard /> },
              { id: 0, label: STR_STOCK[appContext.language], content: <Stock /> },
              { id: 1, label: STR_SALES[appContext.language], content: <Sales /> },
              { id: 2, label: STR_LEDGER[appContext.language], content: <Ledger /> },
            ]
          }
        />
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}