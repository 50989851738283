import axios from 'axios';
import Crm from './main-views/Crm';
import RegisterCustomer from './views/RegisterCustomer';
import CustomerOptions from './views/CustomerOptions';
import ReportCustomerFollowup from './views/ReportCustomerFollowup';
import ReportCustomerVisit from './views/ReportCustomerVisit';
import ReportSale from './views/ReportSale';
import Accounting from './main-views/Accounting';
import AddStock from './views/AddStock';
import More from './main-views/More';
import UserOptions from './views/UserOptions';
import ManageActiveUserGoal from './views/ManageActiveUserGoal';
import CustomerInfo from './views/CustomerInfo';
import ViewSale from './views/ViewSale';
import MarkCustomerAsClient from './views/MarkCustomerAsClient';
import CreateTodo from './views/CreateTodo';
import TodayTodos from './views/TodayTodos';
import PendingTodos from './views/PendingTodos';
import CompletedTodos from './views/CompletedTodos';
import ViewTodo from './views/ViewTodo';
import AddUser from './views/AddUser';
import BonusOptions from './views/BonusOptions';
import CommissionOptions from './views/CommissionOptions';
import ViewGoal from './views/ViewGoal';
import CreateBill from './views/CreateBill';
import AddVendor from './views/AddVendor';
import CreateCashExpense from './views/CreateCashExpense';
import ViewBill from './views/ViewBill';
import ViewVendor from './views/ViewVendor';
import ManageCustomerDeposits from './views/ManageCustomerDeposits';
import AccountSettings from './main-views/AccountSettings';
import ViewCashExpense from './views/ViewCashExpense';
import ViewCashIncome from './views/ViewCashIncome';
import ViewLead from './views/ViewLead';
import CreateCashIncome from './views/CreateCashIncome';
import AssignCustomerPersonnel from './views/AssignCustomerPersonnel';
import StockOptions from './views/StockOptions';
import EditStock from './views/EditStock';
import EditCustomer from './views/EditCustomer';
import CustomerSalesStatement from './views/CustomerSalesStatement';

export const BASE_API = 'https://mauzocrm.werenium.com/api/';
//export const BASE_API = 'https://mauzocrm.werenium.com/api/';

export const CDN_URL = 'https://mauzocrmapp.werenium.com/cdn/'
//export const CDN_URL = 'https://mauzocrmapp.werenium.com/cdn/'

export function getMainView(app) {
  if (app.navItem === 'crm' || !app.navItem) {
    return <Crm />;
  } else if (app.navItem === 'accounting') {
    return <Accounting />;
  } else if (app.navItem === 'more') {
    return <More />;
  } else if (app.navItem === 'account') {
    return <AccountSettings />;
  }

  return null;
}


export function getViewerView(app) {
  if (
    app.navSubItem === 'register-customer' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <RegisterCustomer />;
  } else if (
    app.navSubItem === 'report-customer-followup' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ReportCustomerFollowup id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'mark-customer-as-client' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <MarkCustomerAsClient id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'edit-customer' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <EditCustomer id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'report-customer-visit' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ReportCustomerVisit id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'sale' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ViewSale id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'bill' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ViewBill id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'cash_expense' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ViewCashExpense id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'cash_income' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ViewCashIncome id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'lead' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ViewLead id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'vendor' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ViewVendor id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'goal' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ViewGoal id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'customer-info' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <CustomerInfo id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'customer-sales-statement' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <CustomerSalesStatement id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'assign-customer-personnel' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <AssignCustomerPersonnel id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'manage-customer-deposits' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ManageCustomerDeposits id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'manage-active-user-goal' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ManageActiveUserGoal id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'report-sale' &&
    (!app.navExtraItem || app.navExtraItem) &&
    !app.navMoreItem
  ) {
    return <ReportSale id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'add-stock' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <AddStock />;
  } else if (
    app.navSubItem === 'create-todo' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <CreateTodo />;
  } else if (
    app.navSubItem === 'today-todos' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <TodayTodos />;
  } else if (
    app.navSubItem === 'completed-todos' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <CompletedTodos />;
  } else if (
    app.navSubItem === 'pending-todos' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <PendingTodos />;
  } else if (
    app.navSubItem === 'add-user' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <AddUser />;
  } else if (
    app.navSubItem === 'create-bill' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <CreateBill />;
  } else if (
    app.navSubItem === 'add-vendor' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <AddVendor />;
  } else if (
    app.navSubItem === 'create-cash-expense' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <CreateCashExpense />;
  } else if (
    app.navSubItem === 'create-cash-income' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <CreateCashIncome />;
  } else if (
    app.navSubItem === 'edit-stock' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <EditStock id={app.navExtraItem} />;
  }  

  return null;
}

export function getSideViewerView(app) {
  if (
    app.navSubItem === 'stock-options' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <StockOptions id={app.navExtraItem}/>;
  }

  return null;
}

export function getBottomViewerView(app) {

  if (
    app.navSubItem === 'customer-options' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <CustomerOptions id={app.navExtraItem} />;
  } if (
    app.navSubItem === 'user-options' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <UserOptions id={app.navExtraItem} />;
  } if (
    app.navSubItem === 'bonus-options' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <BonusOptions id={app.navExtraItem} />;
  } if (
    app.navSubItem === 'commission-options' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <CommissionOptions id={app.navExtraItem} />;
  } if (
    app.navSubItem === 'todo' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ViewTodo id={app.navExtraItem} />;
  }

  return null;
}


export function getInlineLoader() {
  return (
    <div className="Loader"></div>
  )
}

export function remove255(input) {
  if (input.startsWith('255')) {
    return '0' + input.slice(3);
  }
  return input;
}

export function formatDate(timestamp, fromPhp = true) {
  timestamp = (fromPhp) ? Number(timestamp) * 1000 : timestamp;
  let date = new Date(timestamp);
  return (date.toLocaleString());
}

export function callApi(url, params) {
  return new Promise(async resolve => {
    await getLocalUser().then(user => {
      //..
      //call main api
      let formData = new FormData();
      for (let key in params) {
        formData.append(key, params[key]);
      }

      for (let key in user) {
        formData.append(key, user[key]);
      }

      axios.post(BASE_API + url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {

        console.log(url, response.data);

        resolve(response.data);
      }).catch(error => {
        resolve({
          status: 0,
          msg: "Network Connection Error",
        })
      })
      //..
    })

  })
}

export function getLocalUser() {
  return new Promise(async resolve => {
    //use cookies
    let userId = getCookie('mauzocrm-userId');
    let userToken = getCookie('mauzocrm-userToken');
    let userType = getCookie('mauzocrm-userType');

    resolve({
      userId,
      userToken,
      userType,
    })


  })
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();

  // Get the current domain from window.location.hostname
  let domain = window.location.hostname;

  // Set domainPart only if it's not 'localhost'
  let domainPart = domain !== 'localhost' ? `;domain=${domain}` : '';

  // Add the SameSite attribute to restrict cross-origin access (Strict or Lax as per your needs)
  let sameSite = ";SameSite=Strict";

  // Add the Secure flag for HTTPS connections
  let secureFlag = window.location.protocol === 'https:' ? ";Secure" : '';

  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/" + domainPart + sameSite + secureFlag;
}


export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function formatMoney(x) {
  //round x to 2 decimal places before og function
  x = Number(x);
  x = x.toFixed(0);

  //then continue
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function formatMoneyShorten(num, digits = 2) {
  num = Number.parseFloat(num);
  if (num <= 1 && num >= -1) {
    return num;
  }

  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function (item) {
    return Math.abs(num) >= item.value; // Use absolute value of num
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

export function formatDateForInput(timestamp) {
  const date = new Date(timestamp * 1000); // Convert seconds to milliseconds

  // Get year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
  const day = String(date.getDate()).padStart(2, '0'); // Ensure day is two digits

  // Return in YYYY-MM-DD format
  return `${year}-${month}-${day}`;
}