import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatDateForInput, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_AMOUNT, STR_AMOUNT_PAID, STR_CALL, STR_CREATE_BILL, STR_CUSTOMER, STR_DASHBOARD, STR_DATE, STR_DATE_CREATED, STR_DATE_DUE, STR_DESCRIPTION, STR_EDIT, STR_INVALID_ACCOUNT, STR_INVALID_AMOUNT, STR_INVALID_DATE, STR_INVALID_DESCRIPTION, STR_INVALID_FOLLOWUP_STEP, STR_INVALID_PROMISE_DESCRIPTION, STR_INVALID_PROMISE_DUE_DATE, STR_INVALID_TITLE, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NAME, STR_NO, STR_NO_OPTIONS, STR_OUT_OF_STOCK, STR_PARTICULARS, STR_PLEASE_WAIT, STR_PRODUCT_CODE, STR_PROMISE_DESCRIPTION, STR_PROMISE_DUE_DATE, STR_QUANTITY, STR_REAL_SALE, STR_REPORT_FOLLOWUP, STR_REPORT_SALES, STR_REPORT_VISIT, STR_SALE_TYPE, STR_SELECT_ACCOUNT, STR_SEND_EMAIL, STR_SEND_SMS, STR_STEP, STR_SUB_TOTAL, STR_SUBMIT, STR_SUCCESS, STR_TITLE, STR_TODOS, STR_TOTAL_AMOUNT, STR_TURN_TO_CLIENT, STR_UNIT, STR_UNIT_PRICE, STR_UP_SALE, STR_VENDOR, STR_VENDORS, STR_VIEW_MORE_INFO, STR_WAS_ANY_PROMISE_MADE, STR_WATEJA, STR_YES } from "../Strings";
import CustomersHome from './CustomersHome';
import { MdAdd, MdCall, MdCheckCircle, MdDelete, MdEdit, MdEmail, MdFacebook, MdMessage, MdOutlineFacebook, MdOutlineWhatsapp, MdWhatsapp } from "react-icons/md";
import { BiInfoCircle, BiLogoLinkedin, BiLogoWhatsapp, BiPurchaseTag } from "react-icons/bi";
import { SlUserFollowing } from "react-icons/sl";
import { BsInstagram, BsTelegram } from "react-icons/bs";
import CustomerWidget from "../ones/CustomerWidget";
import Select from "react-select";


export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(null);
  const [dateCreated, setDateCreated] = useState(formatDateForInput(Date.now() / 1000));
  const [dateDue, setDateDue] = useState("");
  const [title, setTitle] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [id, setId] = useState(props.id);
  const [particulars, setParticulars] = useState([getParticularsTemp()]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [accountId, setAccountId] = useState('');

  async function init() {
    //run initializations here
    setReady(false);

    await Promise.all([
      appContext.getAllVendors(),
      appContext.getAllBusinessAccounts(),
    ])
    if (id) {
      await getData()
    }

    setReady(true);
  }

  function handleParticularsFieldChange(_index, _field, _value) {

    setParticulars(_prev => {
      const _new = [..._prev];

      _new[_index][_field] = _value;

      return _new;
    })
  }

  function addParticularsRow() {
    setParticulars(_prev => {
      const _new = [..._prev, getParticularsTemp()];

      return _new;
    })
  }

  function removeParticularsRow(_index) {
    setParticulars(_prev => {
      const _new = [..._prev];

      _new.splice(_index, 1);

      return _new;
    })
  }

  function getParticularsTemp() {
    return ({
      title: "",
      amount: 0,
    })
  }

  async function getData() {
    await callApi("get-vendor-data", { id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }

  async function createBill() {
    const _runner = async () => {
      setLoading(true);
      await callApi(
        "create-bill",
        {
          totalAmount,
          dateCreated,
          items: JSON.stringify(particulars),
          businessId: appContext.currentBusinessId,
          vendorId: (data) ? data.id : '',
          dateDue,
          title,
          accountId,
        }
      ).then(response => {
        if (response.status === 1) {
          appContext.tellMessage(STR_SUCCESS[appContext.language]);
          appContext.getAllBills();
          appContext.navBack();
        } else {
          appContext.tellError(response.msg);
        }
      })
      setLoading(false);
    }

    if (!loading) {
      if (title.trim().length > 0) {
        if (dateCreated.trim().length > 0) {
          if (!isNaN(totalAmount) && Number(totalAmount) > 0) {
            if (accountId) {
              await _runner();
            } else {
              appContext.tellError(STR_INVALID_ACCOUNT[appContext.language]);
            }
          } else {
            appContext.tellError(STR_INVALID_AMOUNT[appContext.language]);
          }
        } else {
          appContext.tellError(STR_INVALID_DATE[appContext.language]);
        }
      } else {
        appContext.tellInfo(STR_INVALID_TITLE[appContext.language])
      }


    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language])
    }
  }


  function getVendorOptions() {
    if (appContext.allVendors && appContext.allVendors.length > 0) {
      const options = appContext.allVendors.map((item, i) => {
        return (
          {
            value: JSON.stringify(item),
            label: item.name
          }
        )
      })

      setVendorOptions(options);
    }
  }

  function getTotalAmount() {
    let _total = 0;
    if (particulars && particulars.length > 0) {
      for (const _item of particulars) {
        _total += Number(_item.amount);
      }

    }
    setTotalAmount(_total);
  }

  useEffect(() => {
    init();
  }, [id])

  useEffect(() => {
    setId(props.id)
  }, [props.id])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])



  useEffect(() => {
    if (appContext.allVendors) {
      getVendorOptions()
    }
  }, [appContext.allVendors])

  useEffect(() => {
    getTotalAmount();
  }, [particulars])

  useEffect(() => {
    setParticulars([getParticularsTemp()]);
  }, [data])

  if (ready) {
    return (
      <div
        className="container"
      >
        <h6 className="mSectionTitle">{STR_CREATE_BILL[appContext.language]}</h6>

        {
          (data) ?
            <div className="card">
              <div className="card-body">
                <h6
                  className='font-semi-bold'
                  style={{
                    fontSize: "16px",
                  }}
                >
                  <span style={{ textTransform: "uppercase", fontSize: "20px" }}>{data.name}</span>
                  <br />
                  <span className="text-muted">{data.phone}</span>
                </h6>
              </div>
            </div>
            : ""
        }

        <div className="row">
          {
            (!props.id) ?
              <div className="col-md-12 mb-2 mt-2">
                <label className="form-label">{STR_VENDOR[appContext.language]}</label>

                <Select
                  className="form-control"
                  value={data ? { value: JSON.stringify(data), label: data.name } : null}
                  onChange={(_value) => {
                    if (_value) {
                      try {
                        setData(JSON.parse(_value.value));
                      } catch (error) {
                        setData(null);
                      }
                    } else {
                      setData(null);
                    }
                  }}
                  options={vendorOptions}
                  isClearable={true}

                  noOptionsMessage={() => STR_NO_OPTIONS[appContext.language]}
                />

              </div>
              : ""
          }

          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_SELECT_ACCOUNT[appContext.language]}</label>
            <select
              className="form-control"
              value={accountId}
              onChange={(e) => setAccountId(e.target.value)}
              type="text"
            >
              <option value=""></option>
              {
                (appContext.allBusinessAccounts && appContext.allBusinessAccounts.length > 0) ?
                  appContext.allBusinessAccounts.map((item, i) => {
                    if (item.accountType === 'expense') {
                      return (
                        <option key={i} value={item.id}>{item.title}</option>
                      )
                    }
                  })
                  : ""
              }
            </select>
          </div>

          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_TITLE[appContext.language]}</label>
            <input
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              type="text"
            />
          </div>

          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_DATE_CREATED[appContext.language]}</label>
            <input
              className="form-control"
              value={dateCreated}
              onChange={(e) => setDateCreated(e.target.value)}
              type="date"
            />
          </div>

          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_DATE_DUE[appContext.language]}</label>
            <input
              className="form-control"
              value={dateDue}
              onChange={(e) => setDateDue(e.target.value)}
              type="date"
            />
          </div>

          {
            (data) ?
              <div className="col-md-12 mb-2 mt-2">
                <label className="form-label">{STR_PARTICULARS[appContext.language]}</label>

                <div className="mTableHolder">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>
                          {STR_TITLE[appContext.language]}
                        </th>
                        <th>
                          {STR_AMOUNT[appContext.language]}
                        </th>

                        <th></th>
                      </tr>
                    </thead>
                    {
                      (particulars && particulars.length > 0) ?
                        <tbody>
                          {
                            particulars.map((item, i) => {
                              return (
                                <tr key={i}>

                                  <td>
                                    <input
                                      className="form-control"
                                      value={item.item || ''}
                                      onChange={(e) => {
                                        handleParticularsFieldChange(i, 'item', e.target.value)
                                      }}
                                      type="text"
                                      style={{
                                        minWidth: "150px"
                                      }}
                                    />
                                  </td>

                                  <td>
                                    <input
                                      className="form-control"
                                      onChange={(e) => {
                                        handleParticularsFieldChange(i, 'amount', Number(e.target.value))
                                      }}
                                      value={item.amount}
                                      style={{
                                        minWidth: "80px"
                                      }}
                                    />
                                  </td>

                                  <td>
                                    <MdDelete
                                      size={24}
                                      className="text-danger"
                                      onClick={() => removeParticularsRow(i)}
                                      style={{
                                        cursor: "pointer"
                                      }}
                                    />
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                        : ""
                    }
                  </table>
                </div>

                <div className="text-end">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={addParticularsRow}
                  >
                    <MdAdd size={24} />
                  </button>
                </div>
              </div>
              : ""
          }

          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_TOTAL_AMOUNT[appContext.language]}</label>
            <input
              className="form-control"
              value={totalAmount}
              onChange={(e) => setTotalAmount(e.target.value)}
              type="number"
              readOnly={true}
            />
          </div>


          <div className="col-md-12 mb2 mt-2 text-end">
            <button
              className="btn btn-primary btn-padded btn-rounded"
              onClick={() => {
                appContext.activateDialog({
                  message: STR_CREATE_BILL[appContext.language] + "?",
                  onConfirm: createBill
                })
              }}
            >
              {STR_SUBMIT[appContext.language]}
            </button>
          </div>

        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}