import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatDate, formatDateForInput, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ACCOUNTS_MANAGER, STR_ADD_NEW, STR_ALL, STR_AVAILABLE_STOCK, STR_BALANCE, STR_BILLS, STR_BONUS_MANAGEMENT, STR_CLEARED, STR_CLIENTS, STR_CREDIT, STR_DASHBOARD, STR_DEBIT, STR_END_DATE, STR_FILTER_BY_NAME, STR_FILTER_BY_NAME_OR_PHONE, STR_GET_REPORT, STR_INVALID_END_DATE, STR_INVALID_REPORT_TYPE, STR_INVALID_START_DATE, STR_LEADS, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_MY_CUSTOMERS, STR_NO_RECORDS, STR_PAID, STR_PENDING, STR_PLEASE_WAIT, STR_PROCESSED, STR_REPORTS, STR_SALES_ALL, STR_SALES_RECORDS, STR_SALES_TODAY, STR_SELECT_ACCOUNT, STR_SELECT_REPORT, STR_START_DATE, STR_STOCKLIST, STR_TODOS, STR_TOTAL_CREDIT, STR_TOTAL_DEBIT, STR_TRANSACTIONS, STR_UNCLEARED, STR_UNPAID, STR_UNPROCESSED, STR_WATEJA } from "../Strings";
import { MdSearch } from "react-icons/md";
import OneRecord from '../ones/OneRecord';
import CashFlowReport from "../ones/CashFlowReport";
import IncomeStatement from "../ones/IncomeStatement";
import BalanceSheetReport from "../ones/BalanceSheetReport";
import BusinessMetricsReport from "../ones/BusinessMetricsReport";
import CashFlowDriversReport from "../ones/CashFlowDriversReport";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [reportType, setReportType] = useState("");
  const [reportData, setReportData] = useState(null);
  const [startDate, setStartDate] = useState(formatDateForInput((Date.now() / 1000) - (24 * 60 * 60 * 30)));
  const [endDate, setEndDate] = useState(formatDateForInput(Date.now() / 1000));
  const [loadingReport, setLoadingReport] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);

    setReady(true);
  }

  async function getReport() {
    if (!loadingReport) {
      if (reportType.trim().length > 0) {
        if (startDate.trim().length > 0 || reportType === 'balance_sheet') {
          if (endDate.trim().length > 0) {
            setLoadingReport(true);
            document.getElementById("_reportBody").scrollIntoView({ behavior: "smooth" })
            await callApi("get-business-report", { reportType, startDate, endDate, businessId: appContext.currentBusinessId }).then((response) => {
              if (response.status === 1) {
                setReportData(response.data);
              } else {
                appContext.tellError(response.msg);
              }
            })
            setLoadingReport(false);
          } else {
            appContext.tellError(STR_INVALID_END_DATE[appContext.language]);
          }
        } else {
          appContext.tellError(STR_INVALID_START_DATE[appContext.language]);
        }
      } else {
        appContext.tellError(STR_INVALID_REPORT_TYPE[appContext.language]);
      }
    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language]);
    }
  }


  useEffect(() => {
    init();
  }, [])



  if (ready) {
    return (
      <div
        className="container"

      >
        <h6 className="mSectionTitle">{STR_REPORTS[appContext.language]}</h6>

        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 mt-2 mb-2">
                <label className="form-label">{STR_SELECT_REPORT[appContext.language]}</label>
                <select
                  className="form-control"
                  value={reportType}
                  onChange={(e) => {
                    setReportType(e.target.value);
                    setReportData(null);
                  }}
                >
                  <option value=""></option>
                  <option value="income_statement">Income Statement</option>
                  <option value="cashflow">Cashflow</option>
                  <option value="balance_sheet">Balance Sheet</option>
                  <option value="business_metrics">15 Main Business Metrics</option>
                  <option value="cash_flow_drivers">Cash Flow Drivers Report</option>
                </select>
              </div>

              <div className="col-6 mt-2 mb-2" style={{ display: (reportType === 'balance_sheet') ? 'none' : 'block' }}>
                <label className="form-label">{STR_START_DATE[appContext.language]}</label>
                <input
                  className="form-control"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                  type="date"
                />
              </div>

              <div className="col-6 mt-2 mb-2">
                <label className="form-label">{STR_END_DATE[appContext.language]}</label>
                <input
                  className="form-control"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                  type="date"
                />
              </div>

              <div className="col-md-12 text-end mt-4 mb-4">
                <button
                  className="btn btn-sm btn-padded btn-dark btn-rounded"
                  onClick={getReport}
                >
                  {STR_GET_REPORT[appContext.language]}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row" id="_reportBody">
          {
            (loadingReport) ?
              <div className="col-12 mSupportLoading">
                {getInlineLoader()}
              </div>
              :
              (reportData) ?
                <>
                  {
                    (reportType === 'cashflow') ?
                      <CashFlowReport report={reportData} startDate={startDate} endDate={endDate} />
                      : ""
                  }

                  {
                    (reportType === 'income_statement') ?
                      <IncomeStatement report={reportData} startDate={startDate} endDate={endDate} />
                      : ""
                  }

                  {
                    (reportType === 'balance_sheet') ?
                      <BalanceSheetReport balanceSheet={reportData} startDate={startDate} endDate={endDate} />
                      : ""
                  }

                  {
                    (reportType === 'business_metrics') ?
                      <BusinessMetricsReport metricsReport={reportData} startDate={startDate} endDate={endDate} />
                      : ""
                  }

                  {
                    (reportType === 'cash_flow_drivers') ?
                      <CashFlowDriversReport report={reportData} startDate={startDate} endDate={endDate} />
                      : ""
                  }
                </>
                : ""
          }
        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}