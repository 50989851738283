import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ASSIGN_PERSONEL, STR_CALL, STR_CUSTOMER_WILL_BE_CLIENT, STR_DASHBOARD, STR_DEPOSITS, STR_EDIT, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NO_EMAIL, STR_REPORT_FOLLOWUP, STR_REPORT_SALES, STR_REPORT_VISIT, STR_SALES_STATEMENT, STR_SEND_EMAIL, STR_SEND_SMS, STR_SUCCESS, STR_TODOS, STR_TURN_TO_CLIENT, STR_VIEW_MORE_INFO, STR_WATEJA } from "../Strings";
import CustomersHome from '../views/CustomersHome';
import { MdCall, MdCheckCircle, MdEdit, MdEmail, MdFacebook, MdMessage, MdMoney, MdNote, MdOutlineFacebook, MdOutlineWhatsapp, MdPeople, MdWhatsapp } from "react-icons/md";
import { BiEdit, BiInfoCircle, BiLogoLinkedin, BiLogoWhatsapp, BiPurchaseTag } from "react-icons/bi";
import { SlUserFollowing } from "react-icons/sl";
import { BsInstagram, BsTelegram } from "react-icons/bs";
import CustomerWidget from '../ones/CustomerWidget';
import MobileInterface from "../MobileInterface";

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    await getData()
    setReady(true);
  }

  async function getData() {
    await callApi("get-customer-data", { uid: id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }

  function callCustomer() {
    MobileInterface.openLink("tel:" + data.phone)
  }

  function sendSms() {
    MobileInterface.openLink("tel:" + data.phone)
  }

  function sendEmail() {
    if (data.email) {
      MobileInterface.openLink("mailto:" + data.email)
    } else {
      appContext.tellError(STR_NO_EMAIL[appContext.language])
    }
  }

  function openCommunicationChannel(_channel) {
    if (_channel.id === 'whatsapp') {
      MobileInterface.openLink("https://wa.me/" + _channel.value);
    } else if (_channel.id === 'telegram') {
      MobileInterface.openLink("https://t.me/" + _channel.value);
    } else {
      MobileInterface.openLink(_channel.value);
    }
  }

  function editCustomer() {
    appContext.navTo({
      item: 'view',
      subItem: 'edit-customer',
      extraItem: data.uid,
    })
  }

  async function turnToClient() {
    appContext.navTo({
      item: 'view',
      subItem: 'mark-customer-as-client',
      extraItem: data.uid,
    })
  }

  async function manageDeposits() {
    appContext.navTo({
      item: 'view',
      subItem: 'manage-customer-deposits',
      extraItem: data.uid,
    })
  }

  function reportFollowup() {
    appContext.navTo({
      item: 'view',
      subItem: 'report-customer-followup',
      extraItem: data.uid,
    })
  }

  function reportVisit() {
    appContext.navTo({
      item: 'view',
      subItem: 'report-customer-visit',
      extraItem: data.uid,
    })
  }

  function assignPersonel() {
    appContext.navTo({
      item: 'view',
      subItem: 'assign-customer-personnel',
      extraItem: data.uid,
    })
  }

  function reportSales() {
    appContext.navTo({
      item: 'view',
      subItem: 'report-sale',
      extraItem: data.uid,
    })
  }

  function viewMoreInfo() {
    appContext.navTo({
      item: 'view',
      subItem: 'customer-info',
      extraItem: data.uid,
    })
  }

  function viewSalesStatement() {
    appContext.navTo({
      item: 'view',
      subItem: 'customer-sales-statement',
      extraItem: data.id,
    })
  }

  useEffect(() => {
    init();
  }, [id])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready && data) {
    return (
      <div
        className="container mw-700"
      >
        <CustomerWidget data={data} />


        <div className="row mt-4 mb-4">

          <div className="col-4 col-md-3 mb-2 mt-2">
            <button
              className="btn btn-sm btn-block"
              onClick={callCustomer}
              style={{
                height: "100%",
              }}
            >
              <MdCall size={30} /><br />
              {STR_CALL[appContext.language]}
            </button>
          </div>

          <div className="col-4 col-md-3 mb-2 mt-2">
            <button
              className="btn btn-sm btn-block"
              onClick={sendSms}
              style={{
                height: "100%",
              }}
            >
              <MdMessage size={30} /><br />
              {STR_SEND_SMS[appContext.language]}
            </button>
          </div>

          <div className="col-4 col-md-3 mb-2 mt-2">
            <button
              className="btn btn-sm btn-block"
              onClick={callCustomer}
              style={{
                height: "100%",
              }}
            >
              <MdEmail size={30} /><br />
              {STR_SEND_EMAIL[appContext.language]}
            </button>
          </div>


          {
            (data.customerLevel === 'lead') ?
              <div className="col-4 col-md-3 mb-2 mt-2">
                <button
                  className="btn btn-sm btn-block"
                  onClick={turnToClient}
                  style={{
                    height: "100%",
                  }}
                >
                  <MdCheckCircle size={30} /><br />
                  {STR_TURN_TO_CLIENT[appContext.language]}
                </button>
              </div>
              : ""
          }

          <div className="col-4 col-md-3 mb-2 mt-2">
            <button
              className="btn btn-sm btn-block"
              onClick={reportFollowup}
              style={{
                height: "100%",
              }}
            >
              <SlUserFollowing size={30} /><br />
              {STR_REPORT_FOLLOWUP[appContext.language]}
            </button>
          </div>

          <div className="col-4 col-md-3 mb-2 mt-2">
            <button
              className="btn btn-sm btn-block"
              onClick={reportVisit}
              style={{
                height: "100%",
              }}
            >
              <SlUserFollowing size={30} /><br />
              {STR_REPORT_VISIT[appContext.language]}
            </button>
          </div>

          <div className="col-4 col-md-3 mb-2 mt-2">
            <button
              className="btn btn-sm btn-block"
              onClick={reportSales}
              style={{
                height: "100%",
              }}
            >
              <BiPurchaseTag size={30} /><br />
              {STR_REPORT_SALES[appContext.language]}
            </button>
          </div>

          <div className="col-4 col-md-3 mb-2 mt-2">
            <button
              className="btn btn-sm btn-block"
              onClick={editCustomer}
              style={{
                height: "100%",
              }}
            >
              <BiEdit size={30} /><br />
              {STR_EDIT[appContext.language]}
            </button>
          </div>

          <div className="col-4 col-md-3 mb-2 mt-2">
            <button
              className="btn btn-sm btn-block"
              onClick={viewMoreInfo}
              style={{
                height: "100%",
              }}
            >
              <BiInfoCircle size={30} /><br />
              {STR_VIEW_MORE_INFO[appContext.language]}
            </button>
          </div>

          <div className="col-4 col-md-3 mb-2 mt-2">
            <button
              className="btn btn-sm btn-block"
              onClick={viewSalesStatement}
              style={{
                height: "100%",
              }}
            >
              <MdNote size={30} /><br />
              {STR_SALES_STATEMENT[appContext.language]}
            </button>
          </div>

          {
            (
              appContext.currentUserRole === 'cashier' ||
              appContext.currentUserRole === 'manager' ||
              appContext.currentUserRole === 'accountant' ||
              appContext.currentUserRole === 'owner'
            ) ?
              <div className="col-4 col-md-3 mb-2 mt-2">
                <button
                  className="btn btn-sm btn-block"
                  onClick={manageDeposits}
                  style={{
                    height: "100%",
                  }}
                >
                  <MdMoney size={30} /><br />
                  {STR_DEPOSITS[appContext.language]}
                </button>
              </div>
              : ""
          }

          {
            (
              appContext.currentUserRole === 'manager' ||
              appContext.currentUserRole === 'owner'
            ) ?
              <div className="col-4 col-md-3 mb-2 mt-2">
                <button
                  className="btn btn-sm btn-block"
                  onClick={assignPersonel}
                  style={{
                    height: "100%",
                  }}
                >
                  <MdPeople size={30} /><br />
                  {STR_ASSIGN_PERSONEL[appContext.language]}
                </button>
              </div>
              : ""
          }

          {
            JSON.parse(data.otherCommunicationChannels).map((item, i) => {
              return (
                <div key={i} className="col-4 col-md-3 mb-2 mt-2">
                  <button
                    className="btn btn-sm btn-block"
                    onClick={() => openCommunicationChannel(item)}
                    style={{
                      height: "100%",
                      textTransform: "capitalize",
                    }}
                  >
                    {(item.id === 'whatsapp') ? <MdOutlineWhatsapp size={30} /> : ""}
                    {(item.id === 'instagram') ? <BsInstagram size={30} /> : ""}
                    {(item.id === 'telegram') ? <BsTelegram size={30} /> : ""}
                    {(item.id === 'facebook') ? <MdOutlineFacebook size={30} /> : ""}
                    {(item.id === 'linkedin') ? <BiLogoLinkedin size={30} /> : ""}
                    <br />
                    {item.id}
                  </button>
                </div>
              )
            })
          }
        </div>

      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}