import { useState, useEffect, createContext } from "react";
import { getMainView, getViewerView, getSideViewerView, callApi, getBottomViewerView, setCookie, getCookie } from "./Helpers";
import { useParams } from "react-router-dom";
import Viewer from "./components/Viewer";
import MainBody from "./components/MainBody";
import Backdrop from "./components/Backdrop";
import SideViewer from "./components/SideViewer";
import ConfirmDialog from "./components/ConfirmDialog";
import { ToastContainer, toast } from 'react-toastify';
import SideBar from "./components/SideBar";
import TopBar from "./components/TopBar";
import MainMenuBtn from "./components/MainMenuBtn";
import Login from "./views/Login";
import OverlayLoader from "./components/OverlayLoader";
import MainLoader from "./components/MainLoader";
import BottomBar from "./components/BottomBar";
import BottomViewer from "./components/BottomViewer";

export const AppContext = createContext(null);

export default function App(props) {

  const [ready, setReady] = useState(false); //for checking if app is ready

  const { _navItem, _navSubItem, _navExtraItem, _navMoreItem } = useParams(); //for routing purposes

  const [navItem, setNavItem] = useState(_navItem); //routing 
  const [navSubItem, setNavSubItem] = useState(_navSubItem); //routing
  const [navExtraItem, setNavExtraItem] = useState(_navExtraItem); //routing
  const [navMoreItem, setNavMoreItem] = useState(_navMoreItem); //routing

  const [showViewer, setShowViewer] = useState(false); //controlling the display of Viewer component
  const [viewerView, setViewerView] = useState(null); //the view to be shown in viewer

  const [showSideViewer, setShowSideViewer] = useState(false); //controlling the display of SideViewer component
  const [sideViewerView, setSideViewerView] = useState(null); //the view to be shown in viewer

  const [showBottomViewer, setShowBottomViewer] = useState(false); //controlling the display of BottomViewer component
  const [bottomViewerView, setBottomViewerView] = useState(null); //the view to be shown in viewer

  const [mainView, setMainView] = useState(null); //the view tobe shown in MainBody

  const [showOverlayLoader, setShowOverlayLoader] = useState(false); //controlling the display of OverlayLoader

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState("");
  const [confirmDialogAction, setConfirmDialogAction] = useState("");

  const [isSideBarOpen, setIsSideBarOpen] = useState(false); //controls open and close for sidebar in mobile devices

  const [auth, setAuth] = useState(false); //track user authorization status

  //currentSession
  const [currentUserData, setCurrentUserData] = useState(null);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [currentBusinessId, setCurrentBusinessId] = useState(null);
  const [currentBusinessData, setCurrentBusinessData] = useState(null);
  const [theme, setTheme] = useState('default');
  const [language, setLanguage] = useState(getCookie('language') || 'en');

  //App level data
  const [systemParams, setSystemParams] = useState(null);
  const [myCustomers, setMyCustomers] = useState(null);
  const [stockList, setStockList] = useState(null);
  const [totalStockValue, setTotalStockValue] = useState(0);
  const [totalTodaySales, setTotalTodaySales] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [salesRecords, setSalesRecords] = useState(null);
  const [businessUsers, setBusinessUsers] = useState(null);
  const [myTotalSalesToday, setMyTotalSalesToday] = useState(0);
  const [myTotalSalesThisWeek, setMyTotalSalesThisWeek] = useState(0);
  const [myTotalSalesThisMonth, setMyTotalSalesThisMonth] = useState(0);
  const [myCustomersRecordedToday, setMyCustomersRecordedToday] = useState(0);
  const [myCustomersRecordedThisWeek, setMyCustomersRecordedThisWeek] = useState(0);
  const [myCustomersRecordedThisMonth, setMyCustomersRecordedThisMonth] = useState(0);
  const [myTodos, setMyTodos] = useState([]);
  const [mySalesRecords, setMySalesRecords] = useState([]);
  const [ledger, setLedger] = useState(null);
  const [myActiveGoal, setMyActiveGoal] = useState(null);
  const [myGoals, setMyGoals] = useState(null);
  const [allBonuses, setAllBonuses] = useState(null);
  const [allCommissions, setAllCommissions] = useState(null);
  const [allBills, setAllBills] = useState(null);
  const [allVendors, setAllVendors] = useState(null);
  const [allCustomerDeposits, setAllCustomerDeposits] = useState(null);
  const [selectedAccountId, setSelectedAccountId] = useState("");
  const [selectedAccountData, setSelectedAccountData] = useState(null);
  const [allBusinessAccounts, setAllBusinessAccounts] = useState(null);
  const [allCashExpenses, setAllCashExpenses] = useState(null);
  const [allCashIncome, setAllCashIncome] = useState(null);
  const [allCustomers, setAllCustomers] = useState(null);

  async function init() {
    /**
     * Initialize the app here
     */

    setReady(false);
    await getSystemParams().then(async () => {
      await authCheck().then(async (_auth) => {
        if (_auth) {
          await getCurrentUserData().then(_userData => {
            //set defaul business
            if (_userData) {
              if (_userData.businesses) {
                const _businesses = JSON.parse(_userData.businesses);
                const _currentBusinessId = getCookie('mauzocrm-currentBusinessId') || null;
                                

                if (_businesses.length > 0) {
                  
                  //..
                  if(_currentBusinessId) {
                    //validate businessId
                    let _businessExists = false;
                    let _userRoleForThisBusiness = '';
  
                    for (const _business of _businesses) {
                      if(Number(_business.id) === Number(_currentBusinessId)) {
                        _businessExists = true;
                        _userRoleForThisBusiness = _business.role;
                        
                      }
                    }
  
                    if(_businessExists) {
                      setCurrentBusinessId(_currentBusinessId);
                      setCurrentUserRole(_userRoleForThisBusiness);
                    } else {
                      setCurrentBusinessId(_businesses[_businesses.length - 1].id);
                      setCurrentUserRole(_businesses[_businesses.length - 1].role);
                    }
                  } else {
                    setCurrentBusinessId(_businesses[_businesses.length - 1].id);
                    setCurrentUserRole(_businesses[_businesses.length - 1].role);
                  }
                  //..   
                }
                
              }
            }
          });

        }
      })


      //turn off loaders after initialization
      setReady(true);
    })

  }

  function navBack() {
    window.history.back();
    setShowOverlayLoader(false);
  }

  function tellError(msg) {
    toast.error(msg);
  }

  function tellInfo(msg) {
    toast.info(msg);
  }

  function tellWarning(msg) {
    toast.warn(msg);
  }

  function tellMessage(msg) {
    toast.success(msg);
  }

  function refresh() {
    /**
     * This function refreshes the whole app
     */
    console.log("REFRESH CALLED");

    window.location.reload(); //remember to optimize
  }

  function navTo(nav) {
    /**
     * This function handles navigation inside the app
     * Utilizing Hash based routing
     * nav is the object supporting the following keys: item, subItem, extraItem, moreItem
     */
    if (nav) {
      //..

      let url = '';
      if (nav.item) {
        url = `#/${nav.item}/`
      }

      if (nav.subItem) {
        url += `${nav.subItem}/`
      }

      if (nav.extraItem) {
        url += `${nav.extraItem}/`
      }

      if (nav.moreItem) {
        url += `${nav.moreItem}/`
      }

      window.location.href = url;
      //..
    }
  }

  async function getCurrentUserData() {
    return new Promise(async resolve => {
      await callApi('get-current-user-data', {}).then(response => {
        if (response.status === 1) {
          setCurrentUserData(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getSystemParams() {
    return new Promise(async resolve => {
      await callApi('get-system-params', {}).then(response => {
        if (response.status === 1) {
          setSystemParams(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getMyCustomers() {
    return new Promise(async resolve => {
      await callApi('get-my-customers', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setMyCustomers(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getStockList() {
    return new Promise(async resolve => {
      await callApi('get-stock-list', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setStockList(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getTotalStockValue() {
    return new Promise(async resolve => {
      await callApi('get-total-stock-value', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setTotalStockValue(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getBusinessUsers() {
    return new Promise(async resolve => {
      await callApi('get-business-users', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setBusinessUsers(response.data);
          resolve(response.data);
        } else {
          resolve(null);
        }
      })
    })
  }

  async function getSalesRecords() {
    return new Promise(async resolve => {
      await callApi('get-sales-records', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setSalesRecords(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getAllCustomers() {
    return new Promise(async resolve => {
      await callApi('get-all-customers', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setAllCustomers(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getMySalesRecords() {
    return new Promise(async resolve => {
      await callApi('get-my-sales-records', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setMySalesRecords(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getMyActiveGoal() {
    return new Promise(async resolve => {
      await callApi('get-my-active-goal', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setMyActiveGoal(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getAllBonuses() {
    return new Promise(async resolve => {
      await callApi('get-all-bonuses', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setAllBonuses(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getAllCommissions() {
    return new Promise(async resolve => {
      await callApi('get-all-commissions', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setAllCommissions(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getAllCustomerDeposits() {
    return new Promise(async resolve => {
      await callApi('get-all-customer-deposits', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setAllCustomerDeposits(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getSelectedAccountData() {
    return new Promise(async resolve => {
      setSelectedAccountData(null);
      await callApi('get-account-data', { businessId: currentBusinessId, accountId: selectedAccountId }).then(response => {
        if (response.status === 1) {
          setSelectedAccountData(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getAllBusinessAccounts() {
    return new Promise(async resolve => {
      await callApi('get-all-business-accounts', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setAllBusinessAccounts(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getAllBills() {
    return new Promise(async resolve => {
      await callApi('get-all-bills', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setAllBills(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getAllVendors() {
    return new Promise(async resolve => {
      await callApi('get-all-vendors', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setAllVendors(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getAllCashExpenses() {
    return new Promise(async resolve => {
      await callApi('get-all-cash-expenses', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setAllCashExpenses(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getAllCashIncome() {
    return new Promise(async resolve => {
      await callApi('get-all-cash-income', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setAllCashIncome(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }


  async function getMyGoals() {
    return new Promise(async resolve => {
      await callApi('get-my-goals', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setMyGoals(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getLedger() {
    return new Promise(async resolve => {
      await callApi('get-ledger', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setLedger(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getTotalTodaySales() {
    return new Promise(async resolve => {
      await callApi('get-total-today-sales', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setTotalTodaySales(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getTotalSales() {
    return new Promise(async resolve => {
      await callApi('get-total-sales', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setTotalSales(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getMyTodos() {
    return new Promise(async resolve => {
      await callApi('get-my-todos', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setMyTodos(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }


  async function getCurrentBusinessData() {
    return new Promise(async resolve => {
      if (currentBusinessId) {
        await callApi('get-business-data', { id: currentBusinessId }).then(response => {
          if (response.status === 1) {
            setCurrentBusinessData(response.data);
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } else {
        resolve(null)
      }
    })
  }

  function doesUserHasBusiness() {
    if (currentUserData) {
      if (currentUserData) {
        if (JSON.parse(currentUserData.businesses).length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  function authCheck() {
    return new Promise(async resolve => {
      await callApi('auth-check', {}).then(response => {
        if (response.status === 1) {
          setAuth(true);
          resolve(true)
        } else {
          setAuth(false);
          resolve(false);
        }
      })
    })
  }

  function changeLanguage() {
    let _lang = (language === 'en') ? 'sw' : 'en';
    setLanguage(_lang);
    setCookie('language', _lang);
  }

  function activateDialog(params) {
    let {
      message,
      onConfirm
    } = params;
    setConfirmDialogAction(() => { return onConfirm });
    setConfirmDialogMessage(message)
    setShowConfirmDialog(true);
  }

  function getUserMetricDescription(_id) {
    const _userMetrics = JSON.parse(systemParams.userMetrics);
    let _desc = "";

    for (const _metric of _userMetrics) {
      if (_metric.id === _id) {
        _desc = _metric.description[language];
        break;
      }
    }

    return _desc;
  }

  function getBusinessMetricDescription(_id) {
    const _businessMetrics = JSON.parse(systemParams.businessMetrics);
    let _desc = "";

    for (const _metric of _businessMetrics) {
      if (_metric.id === _id) {
        _desc = _metric.description[language];
        break;
      }
    }

    return _desc;
  }

  async function getMyTotalSalesToday() {
    return new Promise(async resolve => {
      await callApi('get-my-total-sales-today', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setMyTotalSalesToday(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getMyTotalSalesThisWeek() {
    return new Promise(async resolve => {
      await callApi('get-my-total-sales-this-week', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setMyTotalSalesThisWeek(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getMyTotalSalesThisMonth() {
    return new Promise(async resolve => {
      await callApi('get-my-total-sales-this-month', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setMyTotalSalesThisMonth(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getMyCustomersRecordedToday() {
    return new Promise(async resolve => {
      await callApi('get-my-customers-recorded-today', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setMyCustomersRecordedToday(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getMyCustomersRecordedThisWeek() {
    return new Promise(async resolve => {
      await callApi('get-my-customers-recorded-this-week', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setMyCustomersRecordedThisWeek(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getMyCustomersRecordedThisMonth() {
    return new Promise(async resolve => {
      await callApi('get-my-customers-recorded-this-month', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setMyCustomersRecordedThisMonth(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  function changeCurrentBusinessId(_currentBusinessId) {
    setCookie('mauzocrm-currentBusinessId', _currentBusinessId);
    setCurrentBusinessId(_currentBusinessId);
    navTo({
      item: 'crm',
    })
    refresh();
  }

  function getBusinessUserInfo(_userData) {
    const _userBusinesses = JSON.parse(_userData.businesses);
    let _currentBusinessInfo = {};

    for (const _business of _userBusinesses) {
      if(Number(_business.id) === Number(currentBusinessId)) {
        _currentBusinessInfo = _business;
      }
    }

    return _currentBusinessInfo;
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    setCookie('language', language);
  }, [language])

  useEffect(() => {
    if (isSideBarOpen) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }
  }, [isSideBarOpen])

  useEffect(() => {
    setNavItem(_navItem);
    setNavSubItem(_navSubItem);
    setNavExtraItem(_navExtraItem);
    setNavMoreItem(_navMoreItem);
  }, [_navItem, _navSubItem, _navExtraItem, _navMoreItem])

  useEffect(() => {
    if (currentUserData) {
      //handle current user data changes here
    }
  }, [currentUserData])

  useEffect(() => {
    if (currentBusinessId) {
      //handle current business id change
      getCurrentBusinessData();
    }
  }, [currentBusinessId])

  useEffect(() => {
    if (selectedAccountId) {
      getSelectedAccountData();
    }
  }, [selectedAccountId])


  useEffect(() => {

    //check for viewers
    if (navItem === 'view') {
      //activate viewer
      setShowViewer(true);
      setViewerView(getViewerView(appContext));

      //hide other viewers
      setShowSideViewer(false)
      setSideViewerView(null);

      setShowBottomViewer(false);
      setBottomViewerView(null);

    } else if (navItem === 'side-view') {
      //activate viewer
      setShowSideViewer(true);
      setSideViewerView(getSideViewerView(appContext));

      //hide other viewers
      setShowViewer(false)
      setViewerView(null);

      setShowBottomViewer(false);
      setBottomViewerView(null);

    } else if (navItem === 'bottom-view') {
      //activate viewer
      setShowBottomViewer(true);
      setBottomViewerView(getBottomViewerView(appContext));



    } else {
      //just set normal views
      setShowViewer(false);
      setShowSideViewer(false)

      setViewerView(null);
      setSideViewerView(null);

      setShowBottomViewer(false);
      setBottomViewerView(null);

      const _mainView = getMainView(appContext)
      if (_mainView) {
        setMainView(_mainView);
      }
    }
  }, [navItem, navSubItem, navExtraItem, navMoreItem]);

  const appContext = {
    refresh,
    navTo,
    mainView,
    viewerView,
    setShowOverlayLoader,
    navItem,
    navSubItem,
    navExtraItem,
    navMoreItem,
    setShowViewer,
    showViewer,
    navBack,
    showSideViewer,
    setShowSideViewer,
    sideViewerView,
    activateDialog,
    setShowConfirmDialog,
    confirmDialogAction,
    confirmDialogMessage,
    showConfirmDialog,
    tellError,
    tellInfo,
    tellMessage,
    tellWarning,
    isSideBarOpen,
    setIsSideBarOpen,
    showOverlayLoader,
    auth,
    currentUserData,
    theme,
    language,
    doesUserHasBusiness,
    currentBusinessData,
    currentBusinessId,
    getCurrentBusinessData,
    getCurrentUserData,
    bottomViewerView,
    showBottomViewer,
    changeLanguage,
    systemParams,
    getSystemParams,
    myCustomers,
    getMyCustomers,
    getStockList,
    stockList,
    totalStockValue,
    getTotalStockValue,
    getTotalTodaySales,
    totalTodaySales,
    salesRecords,
    totalSales,
    getTotalSales,
    getSalesRecords,
    businessUsers,
    getBusinessUsers,
    getUserMetricDescription,
    getMyTotalSalesThisMonth,
    getMyTotalSalesThisWeek,
    getMyTotalSalesToday,
    myTotalSalesThisMonth,
    myTotalSalesThisWeek,
    myTotalSalesToday,
    myCustomersRecordedToday,
    myCustomersRecordedThisWeek,
    myCustomersRecordedThisMonth,
    getMyCustomersRecordedToday,
    getMyCustomersRecordedThisWeek,
    getMyCustomersRecordedThisMonth,
    getMyTodos,
    myTodos,
    mySalesRecords,
    getMySalesRecords,
    currentUserRole,
    getLedger,
    ledger,
    myGoals,
    myActiveGoal,
    getMyActiveGoal,
    getMyGoals,
    allBonuses,
    getAllBonuses,
    allCommissions,
    getAllCommissions,
    allBills,
    getAllBills,
    getAllVendors,
    allVendors,
    getAllCustomerDeposits,
    allCustomerDeposits,
    setSelectedAccountId,
    selectedAccountId,
    selectedAccountData,
    getSelectedAccountData,
    getAllBusinessAccounts,
    allBusinessAccounts,
    setSelectedAccountData,
    getBusinessMetricDescription,
    setLanguage,
    setCurrentBusinessId,
    getAllCashExpenses,
    allCashExpenses,
    allCashIncome,
    getAllCashIncome,
    allCustomers,
    getAllCustomers,
    changeCurrentBusinessId,
    getBusinessUserInfo,
  }

  if (ready) {
    if (auth) {
      if (currentUserData) {
        return (
          <AppContext.Provider value={appContext}>
            <MainBody />
            <TopBar />
            <BottomBar />
            <Viewer />
            <SideViewer />
            <ConfirmDialog />
            <OverlayLoader />
            <BottomViewer />
            <ToastContainer style={{ zIndex: "var(--maxIndex)" }} position="bottom-right" />
          </AppContext.Provider>
        )
      } else {
        return (
          <AppContext.Provider value={appContext}>
            <ToastContainer style={{ zIndex: "var(--maxIndex)" }} position="bottom-right" />
            <MainLoader />
          </AppContext.Provider>
        )
      }
    } else {
      return (
        <AppContext.Provider value={appContext}>
          <OverlayLoader />
          <ToastContainer style={{ zIndex: "var(--maxIndex)" }} position="bottom-right" />
          <Login />
        </AppContext.Provider>
      )
    }
  } else {
    return (
      <AppContext.Provider value={appContext}>
        <ToastContainer style={{ zIndex: "var(--maxIndex)" }} position="bottom-right" />
        <MainLoader />
      </AppContext.Provider>
    )
  }
}