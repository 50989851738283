import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatDate, formatDateForInput, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ACCOUNT_NAME, STR_ACCOUNTS_RECEIVABLE, STR_AMOUNT, STR_AMOUNT_PAID, STR_ASK_CONTINUE, STR_BALANCE, STR_BILLS, STR_BUSINESS_NAME, STR_BUSINESS_NATURE, STR_BUSINESS_START_DATE, STR_CALL, STR_CASH_EXPENSES, STR_CUSTOMER_WILL_BE_CLIENT, STR_DASHBOARD, STR_DATE, STR_DISCOUNT, STR_DISTRICT, STR_EDIT, STR_EMAIL, STR_FIRSTNAME, STR_GET_SHAREHOLDERS_INFO, STR_INCORPORATION_NUMBER, STR_INFO_NOT_AVAILABLE, STR_INITIAL_AMOUNT, STR_INVALID_AMOUNT, STR_INVALID_DATE, STR_LASTNAME, STR_LOCATION, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_MIDDLENAME, STR_NAME, STR_NO_RECORDS, STR_PARTICULARS, STR_PAYABLE, STR_PAYMENTS, STR_PHONE_NUMBERS, STR_PRODUCT_CODE, STR_PROMISE, STR_PROMISE_DUE_DATE, STR_PURCHASES, STR_QUANTITY, STR_RECEIVABLE, STR_RECEIVE_PAYMENT, STR_RECORD_PAYMENTS, STR_REGION, STR_REPORT_FOLLOWUP, STR_REPORT_SALES, STR_REPORT_VISIT, STR_SALES, STR_SEND_EMAIL, STR_SEND_SMS, STR_SHAREHOLDERS_INFO, STR_SUB_TOTAL, STR_SUBMIT, STR_SUCCESS, STR_TAXPAYER_NAME, STR_TIN, STR_TITLE, STR_TODOS, STR_TOTAL_AMOUNT, STR_TRADING_NAME, STR_TURN_TO_CLIENT, STR_UNIT, STR_UNIT_PRICE, STR_VENDOR, STR_VIEW_MORE_INFO, STR_VISITS, STR_WATEJA } from "../Strings";
import CustomersHome from '../views/CustomersHome';
import { MdCall, MdCheckCircle, MdEdit, MdEmail, MdFacebook, MdMessage, MdOutlineFacebook, MdOutlineWhatsapp, MdWhatsapp } from "react-icons/md";
import { BiInfoCircle, BiLogoLinkedin, BiLogoWhatsapp, BiPurchaseTag } from "react-icons/bi";
import { SlUserFollowing } from "react-icons/sl";
import { BsInstagram, BsTelegram } from "react-icons/bs";
import CustomerWidget from '../ones/CustomerWidget';

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [brelaInfo, setBrelaInfo] = useState(null);

  async function init() {
    //run initializations here

    setReady(false);
    await getData()
    setReady(true);
  }

  async function getData() {
    await callApi("lookup-tin-info", { tin: id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }

  async function getBrelaInfo() {
    setLoading(true);
    await callApi("get-brela-info", { tin: id }).then(response => {
      if (response.status === 1) {
        if (response.data && response.data.shareHolders) {
          setBrelaInfo(response.data);
        } else {
          appContext.tellError(STR_INFO_NOT_AVAILABLE[appContext.language]);
        }
      } else {
        appContext.tellError(STR_INFO_NOT_AVAILABLE[appContext.language]);
      }
    })
    setLoading(false);
  }

  useEffect(() => {
    init();
  }, [id])

  useEffect(() => {
    if (data) {
      try {
        setBrelaInfo(JSON.parse(data.brelaInfo))
      } catch (error) {
        //..
      }
    }
  }, [data])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready) {
    return (
      <div
        className="container"
      >

        {
          (
            data &&
            (
              data.tpName ||
              data.tradingName ||
              data.firstName ||
              data.lastName ||
              data.middleName
            )
          ) ?
            <>
              <h6
                className="mSectionTitle"
              >
                {data.tpName || data.tradingName || (data.firstName + " " + data.middleName + " " + data.lastName)}
              </h6>
              <hr />


              <div className="row">

                <div className="col-12 col-md-6 mb-2 mt-2">
                  <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_TRADING_NAME[appContext.language]}</label>
                  <h6
                    className="font-regular"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {data.tradingName || "N/A"}
                  </h6>
                </div>

                <div className="col-12 col-md-6 mb-2 mt-2">
                  <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_TAXPAYER_NAME[appContext.language]}</label>
                  <h6
                    className="font-regular"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {data.tpName || "N/A"}
                  </h6>
                </div>

                <div className="col-12 col-md-6 mb-2 mt-2">
                  <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_TIN[appContext.language]}</label>
                  <h6
                    className="font-regular"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {data.tin || "N/A"}
                  </h6>
                </div>

                <div className="col-12 col-md-6 mb-2 mt-2">
                  <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_REGION[appContext.language]}</label>
                  <h6
                    className="font-regular"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {data.region || "N/A"}
                  </h6>
                </div>

                <div className="col-12 col-md-6 mb-2 mt-2">
                  <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_DISTRICT[appContext.language]}</label>
                  <h6
                    className="font-regular"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {data.district || "N/A"}
                  </h6>
                </div>

                <div className="col-12 col-md-6 mb-2 mt-2">
                  <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_LOCATION[appContext.language]}</label>
                  <h6
                    className="font-regular"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {data.location || "N/A"}
                  </h6>
                </div>

                <div className="col-12 col-md-6 mb-2 mt-2">
                  <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_BUSINESS_START_DATE[appContext.language]}</label>
                  <h6
                    className="font-regular"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {data.businessStartDate || "N/A"}
                  </h6>
                </div>

                <div className="col-12 col-md-6 mb-2 mt-2">
                  <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_BUSINESS_NATURE[appContext.language]}</label>
                  <h6
                    className="font-regular"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {data.businessNature || "N/A"}
                  </h6>
                </div>

                <div className="col-12 col-md-6 mb-2 mt-2">
                  <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_PHONE_NUMBERS[appContext.language]}</label>
                  <h6
                    className="font-regular"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {data.phone1}<br />
                    {data.phone2}<br />
                    {data.phone3}<br />
                  </h6>
                </div>

                <div className="col-12 col-md-6 mb-2 mt-2">
                  <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_EMAIL[appContext.language]}</label>
                  <h6
                    className="font-regular"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {data.email || "N/A"}
                  </h6>
                </div>



                <div className="col-12 col-md-6 mb-2 mt-2">
                  <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_FIRSTNAME[appContext.language]}</label>
                  <h6
                    className="font-regular"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {data.firstName || "N/A"}
                  </h6>
                </div>

                <div className="col-12 col-md-6 mb-2 mt-2">
                  <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_MIDDLENAME[appContext.language]}</label>
                  <h6
                    className="font-regular"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {data.middleName || "N/A"}
                  </h6>
                </div>

                <div className="col-12 col-md-6 mb-2 mt-2">
                  <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_LASTNAME[appContext.language]}</label>
                  <h6
                    className="font-regular"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {data.lastName || "N/A"}
                  </h6>
                </div>

                <div className="col-12 col-md-6 mb-2 mt-2">
                  <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_INCORPORATION_NUMBER[appContext.language]}</label>
                  <h6
                    className="font-regular"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {data.incorporationNumber || "N/A"}
                  </h6>
                </div>

                {
                  (brelaInfo && brelaInfo.shareHolders) ?
                    <div className="col-md-12 mb-2 mt-2">
                      <h6
                        style={{
                          fontSize: "18px",
                        }}
                        className="text-secondary"
                      >
                        {STR_SHAREHOLDERS_INFO[appContext.language]}
                      </h6>

                      <div style={{ overflowX: 'auto' }}> {/* Wrapper for horizontal scrolling */}
                        <table className="table table-striped" style={{ minWidth: '500px' }}>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Phone</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              brelaInfo.shareHolders.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      {item.company_name || (item.first_name + " " + item.middle_name + " " + item.last_name)}
                                    </td>
                                    <td>
                                      {item.email}
                                    </td>
                                    <td>
                                      {item.mob_phone}
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                    :
                    <div className="col-md-12 mb-2 mt-2">
                      <h6
                        style={{
                          fontSize: "18px",
                        }}
                        className="text-secondary"
                      >
                        {STR_SHAREHOLDERS_INFO[appContext.language]}
                      </h6>

                      <div className="text-start mt-4 mb-4">
                        <button
                          className="btn btn-sm btn-rounded btn-dark"
                          onClick={getBrelaInfo}
                        >
                          {STR_GET_SHAREHOLDERS_INFO[appContext.language]}
                        </button>
                      </div>
                    </div>
                }

              </div>
            </>
            :
            <div
              className="container"
            >
              <h6>{STR_NO_RECORDS[appContext.language]}</h6>
            </div>
        }

      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}