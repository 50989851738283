import React, { useContext } from 'react';
import { formatMoney } from '../Helpers';
import { AppContext } from "../App";

const BusinessMetricsReport = ({ metricsReport, startDate, endDate }) => {
  const appContext = useContext(AppContext);

  const renderMetricRow = (id, metricData) => {
    return (
      <div className="col-md-6 mt-3">
        <div className="card">
          <div className="card-header font-semi-bold">
            {appContext.getBusinessMetricDescription(id)} {/* Metric title */}
          </div>
          <div className="card-body">
            <ul className="list-group">
              {metricData.accounts && metricData.accounts.map((account, i) => (
                <li key={i} className="list-group-item d-flex justify-content-between align-items-center">
                  {account.title} {/* Account title */}
                  <span style={{ fontSize: "14px" }} className="badge bg-dark rounded-pill font-semi-bold">{formatMoney(account.balance)}</span>
                </li>
              ))}
              {/* Show total for each metric */}
              <li className="list-group-item d-flex justify-content-between align-items-center font-bold">
                {appContext.getBusinessMetricDescription(id)} Total {/* Metric total */}
                <span style={{ fontSize: "18px" }} className="badge bg-success rounded-pill font-semi-bold">{formatMoney(metricData.total)}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container mt-5">
      <h2 className="font-semi-bold mb-3">Business Metrics Report</h2>
      <p className="text-muted">For the period: {startDate} - {endDate}</p>

      <div className="row">
        {/* Financial Metrics */}
        {renderMetricRow('mtaji_unaozunguka', metricsReport.mtaji_unaozunguka)}
        {renderMetricRow('manunuzi_uzalishaji', metricsReport.manunuzi_uzalishaji)}
        {renderMetricRow('mauzo', metricsReport.mauzo)}
        {renderMetricRow('faida_ghafi', { accounts: [], total: metricsReport.faida_ghafi.total })} {/* Gross profit without account details */}
        {renderMetricRow('gharama_uendeshaji', metricsReport.gharama_uendeshaji)}
        {renderMetricRow('faida_kamili', { accounts: [], total: metricsReport.faida_kamili.total })} {/* Net profit without account details */}
        {renderMetricRow('mikopo', metricsReport.mikopo)}
        {renderMetricRow('madeni', metricsReport.madeni)}
        {renderMetricRow('stock', metricsReport.stock)}
        {renderMetricRow('uwekezaji_uliofanyika', metricsReport.uwekezaji_uliofanyika)}

        {/* Follow-up Metrics (with placeholders for now) */}
        {renderMetricRow('wateja_waliotembelewa', metricsReport.wateja_waliotembelewa)}
        {renderMetricRow('wateja_waliopigiwa_simu', metricsReport.wateja_waliopigiwa_simu)}
        {renderMetricRow('wateja_walionunua', metricsReport.wateja_walionunua)}
        {renderMetricRow('wateja_wapya_walionunua', metricsReport.wateja_wapya_walionunua)}
        {renderMetricRow('wateja_waliofuatiliwa', metricsReport.wateja_waliofuatiliwa)}
      </div>
    </div>
  );
};

export default BusinessMetricsReport;
