import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatDateForInput, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ACCEPT, STR_CALL, STR_CHANGE_PASSWORD, STR_CREATE_GOAL, STR_CREATE_TODO, STR_DASHBOARD, STR_EDIT, STR_MANAGE_ACTIVE_GOAL, STR_MARK_AS_PAID, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_REMOVE, STR_REPORT_FOLLOWUP, STR_REPORT_SALES, STR_REPORT_VISIT, STR_SEND_EMAIL, STR_SEND_SMS, STR_SUCCESS, STR_TODOS, STR_TURN_TO_CLIENT, STR_VIEW_GOAL, STR_VIEW_MORE_INFO, STR_WATEJA } from "../Strings";
import CustomersHome from '../views/CustomersHome';
import { MdBlock, MdCall, MdCheckCircle, MdEdit, MdEmail, MdFacebook, MdMessage, MdOutlineFacebook, MdOutlineWhatsapp, MdPassword, MdTask, MdWhatsapp } from "react-icons/md";
import { BiInfoCircle, BiLogoLinkedin, BiLogoWhatsapp, BiPurchaseTag } from "react-icons/bi";
import { SlUserFollowing } from "react-icons/sl";
import { BsInstagram, BsTelegram } from "react-icons/bs";
import { TbTarget } from "react-icons/tb";

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    await getData()
    setReady(true);
  }

  async function getData() {
    await callApi("get-commission-data", { id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }


  async function accept() {
    setLoading(true);
    await callApi("confirm-commission", { id }).then(response => {
      if (response.status === 1) {
        appContext.tellMessage(STR_SUCCESS[appContext.language]);
        getData();
        appContext.getAllCommissions();
      } else {
        appContext.tellError(response.msg);
      }
    })
    setLoading(false);
  }

  function viewGoal() {
    appContext.navTo({
      item: 'view',
      subItem: 'goal',
      extraItem: data.userGoalId,
    })
  }

  async function markAsPaid() {
    setLoading(true);
    await callApi("pay-commission", { id }).then(response => {
      if (response.status === 1) {
        appContext.tellMessage(STR_SUCCESS[appContext.language]);
        getData();
        appContext.getAllCommissions();
      } else {
        appContext.tellError(response.msg);
      }
    })
    setLoading(false);
  }

  useEffect(() => {
    init();
  }, [id])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready && data) {
    return (
      <div
        className="container mw-700"
      >
        <h6
          className="font-semi-bold mt-4"
          style={{
            fontSize: "24px",
            textTransform: "capitalize",
          }}
        >
          {formatMoney(data.amount)} TZS
        </h6>
        <h6
          className="font-semi-bold text-muted"
          style={{
            fontSize: "18px",
            textTransform: "capitalize",
          }}
        >
          {data.phone || data.email} <br />
          {data.name}
        </h6>
        <div className="form-text">{formatDateForInput(data.dateRequested)}</div>

        <h6
          className='text-start font-bold mb-4 mt-4'
          style={{
            fontSize: "12px",
            borderLeft: "6px solid var(--primaryColor)",
            paddingLeft: "5px"
          }}
        >
          {data.status}
        </h6>


        <div
          style={{
            width: "100%",
            padding: "10px",
          }}
          className="text-center"
        >
          {
            (
              data.status === 'pending' &&
              (appContext.currentUserRole === 'manager' || appContext.currentUserRole === 'owner')
            ) ?
              <button
                className="btn btn-outline-dark btn-block btn-rounded mb-4 btn-padded"
                onClick={() => {
                  appContext.activateDialog({
                    message: STR_ACCEPT[appContext.language] + "?",
                    onConfirm: accept,
                  })
                }}
              >
                {STR_ACCEPT[appContext.language]}
              </button>
              : ""
          }

          {
            (
              data.status === 'unpaid' &&
              (appContext.currentUserRole === 'manager' || appContext.currentUserRole === 'owner')
            ) ?
              <button
                className="btn btn-outline-dark btn-block btn-rounded mb-4 btn-padded"
                onClick={() => {
                  appContext.activateDialog({
                    message: STR_MARK_AS_PAID[appContext.language] + "?",
                    onConfirm: markAsPaid,
                  })
                }}
              >
                {STR_MARK_AS_PAID[appContext.language]}
              </button>
              : ""
          }

          <button
            className="btn btn-outline-dark btn-block btn-rounded mb-4 btn-padded"
            onClick={() => {
              viewGoal()
            }}
          >
            {STR_VIEW_GOAL[appContext.language]}
          </button>


        </div>

      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}