import React from 'react';
import { formatMoney } from '../Helpers';

const BalanceSheetReport = ({ balanceSheet, endDate }) => {
  return (
    <div className="container mt-5">
      <h2 className="font-semi-bold mb-3">Balance Sheet</h2>
      <p className="text-muted">As of: {endDate}</p>

      {/* Assets Section */}
      <div className="row">
        <div className="col-12 col-md-6">
          <h4 className="font-semi-bold">Assets</h4>
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th>Account</th>
                <th>Amount (TZS)</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(balanceSheet.assets.accounts).map(([account, amount], index) => (
                <tr key={index}>
                  <td>{account}</td>
                  <td className="text-end">{formatMoney(amount)}</td>
                </tr>
              ))}
              <tr className="font-bold">
                <td>Total Assets</td>
                <td className="text-end">{formatMoney(balanceSheet.assets.total)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Liabilities Section */}
        <div className="col-12 col-md-6">
          <h4 className="font-semi-bold">Liabilities</h4>
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th>Account</th>
                <th>Amount (TZS)</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(balanceSheet.liabilities.accounts).map(([account, amount], index) => (
                <tr key={index}>
                  <td>{account}</td>
                  <td className="text-end">{formatMoney(amount)}</td>
                </tr>
              ))}
              <tr className="font-bold">
                <td>Total Liabilities</td>
                <td className="text-end">{formatMoney(balanceSheet.liabilities.total)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Equity Section */}
      <div className="row mt-4">
        <div className="col-12 col-md-6">
          <h4 className="font-semi-bold">Equity</h4>
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th>Account</th>
                <th>Amount (TZS)</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(balanceSheet.equity.accounts).map(([account, amount], index) => (
                <tr key={index}>
                  <td>{account}</td>
                  <td className="text-end">{formatMoney(amount)}</td>
                </tr>
              ))}
              <tr className="font-bold">
                <td>Total Equity</td>
                <td className="text-end">{formatMoney(balanceSheet.equity.total)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Summary Section */}
        <div className="col-12 col-md-6">
          <h4 className="font-semi-bold">Summary</h4>
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th>Category</th>
                <th>Amount (TZS)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Total Assets</td>
                <td className="text-end">{formatMoney(balanceSheet.assets.total)}</td>
              </tr>
              <tr>
                <td>Total Liabilities</td>
                <td className="text-end">{formatMoney(balanceSheet.liabilities.total)}</td>
              </tr>
              <tr>
                <td>Total Equity</td>
                <td className="text-end">{formatMoney(balanceSheet.equity.total)}</td>
              </tr>

              {/* If system-generated Opening Balance Equity exists */}
              {balanceSheet.equity.accounts["Opening Balance Equity (System Generated)"] && (
                <tr>
                  <td>Opening Balance Equity (System Generated)</td>
                  <td className="text-end">{formatMoney(balanceSheet.equity.accounts["Opening Balance Equity (System Generated)"])}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BalanceSheetReport;
