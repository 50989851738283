import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatDateForInput, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_APPLY_BONUS_ON_SALES, STR_APPLY_BONUS_ON_UNIQUE_CUSTOMERS_ENGAGED, STR_APPLY_COMMISSION_ON_SALES, STR_CALL, STR_COMMISSION_PERCENT, STR_CREATE_GOAL, STR_DASHBOARD, STR_DATE, STR_DESCRIPTION, STR_EDIT, STR_END_DATE, STR_INVALID_DATE, STR_INVALID_DESCRIPTION, STR_INVALID_FOLLOWUP_STEP, STR_INVALID_PROMISE_DESCRIPTION, STR_INVALID_PROMISE_DUE_DATE, STR_MANAGE_ACTIVE_GOAL, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NO, STR_PLEASE_WAIT, STR_PROMISE_DESCRIPTION, STR_PROMISE_DUE_DATE, STR_REPORT_FOLLOWUP, STR_REPORT_SALES, STR_REPORT_VISIT, STR_SALES_BONUS_AMOUNT, STR_SALES_TARGET, STR_SEND_EMAIL, STR_SEND_SMS, STR_START_DATE, STR_STEP, STR_SUBMIT, STR_SUCCESS, STR_TODOS, STR_TURN_TO_CLIENT, STR_UNIQUE_CUSTOMERS_BONUS_AMOUNT, STR_UNIQUE_CUSTOMERS_ENGAGED_TARGET, STR_VIEW_MORE_INFO, STR_WAS_ANY_PROMISE_MADE, STR_WATEJA, STR_YES } from "../Strings";
import CustomersHome from './CustomersHome';
import { MdCall, MdCheckCircle, MdEdit, MdEmail, MdFacebook, MdMessage, MdOutlineFacebook, MdOutlineWhatsapp, MdWhatsapp } from "react-icons/md";
import { BiInfoCircle, BiLogoLinkedin, BiLogoWhatsapp, BiPurchaseTag } from "react-icons/bi";
import { SlUserFollowing } from "react-icons/sl";
import { BsInstagram, BsTelegram } from "react-icons/bs";

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(null);
  const [activeGoalData, setActiveGoalData] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userMetrics, setUserMetrics] = useState();
  const [uniqueCustomersEngagedTarget, setUniqueCustomersEngagedTarget] = useState(0);
  const [applyBonusOnSales, setApplyBonusOnSales] = useState('no');
  const [applyBonusOnCustomers, setApplyBonusOnCustomers] = useState('no');
  const [salesBonusAmount, setSalesBonusAmount] = useState(0);
  const [customersBonusAmount, setCustomersBonusAmount] = useState(0);
  const [applyCommissionOnSales, setApplyCommissionOnSales] = useState('no');
  const [salesCommissionPercent, setSalesCommissionPercent] = useState(0);
  const [salesTarget, setSalesTarget] = useState(0);

  async function init() {
    //run initializations here
    setReady(false);
    await Promise.all([
      getData(),
    ])
    setReady(true);
  }


  function handleMetricTargetChange(_index, _value) {
    setUserMetrics(_prev => {
      const _new = [..._prev];

      if (!isNaN(Number(_value))) {
        _new[_index].target = Number(_value);
      }

      return _new;
    })
  }

  async function getData() {
    await callApi("get-user-data", { uid: id }).then(async response => {
      if (response.status === 1) {
        setData(response.data);
        await callApi("get-user-active-goal", { id: response.data.id, businessId: appContext.currentBusinessId }).then(response => {
          if (response.status === 1) {
            setActiveGoalData(response.data);
          }
        })
      }
    })
  }



  async function saveGoal() {
    const _runner = async () => {
      setLoading(true);
      await callApi(
        "update-user-goal-info",
        {
          id: activeGoalData.id,
          memberId: data.id,
          businessId: appContext.currentBusinessId,
          startDate,
          endDate,
          userMetrics: JSON.stringify(userMetrics),
          uniqueCustomersEngagedTarget,
          applyBonusOnSales,
          applyBonusOnCustomers,
          salesBonusAmount,
          customersBonusAmount,
          applyCommissionOnSales,
          salesCommissionPercent,
          salesTarget,
        }
      ).then(response => {
        if (response.status === 1) {
          appContext.tellMessage(STR_SUCCESS[appContext.language]);
          appContext.navBack();
        } else {
          appContext.tellError(response.msg);
        }
      })
      setLoading(false);
    }

    if (!loading) {
      if (startDate.trim().length > 0 && endDate.trim().length > 0) {
        await _runner();
      } else {
        appContext.tellError(STR_INVALID_DATE[appContext.language]);
      }

    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language])
    }
  }


  useEffect(() => {
    init();
  }, [id])

  useEffect(() => {
    if (activeGoalData) {
      setUserMetrics(JSON.parse(activeGoalData.userMetrics));
      setStartDate(formatDateForInput(activeGoalData.startDate));
      setEndDate(formatDateForInput(activeGoalData.endDate));
      setUniqueCustomersEngagedTarget(activeGoalData.uniqueCustomersEngagedTarget);
      setApplyBonusOnSales(activeGoalData.applyBonusOnSales);
      setApplyBonusOnCustomers(activeGoalData.applyBonusOnCustomers);
      setSalesBonusAmount(activeGoalData.salesBonusAmount);
      setCustomersBonusAmount(activeGoalData.customersBonusAmount);
      setApplyCommissionOnSales(activeGoalData.applyCommissionOnSales);
      setSalesCommissionPercent(activeGoalData.salesCommissionPercent);
      setSalesTarget(activeGoalData.salesTarget);
    }
  }, [activeGoalData])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])



  if (ready && data) {
    return (
      <div
        className="container mw-700"
      >
        <h6 className="mSectionTitle">{STR_MANAGE_ACTIVE_GOAL[appContext.language]}</h6>

        <div className="card">
          <div className="card-body">
            <h6
              className="font-semi-bold"
              style={{
                fontSize: "18px",
                textTransform: "capitalize",
              }}
            >
              {data.name}
            </h6>
            <h6
              className="font-semi-bold text-muted"
              style={{
                fontSize: "14px",
                textTransform: "capitalize",
              }}
            >
              {data.phone}
            </h6>
          </div>
        </div>

        <div className="row">

          <div className="col-6 mb-2 mt-2">
            <label className="form-label">{STR_START_DATE[appContext.language]}</label>
            <input
              className="form-control"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              type="date"
            />
          </div>

          <div className="col-6 mb-2 mt-2">
            <label className="form-label">{STR_END_DATE[appContext.language]}</label>
            <input
              className="form-control"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              type="date"
            />
          </div>

          <div className="col-md-12 mb-4 mt-4">
            <table
              className="table table-striped"
            >
              <tbody>
                {
                  (userMetrics && userMetrics.length > 0) ?
                    userMetrics.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{appContext.getUserMetricDescription(item.id)}</td>
                          <td>
                            <input
                              className="form-control"
                              value={item.target}
                              onChange={(e) => handleMetricTargetChange(i, e.target.value)}
                            />
                          </td>
                        </tr>
                      )
                    })
                    : null
                }
              </tbody>
            </table>
          </div>

          <div className="col-md-6 mb-2 mt-2">
            <label className="form-label">{STR_APPLY_BONUS_ON_UNIQUE_CUSTOMERS_ENGAGED[appContext.language]}?</label>
            <select
              className="form-control"
              value={applyBonusOnCustomers}
              onChange={(e) => setApplyBonusOnCustomers(e.target.value)}
            >
              <option value="yes">{STR_YES[appContext.language]}</option>
              <option value="no">{STR_NO[appContext.language]}</option>
            </select>
          </div>

          {
            (applyBonusOnCustomers === 'yes') ?
              <>
                <div className="col-md-6 mb-2 mt-2">
                  <label className="form-label">{STR_UNIQUE_CUSTOMERS_ENGAGED_TARGET[appContext.language]}</label>
                  <input
                    className="form-control"
                    value={uniqueCustomersEngagedTarget}
                    onChange={(e) => setUniqueCustomersEngagedTarget(e.target.value)}
                    type="number"
                  />
                </div>

                <div className="col-md-6 mb-2 mt-2">
                  <label className="form-label">{STR_UNIQUE_CUSTOMERS_BONUS_AMOUNT[appContext.language]}</label>
                  <input
                    className="form-control"
                    value={customersBonusAmount}
                    onChange={(e) => setCustomersBonusAmount(e.target.value)}
                    type="number"
                  />
                </div>
              </>
              : ""
          }

          <div className="col-md-6 mb-2 mt-2">
            <label className="form-label">{STR_APPLY_BONUS_ON_SALES[appContext.language]}?</label>
            <select
              className="form-control"
              value={applyBonusOnSales}
              onChange={(e) => setApplyBonusOnSales(e.target.value)}
            >
              <option value="yes">{STR_YES[appContext.language]}</option>
              <option value="no">{STR_NO[appContext.language]}</option>
            </select>
          </div>

          {
            (applyBonusOnSales === 'yes') ?
              <>
                <div className="col-md-6 mb-2 mt-2">
                  <label className="form-label">{STR_SALES_BONUS_AMOUNT[appContext.language]}</label>
                  <input
                    className="form-control"
                    value={salesBonusAmount}
                    onChange={(e) => setSalesBonusAmount(e.target.value)}
                    type="number"
                  />
                </div>
              </>
              : ""
          }

          {
            (applyBonusOnSales === 'yes' || applyCommissionOnSales === 'yes') ?
              <>
                <div className="col-md-6 mb-2 mt-2">
                  <label className="form-label">{STR_SALES_TARGET[appContext.language]}</label>
                  <input
                    className="form-control"
                    value={salesTarget}
                    onChange={(e) => setSalesTarget(e.target.value)}
                    type="number"
                  />
                </div>
              </>
              : ""
          }

          <div className="col-md-6 mb-2 mt-2">
            <label className="form-label">{STR_APPLY_COMMISSION_ON_SALES[appContext.language]}?</label>
            <select
              className="form-control"
              value={applyCommissionOnSales}
              onChange={(e) => setApplyCommissionOnSales(e.target.value)}
            >
              <option value="yes">{STR_YES[appContext.language]}</option>
              <option value="no">{STR_NO[appContext.language]}</option>
            </select>
          </div>


          {
            (applyCommissionOnSales === 'yes') ?
              <>
                <div className="col-md-6 mb-2 mt-2">
                  <label className="form-label">{STR_COMMISSION_PERCENT[appContext.language]}</label>
                  <input
                    className="form-control"
                    value={salesCommissionPercent}
                    onChange={(e) => setSalesCommissionPercent(e.target.value)}
                    type="number"
                  />
                </div>
              </>
              : ""
          }

          <div className="col-md-12 mb2 mt-2 text-end">
            <button
              className="btn btn-primary btn-padded btn-rounded"
              onClick={() => {
                appContext.activateDialog({
                  message: STR_SUBMIT[appContext.language] + "?",
                  onConfirm: saveGoal
                })
              }}
            >
              {STR_SUBMIT[appContext.language]}
            </button>
          </div>

        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}