import React from 'react';
import { formatMoney } from '../Helpers';

const IncomeStatement = ({ report, startDate, endDate }) => {
  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4 font-bold">Income Statement</h2>

      <div className="row mb-3">
        <div className="col-md-6">
          <strong>Start Date: </strong> {startDate}
        </div>
        <div className="col-md-6">
          <strong>End Date: </strong> {endDate}
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <table className="table table-bordered table-hover">
            <thead>
              <tr className="bg-light">
                <th className="font-semi-bold">Category</th>
                <th className="font-semi-bold text-end">Amount (TZS)</th>
              </tr>
            </thead>
            <tbody>
              {/* Revenue Accounts */}
              <tr className="bg-light">
                <td colSpan="2" className="font-bold">Revenue Accounts</td>
              </tr>
              {Object.keys(report.revenueAccounts).map((accountTitle, index) => (
                <tr key={index}>
                  <td>{accountTitle}</td>
                  <td className="text-end">{formatMoney(report.revenueAccounts[accountTitle])}</td>
                </tr>
              ))}
              <tr className="font-semi-bold">
                <td>Total Revenue</td>
                <td className="text-end">{formatMoney(report.totalRevenue)}</td>
              </tr>

              {/* Direct Costs Accounts */}
              <tr className="bg-light">
                <td colSpan="2" className="font-bold">Cost of Goods Sold (COGS)</td>
              </tr>
              {Object.keys(report.directCostsAccounts).map((accountTitle, index) => (
                <tr key={index}>
                  <td>{accountTitle}</td>
                  <td className="text-end">{formatMoney(report.directCostsAccounts[accountTitle])}</td>
                </tr>
              ))}
              <tr className="font-semi-bold">
                <td>Total COGS</td>
                <td className="text-end">{formatMoney(report.totalDirectCosts)}</td>
              </tr>

              {/* Gross Profit */}
              <tr className="font-semi-bold">
                <td>Gross Profit</td>
                <td className="text-end font-semi-bold">{formatMoney(report.grossProfit)}</td>
              </tr>

              {/* Other Income Accounts */}
              <tr className="bg-light">
                <td colSpan="2" className="font-bold">Other Income</td>
              </tr>
              {Object.keys(report.otherIncomeAccounts).map((accountTitle, index) => (
                <tr key={index}>
                  <td>{accountTitle}</td>
                  <td className="text-end">{formatMoney(report.otherIncomeAccounts[accountTitle])}</td>
                </tr>
              ))}
              <tr className="font-semi-bold">
                <td>Total Other Income</td>
                <td className="text-end">{formatMoney(report.otherIncomeTotal)}</td>
              </tr>

              {/* Operating Expenses Accounts */}
              <tr className="bg-light">
                <td colSpan="2" className="font-bold">Operating Expenses</td>
              </tr>
              {Object.keys(report.expenseAccounts).map((accountTitle, index) => (
                <tr key={index}>
                  <td>{accountTitle}</td>
                  <td className="text-end">{formatMoney(report.expenseAccounts[accountTitle])}</td>
                </tr>
              ))}
              <tr className="font-semi-bold">
                <td>Total Operating Expenses</td>
                <td className="text-end">{formatMoney(report.totalExpenses)}</td>
              </tr>

              {/* Net Profit */}
              <tr className="bg-light font-bold">
                <td>Net Profit</td>
                <td className="text-end">{formatMoney(report.netProfit)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default IncomeStatement;
