import React, { useContext } from 'react';
import { formatMoney } from '../Helpers';
import { AppContext } from '../App';

const CashFlowDriversReport = ({ report, startDate, endDate }) => {
  const appContext = useContext(AppContext);

  const renderDriverRow = (label, value) => {
    return (
      <tr key={label}>
        <td className="font-semi-bold">{label}</td>
        <td className="text-end">{value ? formatMoney(value) : '-'}</td>
      </tr>
    );
  };

  return (
    <div className="container mt-5">
      <h2 className="font-semi-bold mb-3">Cash Flow Drivers Report</h2>
      <p className="text-muted">For the period: {startDate} - {endDate}</p>

      <div className="row">
        <div className="col-12 col-md-6">
          <h4 className="font-semi-bold">DSO (Days Sales Outstanding)</h4>
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th>Metric</th>
                <th className="text-end">Amount (TZS)</th>
              </tr>
            </thead>
            <tbody>
              {renderDriverRow('Total Receivables', report.totalReceivables)}
              {renderDriverRow('Total Credit Sales', report.totalCreditSales)}
              {renderDriverRow('DSO (Days Sales Outstanding)', report.DSO)}
            </tbody>
          </table>
        </div>

        <div className="col-12 col-md-6">
          <h4 className="font-semi-bold">DIO (Days Inventory Outstanding)</h4>
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th>Metric</th>
                <th className="text-end">Amount (TZS)</th>
              </tr>
            </thead>
            <tbody>
              {renderDriverRow('Total Inventory', report.totalInventory)}
              {renderDriverRow('Total COGS (Cost of Goods Sold)', report.totalCOGS)}
              {renderDriverRow('DIO (Days Inventory Outstanding)', report.DIO)}
            </tbody>
          </table>
        </div>

        <div className="col-12 col-md-6 mt-4">
          <h4 className="font-semi-bold">DPO (Days Payable Outstanding)</h4>
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th>Metric</th>
                <th className="text-end">Amount (TZS)</th>
              </tr>
            </thead>
            <tbody>
              {renderDriverRow('Total Payables', report.totalPayables)}
              {renderDriverRow('Total Purchases', report.totalPurchases)}
              {renderDriverRow('DPO (Days Payable Outstanding)', report.DPO)}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CashFlowDriversReport;
