import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ADD_STOCK, STR_AMOUNT, STR_AVAILABLE_QUANTITY, STR_CALL, STR_CHANGE_PASSWORD, STR_CREATE_GOAL, STR_CREATE_TODO, STR_DASHBOARD, STR_EDIT, STR_INVALID_AMOUNT, STR_INVALID_UNIT_PRICE, STR_ITEM_TYPE, STR_MANAGE_ACTIVE_GOAL, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NAME, STR_PLEASE_WAIT, STR_PRODUCT_CODE, STR_REDUCE_STOCK, STR_REMOVE, STR_REPORT_FOLLOWUP, STR_REPORT_SALES, STR_REPORT_VISIT, STR_SEND_EMAIL, STR_SEND_SMS, STR_SUBMIT, STR_SUCCESS, STR_TODOS, STR_TURN_TO_CLIENT, STR_UNIT, STR_UNIT_BUYING_PRICE, STR_UNIT_RETAIL_SELLING_PRICE, STR_VIEW_MORE_INFO, STR_WATEJA } from "../Strings";
import CustomersHome from '../views/CustomersHome';
import { MdBlock, MdCall, MdCheckCircle, MdEdit, MdEmail, MdFacebook, MdMessage, MdOutlineFacebook, MdOutlineWhatsapp, MdPassword, MdTask, MdWhatsapp } from "react-icons/md";
import { BiInfoCircle, BiLogoLinkedin, BiLogoWhatsapp, BiPurchaseTag } from "react-icons/bi";
import { SlUserFollowing } from "react-icons/sl";
import { BsInstagram, BsTelegram } from "react-icons/bs";
import { TbTarget } from "react-icons/tb";

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [amountToAdd, setAmountToAdd] = useState(0);
  const [amountToReduce, setAmountToReduce] = useState(0);
  const [unitBuyingPrice, setUnitBuyingPrice] = useState(0);

  async function init() {
    //run initializations here
    setReady(false);
    await getData()
    setReady(true);
  }

  async function getData() {
    await callApi("get-stock-data", { id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }

  async function addStock() {
    if(!loading) {
      if(!isNaN(Number(amountToAdd)) && Number(amountToAdd) > 0) {
        if(!isNaN(Number(unitBuyingPrice)) && Number(unitBuyingPrice) > 0) {
          setLoading(true);
          await callApi(
            'add-stock-quantity',
            {
              amount: amountToAdd,
              unitBuyingPrice,
              id: data.id
            }
          ).then(response => {
            if(response.status === 1) {
              appContext.tellMessage(STR_SUCCESS[appContext.language]);
              getData();
            } else {
              appContext.tellError(response.msg);
            }
          })
          setLoading(false);
        } else {
          appContext.tellError(STR_INVALID_UNIT_PRICE[appContext.language]);
        }
      } else {
        appContext.tellError(STR_INVALID_AMOUNT[appContext.language]);
      }
    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language]);
    }
  }

  
  async function reduceStock() {
    if(!loading) {
      if(!isNaN(Number(amountToReduce)) && Number(amountToReduce) > 0) {
        //..
        setLoading(true);
        await callApi(
          'reduce-stock',
          {
            amount: amountToReduce,
            id: data.id
          }
        ).then(response => {
          if(response.status === 1) {
            appContext.tellMessage(STR_SUCCESS[appContext.language]);
            getData();
          } else {
            appContext.tellError(response.msg);
          }
        })
        setLoading(false);
        //..
      } else {
        appContext.tellError(STR_INVALID_AMOUNT[appContext.language]);
      }
    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language]);
    }
  }

  useEffect(() => {
    init();
  }, [id])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  useEffect(() => {
    if(data) {
      setUnitBuyingPrice(data.unitBuyingPrice);
    }
  }, [ data ])

  if (ready && data) {
    return (
      <div
        className="container"
      >
        
				<div className="row">

					<div className="col-md-12">
						<h6 className="mSectionTitle">{data.name}</h6>
					</div>

					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="row">

                  <div className="col-md-6 mt-3 mb-3">
                    <h6
                      style={{
                        fontSize: "16px"
                      }}
                      className="font-semi-bold"
                    >
                      {data.itemType || 'N/A'}
                      <span className="d-block text-muted" style={{ fontSize: "12px" }}>{STR_ITEM_TYPE[appContext.language]}</span>
                    </h6>
                  </div>


                  <div className="col-md-6 mt-3 mb-3">
                    <h6
                      style={{
                        fontSize: "16px"
                      }}
                      className="font-semi-bold"
                    >
                      {data.code || 'N/A'}
                      <span className="d-block text-muted" style={{ fontSize: "12px" }}>{STR_PRODUCT_CODE[appContext.language]}</span>
                    </h6>
                  </div>

                  <div className="col-md-6 mt-3 mb-3">
                    <h6
                      style={{
                        fontSize: "16px"
                      }}
                      className="font-semi-bold"
                    >
                      {data.name || 'N/A'}
                      <span className="d-block text-muted" style={{ fontSize: "12px" }}>{STR_NAME[appContext.language]}</span>
                    </h6>
                  </div>

                  <div className="col-md-6 mt-3 mb-3">
                    <h6
                      style={{
                        fontSize: "16px"
                      }}
                      className="font-semi-bold"
                    >
                      {data.unit || 'N/A'}
                      <span className="d-block text-muted" style={{ fontSize: "12px" }}>{STR_UNIT[appContext.language]}</span>
                    </h6>
                  </div>

                  <div className="col-md-6 mt-3 mb-3">
                    <h6
                      style={{
                        fontSize: "24px"
                      }}
                      className="font-semi-bold"
                    >
                      {data.quantity ? formatMoney(data.quantity) : 'N/A'}
                      <span className="d-block text-muted" style={{ fontSize: "12px" }}>{STR_AVAILABLE_QUANTITY[appContext.language]}</span>
                    </h6>
                  </div>

                  <div className="col-md-6 mt-3 mb-3">
                    <h6
                      style={{
                        fontSize: "24px"
                      }}
                      className="font-semi-bold"
                    >
                      {data.unitBuyingPrice ? formatMoney(data.unitBuyingPrice) : 'N/A'}
                      <span className="d-block text-muted" style={{ fontSize: "12px" }}>{STR_UNIT_BUYING_PRICE[appContext.language]}</span>
                    </h6>
                  </div>

                  <div className="col-md-6 mt-3 mb-3">
                    <h6
                      style={{
                        fontSize: "24px"
                      }}
                      className="font-semi-bold"
                    >
                      {data.unitRetailSellingPrice ? formatMoney(data.unitRetailSellingPrice) : 'N/A'}
                      <span className="d-block text-muted" style={{ fontSize: "12px" }}>{STR_UNIT_RETAIL_SELLING_PRICE[appContext.language]}</span>
                    </h6>
                  </div>

                  <div className="col-md-6 mt-3 mb-3">
                    <h6
                      style={{
                        fontSize: "24px"
                      }}
                      className="font-semi-bold"
                    >
                      {data.unitWholesaleSellingPrice ? formatMoney(data.unitWholesaleSellingPrice) : 'N/A'}
                      <span className="d-block text-muted" style={{ fontSize: "12px" }}>{STR_UNIT_RETAIL_SELLING_PRICE[appContext.language]}</span>
                    </h6>
                  </div>

									<div className="col-12">
										<button
											className="btn btn-dark btn-sm btn-block"
                      onClick={() => {
                        appContext.navTo({
                          item: 'view',
                          subItem: 'edit-stock',
                          extraItem: data.id,
                        })
                      }}
										>
											{STR_EDIT[appContext.language]}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>

          <div className="col-md-6" style={{ display: data.itemType === 'non-stock' ? 'none' : 'block' }}>
						<div className="card">
							<div className="card-body">

                <h6
                  className="font-bold"
                  style={{
                    fontSize: "18px",
                    textTransform: "uppercase",
                  }}
                >
                  {STR_ADD_STOCK[appContext.language]}
                </h6>

                <div className="mt-2 mb-2">
                  <label className="form-label">{STR_AMOUNT[appContext.language]}</label>
                  <input
                    className="form-control"
                    value={amountToAdd}
                    onChange={(e) => setAmountToAdd(e.target.value)}
                  />
                </div>

                <div className="mt-2 mb-2">
                  <label className="form-label">{STR_UNIT_BUYING_PRICE[appContext.language]}</label>
                  <input
                    className="form-control"
                    value={unitBuyingPrice}
                    onChange={(e) => setUnitBuyingPrice(e.target.value)}
                  />
                </div>

								<div className="mt-4">
                  <button
                    className="btn btn-dark btn-sm btn-block"
                    onClick={() => {
                      appContext.activateDialog({
                        message: STR_SUBMIT[appContext.language]+'?',
                        onConfirm: addStock,
                      })
                    }}
                  >
                    {STR_SUBMIT[appContext.language]}
                  </button>
                </div>
							</div>
						</div>
					</div>

          <div className="col-md-6" style={{ display: data.itemType === 'non-stock' ? 'none' : 'block' }}>
						<div className="card">
							<div className="card-body">

                <h6
                  className="font-bold"
                  style={{
                    fontSize: "18px",
                    textTransform: "uppercase",
                  }}
                >
                  {STR_REDUCE_STOCK[appContext.language]}
                </h6>

                <div className="mt-2 mb-2">
                  <label className="form-label">{STR_AMOUNT[appContext.language]}</label>
                  <input
                    className="form-control"
                    value={amountToReduce}
                    onChange={(e) => setAmountToReduce(e.target.value)}
                  />
                </div>

								<div className="mt-4">
                  <button
                    className="btn btn-dark btn-sm btn-block"
                    onClick={() => {
                      appContext.activateDialog({
                        message: STR_SUBMIT[appContext.language]+'?',
                        onConfirm: reduceStock,
                      })
                    }}
                  >
                    {STR_SUBMIT[appContext.language]}
                  </button>
                </div>
							</div>
						</div>
					</div>

				</div>

      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}