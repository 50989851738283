import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatDateForInput, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ACCOUNTING_JOURNAL, STR_AMOUNT, STR_AMOUNT_PAID, STR_CALL, STR_CASHFLOW_SECTION, STR_CREATE_BILL, STR_CREATE_CASH_EXPENSE, STR_CREATE_CASH_INCOME, STR_CUSTOMER, STR_DASHBOARD, STR_DATE, STR_DATE_CREATED, STR_DATE_DUE, STR_DESCRIPTION, STR_EDIT, STR_ENTER_JOURNAL_RECORD, STR_INVALID_ACCOUNT, STR_INVALID_AMOUNT, STR_INVALID_DATE, STR_INVALID_DESCRIPTION, STR_INVALID_FOLLOWUP_STEP, STR_INVALID_PROMISE_DESCRIPTION, STR_INVALID_PROMISE_DUE_DATE, STR_INVALID_TITLE, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NAME, STR_NO, STR_NO_OPTIONS, STR_OUT_OF_STOCK, STR_PARTICULARS, STR_PLEASE_WAIT, STR_PRODUCT_CODE, STR_PROMISE_DESCRIPTION, STR_PROMISE_DUE_DATE, STR_QUANTITY, STR_REAL_SALE, STR_REPORT_FOLLOWUP, STR_REPORT_SALES, STR_REPORT_VISIT, STR_SALE_TYPE, STR_SELECT_ACCOUNT, STR_SEND_EMAIL, STR_SEND_SMS, STR_STEP, STR_SUB_TOTAL, STR_SUBMIT, STR_SUCCESS, STR_TITLE, STR_TODOS, STR_TOTAL_AMOUNT, STR_TRANSACTION_TYPE, STR_TURN_TO_CLIENT, STR_UNIT, STR_UNIT_PRICE, STR_UP_SALE, STR_VENDOR, STR_VENDORS, STR_VIEW_MORE_INFO, STR_WAS_ANY_PROMISE_MADE, STR_WATEJA, STR_YES } from "../Strings";
import CustomersHome from './CustomersHome';
import { MdAdd, MdCall, MdCheckCircle, MdDelete, MdEdit, MdEmail, MdFacebook, MdMessage, MdOutlineFacebook, MdOutlineWhatsapp, MdWhatsapp } from "react-icons/md";
import { BiInfoCircle, BiLogoLinkedin, BiLogoWhatsapp, BiPurchaseTag } from "react-icons/bi";
import { SlUserFollowing } from "react-icons/sl";
import { BsInstagram, BsTelegram } from "react-icons/bs";
import CustomerWidget from "../ones/CustomerWidget";
import Select from "react-select";


export default function View() {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);

  const [dateCreated, setDateCreated] = useState(formatDateForInput(Date.now() / 1000));
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState(0);
  const [accountId, setAccountId] = useState('');
  const [side, setSide] = useState('debit');
  const [cashFlowSection, setCashFlowSection] = useState('');

  async function init() {
    //run initializations here
    setReady(false);

    await Promise.all([
      appContext.getAllBusinessAccounts(),
    ])


    setReady(true);
  }


  async function create() {
    const _runner = async () => {
      setLoading(true);
      await callApi(
        "create-journal-record",
        {
          amount,
          dateCreated,
          businessId: appContext.currentBusinessId,
          title,
          accountId,
          side,
          cashFlowSection,
        }
      ).then(response => {
        if (response.status === 1) {
          appContext.tellMessage(STR_SUCCESS[appContext.language]);
          setAmount(0);
          setTitle("");

        } else {
          appContext.tellError(response.msg);
        }
      })
      setLoading(false);
    }

    if (!loading) {
      if (title.trim().length > 0) {
        if (dateCreated.trim().length > 0) {
          if (!isNaN(amount) && Number(amount) > 0) {
            if (accountId) {
              await _runner();
            } else {
              appContext.tellError(STR_INVALID_ACCOUNT[appContext.language]);
            }
          } else {
            appContext.tellError(STR_INVALID_AMOUNT[appContext.language]);
          }
        } else {
          appContext.tellError(STR_INVALID_DATE[appContext.language]);
        }
      } else {
        appContext.tellInfo(STR_INVALID_TITLE[appContext.language])
      }


    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language])
    }
  }




  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])



  if (ready) {
    return (
      <div
        className="container"
      >
        <h6 className="mSectionTitle">
          {STR_ACCOUNTING_JOURNAL[appContext.language]}
          <span style={{ fontSize: "16px", textTransform: "uppercase" }} className="d-block text-muted font-bold">{STR_ENTER_JOURNAL_RECORD[appContext.language]}</span>
        </h6>


        <div className="row">
          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_SELECT_ACCOUNT[appContext.language]}</label>
            <select
              className="form-control"
              value={accountId}
              onChange={(e) => setAccountId(e.target.value)}
              type="text"
            >
              <option value=""></option>
              {
                (appContext.allBusinessAccounts && appContext.allBusinessAccounts.length > 0) ?
                  appContext.allBusinessAccounts.map((item, i) => {
                    return (
                      <option key={i} value={item.id}>{item.title}</option>
                    )
                  })
                  : ""
              }
            </select>
          </div>

          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_TITLE[appContext.language]}</label>
            <input
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              type="text"
            />
          </div>

          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_DATE_CREATED[appContext.language]}</label>
            <input
              className="form-control"
              value={dateCreated}
              onChange={(e) => setDateCreated(e.target.value)}
              type="date"
            />
          </div>



          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_TOTAL_AMOUNT[appContext.language]}</label>
            <input
              className="form-control"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}

            />
          </div>

          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_TRANSACTION_TYPE[appContext.language]}</label>
            <select
              className="form-control"
              value={side}
              onChange={(e) => setSide(e.target.value)}
            >
              <option value="debit">Debit</option>
              <option value="credit">Credit</option>
            </select>
          </div>

          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_CASHFLOW_SECTION[appContext.language]}</label>
            <select
              className="form-control"
              value={cashFlowSection}
              onChange={(e) => setCashFlowSection(e.target.value)}
            >
              <option value=""></option>
              <option value="operating_activities">Operating Activities</option>
              <option value="investing_activities">Investing Activities</option>
              <option value="financing_activities">Financing Activities</option>
            </select>
          </div>


          <div className="col-md-12 mb2 mt-2 text-end">
            <button
              className="btn btn-primary btn-padded btn-rounded"
              onClick={() => {
                appContext.activateDialog({
                  message: STR_ENTER_JOURNAL_RECORD[appContext.language] + "?",
                  onConfirm: create
                })
              }}
            >
              {STR_SUBMIT[appContext.language]}
            </button>
          </div>

        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}