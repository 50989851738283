import React from 'react';
import { formatMoney } from '../Helpers';

const CashFlowReport = ({ report, startDate, endDate }) => {
  return (
    <div className="container my-4">
      <h2 className="text-center mb-4">Cash Flow Statement</h2>

      {/* Date Range */}
      <div className="text-center mb-4">
        <p className="font-semi-bold">Date Range: {startDate} - {endDate}</p>
      </div>

      <div className="row justify-content-center">
        <div className="col-12 col-md-8">

          {/* Operating Activities */}
          <div className="card mb-4">
            <div className="card-header bg-primary text-white">
              Operating Activities
            </div>
            <div className="card-body">
              <p><span className="font-semi-bold">Inflows:</span> {formatMoney(report.operating.inflows)} TZS</p>
              <p><span className="font-semi-bold">Outflows:</span> {formatMoney(report.operating.outflows)} TZS</p>
              <p><span className="font-semi-bold">Net Cash Flow:</span> {formatMoney(report.operating.netCashFlow)} TZS</p>
            </div>
          </div>

          {/* Investing Activities */}
          <div className="card mb-4">
            <div className="card-header bg-success text-white">
              Investing Activities
            </div>
            <div className="card-body">
              <p><span className="font-semi-bold">Inflows:</span> {formatMoney(report.investing.inflows)} TZS</p>
              <p><span className="font-semi-bold">Outflows:</span> {formatMoney(report.investing.outflows)} TZS</p>
              <p><span className="font-semi-bold">Net Cash Flow:</span> {formatMoney(report.investing.netCashFlow)} TZS</p>
            </div>
          </div>

          {/* Financing Activities */}
          <div className="card mb-4">
            <div className="card-header bg-info text-white">
              Financing Activities
            </div>
            <div className="card-body">
              <p><span className="font-semi-bold">Inflows:</span> {formatMoney(report.financing.inflows)} TZS</p>
              <p><span className="font-semi-bold">Outflows:</span> {formatMoney(report.financing.outflows)} TZS</p>
              <p><span className="font-semi-bold">Net Cash Flow:</span> {formatMoney(report.financing.netCashFlow)} TZS</p>
            </div>
          </div>

          {/* Total Net Cash Flow */}
          <div className="card">
            <div className="card-header bg-dark text-white">
              Total Net Cash Flow
            </div>
            <div className="card-body">
              <h4 className="text-center">{formatMoney(report.totalNetCashFlow)} TZS</h4>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default CashFlowReport;
