export const STR_APP_NAME = {
  en: "Mauzo CRM & Accounting",
  sw: "Mauzo CRM & Accounting",
};

export const STR_HEALTH = {
  en: "health",
  sw: "afya",
}

export const STR_RELATIONSHIPS = {
  en: "relationships",
  sw: "mahusiano",
}

export const STR_STORIES = {
  en: "stories",
  sw: "masimulizi",
}

export const STR_GET_STARTED = {
  en: "get started",
  sw: "anza sasa",
}

export const STR_CONTINUE = {
  en: "continue",
  sw: "endelea",
}

export const STR_ENTER = {
  en: "enter",
  sw: "ingia",
}

export const STR_ENTER_YOUR_PHONE_NUMBER = {
  en: "Enter your phone number",
  sw: "Ingiza namba yako ya simu",
}

export const STR_PHONE_NUMBER = {
  en: "Phone number",
  sw: "Namba ya simu",
}

export const STR_ENTER_OTP = {
  en: "Enter the OTP sent to your phone",
  sw: "Ingiza OTP uliotumiwa kwenye simu",
}

export const STR_PLEASE_WAIT = {
  en: "Please wait..",
  sw: "Tafadhali subiri..",
}

export const STR_INVALID_PHONE = {
  en: "Invalid phone number",
  sw: "Namba ya simu sio sahihi",
}

export const STR_INVALID_OTP = {
  en: "Invalid OTP",
  sw: "OTP sio sahihi",
}

export const STR_WELCOME = {
  en: "Welcome..",
  sw: "Karibu..",
}

export const STR_CHANGE_PHONE = {
  en: "Change Phone",
  sw: "Badili Simu",
}

export const STR_RESEND_OTP = {
  en: "Resend OTP",
  sw: "Tuma tena OTP",
}

export const STR_MENU_HOME = {
  en: "Home",
  sw: "Nyumbani",
}

export const STR_MENU_DATING = {
  en: "Dating",
  sw: "Mahusiano",
}

export const STR_MENU_DOCTORS = {
  en: "Doctors",
  sw: "Madaktari",
}

export const STR_MENU_INBOX = {
  en: "Inbox",
  sw: "Ujumbe",
}

export const STR_MENU_ARTICLES = {
  en: "Articles",
  sw: "Makala",
}

export const STR_MENU_STORIES = {
  en: "Stories",
  sw: "Simulizi",
}

export const STR_MENU_UTILITIES = {
  en: "Utilities",
  sw: "Visaidizi",
}

export const STR_LATEST_ARTICLES = {
  en: "Latest Articles",
  sw: "Makala Mpya",
}

export const STR_MORE_ARTICLES = {
  en: "More Articles",
  sw: "Makala Zaidi",
}

export const STR_LATEST_STORIES = {
  en: "Latest Stories",
  sw: "Simulizi Mpya",
}

export const STR_MORE_STORIES = {
  en: "More Stories",
  sw: "Simulizi Zaidi",
}

export const STR_PERSONAL_SETTINGS = {
  en: "Personal Settings",
  sw: "Mipangilio Binafsi",
}

export const STR_UPDATE_USERNAME = {
  en: "Update username",
  sw: "Badili/Weka jina",
}

export const STR_CHANGE_PHONE_NUMBER = {
  en: "Change phone number",
  sw: "Badili namba ya simu",
}

export const STR_UPDATE_PERSONAL_INFO = {
  en: "Update personal information",
  sw: "Badili/Weka taarifa binafsi",
}

export const STR_PAYMENT_SETTINGS = {
  en: "Payments",
  sw: "Malipo",
}

export const STR_SUBSCRIPTION_FEE_PAYMENTS = {
  en: "My Subscription",
  sw: "Kifurushi cha matumizi",
}

export const STR_CONSULTATION_FEE_PAYMENTS = {
  en: "Doctor's consultation fee payment",
  sw: "Malipo ya kuwasiliana na daktari",
}

export const STR_MY_SUBSCRIPTION = {
  en: "My Subscription",
  sw: "Kifurushi cha matumizi",
}

export const STR_SUBSCRIPTION_HAS_EXPIRED = {
  en: "Subscription has expired",
  sw: "Kifurushi kimeisha",
}

export const STR_PURCHASE_SUBSCRIPTION_PACKAGE = {
  en: "Purchase a subscription package to continue using this app",
  sw: "Nunua kifurushi cha matumizi ili kuendelea kutumia App hii",
}

export const STR_SELECT = {
  en: "Select",
  sw: "Chagua",
}

export const STR_COMPLETE_PAYMENT = {
  en: "Complete Payment",
  sw: "Kamilisha Malipo",
}

export const STR_PAY_WITH_CARD = {
  en: "Pay with Credit Card",
  sw: "Lipia kwa Kadi",
}

export const STR_PAY_WITH_MOBILE = {
  en: "Pay with Mobile Network",
  sw: "Lipia kwa Mtandao wa Simu",
}

export const STR_SELECT_PAYMENT_METHOD = {
  en: "Select payment method",
  sw: "Chagua njia ya kufanya malipo",
}

export const STR_ENTER_PHONE_NUMBER_FOR_PAYMENT = {
  en: "Enter phone number you want to use for this payment. All Tanzanian networks are supported.",
  sw: "Ingiza namba ya simu utakayofanyia malipo. Tunapokea kutoka mitandao yote ya Tanzania",
}

export const STR_FINALIZE_PAYMENT_CARD = {
  en: "Please wait, you'll soon be redirected to complete the payment with your card.",
  sw: "Tafadhali subiri utaelekezwa kwenye kiungo cha kufanyia malipo kwa kadi yako",
}

export const STR_FINALIZE_PAYMENT_MOBILE = {
  en: "Push USSD was sent to your phone please check and finalize the payment",
  sw: "Tafadhali angalia simu yako ili kukamilisha malipo kwa USSD Push",
}

export const STR_BUY_NOW = {
  en: "Buy Now",
  sw: "Nunua Sasa",
}

export const STR_DAYS = {
  en: "Days",
  sw: "Siku",
}

export const STR_FROM = {
  en: "From",
  sw: "Kuanzia",
}

export const STR_TO = {
  en: "To",
  sw: "Hadi",
}

export const STR_PRICE = {
  en: "Price",
  sw: "Bei",
}

export const STR_PASSWORD = {
  en: "Password",
  sw: "Nenosiri",
}

export const STR_CONFIRM = {
  en: "Confirm",
  sw: "Hakiki",
}

export const STR_INVALID_PASSWORD = {
  en: "Invalid Password, password must have atleast 8 characters",
  sw: "Nenosiri angalau liwe na muunganiko wa namba na herufi angalau 8",
}

export const STR_INVALID_CONFIRM_PASSWORD = {
  en: "Please confirm your password correctly",
  sw: "Tafadhali hakiki nenosiri lako kwa usahihi",
}

export const STR_CHANGE_LANGUAGE = {
  en: "Tumia Swahili",
  sw: "Change to English",
}

export const STR_FORGOT_PASSWORD = {
  en: "Forgot Password",
  sw: "Nimesahau Nenosiri",
}

export const STR_CRM = {
  en: "CRM",
  sw: "CRM",
}

export const STR_ACCOUNTING = {
  en: "Accounting",
  sw: "Uhasibu",
}

export const STR_LEADS_DISCOVERY = {
  en: "Leads Discovery",
  sw: "Gundua Wateja Tarajiwa",
}

export const STR_MORE = {
  en: "More",
  sw: "Zaidi",
}

export const STR_REPORTS = {
  en: "Reports",
  sw: "Ripoti",
}

export const STR_DASHBOARD = {
  en: "Dashboard",
  sw: "Dashbodi",
}

export const STR_WATEJA = {
  en: "Customers",
  sw: "Wateja",
}

export const STR_TODOS = {
  en: "Todos",
  sw: "Majukumu",
}

export const STR_TITLE_REGISTER_BUSINESS = {
  en: "Register Business",
  sw: "Sajili Biashara",
}

export const STR_TITLE_RECORD_CUSTOMER = {
  en: "Record Customer",
  sw: "Rekodi mteja",
}


export const STR_NO_BUSINESS_WARNING = {
  en: "Your account is not linked to any business, please register a business or request your business manager to add you",
  sw: "Akaunti yako haijaunganishwa na biashara yoyote kwa sasa, tafadhali sajili biashara au omba mkurugenzi wako akuongeze kwenye biashara",
}

export const STR_BUSINESS_NAME = {
  en: "Business name",
  sw: "Jina la biashara",
}

export const STR_START_FREE_TRIAL = {
  en: "Start 7 Days Free Trial",
  sw: "Jaribu Bure Kwa Siku 7",
}

export const STR_INVALID_BUSINESS_NAME = {
  en: "Invalid business name",
  sw: "Jina la biashara sio sahihi",
}

export const STR_SUCCESS = {
  en: "Successful..",
  sw: "Imefanikiwa..",
}

export const STR_MY_CUSTOMERS = {
  en: "My Customers",
  sw: "Wateja Wangu",
}

export const STR_CLIENTS = {
  en: "Clients",
  sw: "Wateja kamili",
}

export const STR_LEADS = {
  en: "Leads",
  sw: "Wateja Tarajiwa",
}

export const STR_CLIENT = {
  en: "Client",
  sw: "Mteja kamili",
}

export const STR_LEAD = {
  en: "Lead",
  sw: "Mteja Tarajiwa",
}

export const STR_ADD_NEW = {
  en: "Add New",
  sw: "Ongeza Mpya",
}

export const STR_NAME = {
  en: "Name",
  sw: "Jina",
}

export const STR_CUSTOMER_TYPE = {
  en: "Customer Type",
  sw: "Aina ya mteja",
}

export const STR_CUSTOMER_LEVEL = {
  en: "Customer Level",
  sw: "Hatua ya mteja",
}

export const STR_PERSON = {
  en: "Person",
  sw: "Mtu binafsi",
}

export const STR_BUSINESS = {
  en: "Business",
  sw: "Biashara",
}

export const STR_COMPANY = {
  en: "Company",
  sw: "Kampuni",
}

export const STR_GOVERNMENT = {
  en: "Government",
  sw: "Serikali",
}

export const STR_LOCATION = {
  en: "Location",
  sw: "Anwani (Mahala)",
}

export const STR_EMAIL_ADDRESS = {
  en: "Email address",
  sw: "Anwani ya barua pepe",
}

export const STR_NOTES = {
  en: "Notes",
  sw: "Notes",
}

export const STR_SUBMIT = {
  en: "Submit",
  sw: "Wasilisha",
}

export const STR_INVALID_NAME = {
  en: "Invalid name",
  sw: "Jina sio sahihi",
}

export const STR_NO_RECORDS = {
  en: "No records were found",
  sw: "Hakuna rekodi kwa sasa",
}

export const STR_FILTER_BY_NAME_OR_PHONE = {
  en: "Filter by name or phone",
  sw: "Chambua kwa jina au namba ya simu",
}

export const STR_OTHER_COMM_CHANNELS = {
  en: "Other communication channels",
  sw: "Njia nyingine za mawasiliano",
}

export const STR_INCLUDE_COUNTRY_CODE_ON_PHONE = {
  en: "Include country code on the phone number",
  sw: "Weka country code kwenye namba hii",
}

export const STR_INCLUDE_LINK_TO_PROFILE = {
  en: "Include link to profile",
  sw: "Weka link ya kurasa",
}

export const STR_CALL = {
  en: "Call",
  sw: "Piga Simu",
}

export const STR_SEND_SMS = {
  en: "Send SMS",
  sw: "Tuma SMS",
}

export const STR_SEND_EMAIL = {
  en: "Send Email",
  sw: "Barua pepe",
}

export const STR_EDIT = {
  en: "Edit",
  sw: "Hariri",
}

export const STR_TURN_TO_CLIENT = {
  en: "Mark as client",
  sw: "Fanya mteja kamili",
}

export const STR_REPORT_FOLLOWUP = {
  en: "Report followup",
  sw: "Ripoti Ufuatiliaji",
}

export const STR_REPORT_SALES = {
  en: "Report Sales",
  sw: "Ripoti Mauzo",
}

export const STR_VIEW_MORE_INFO = {
  en: "View More Info",
  sw: "Ona Taarifa Zaidi",
}

export const STR_STEP = {
  en: "Step",
  sw: "Hatua",
}

export const STR_DESCRIPTION = {
  en: "Description",
  sw: "Maelezo zaidi",
}

export const STR_DATE = {
  en: "Date",
  sw: "Tarehe",
}

export const STR_WAS_ANY_PROMISE_MADE = {
  en: "Was there any promise that was made",
  sw: "Je kuna ahadi yoyote iliwekwa",
}

export const STR_NO = {
  en: "No",
  sw: "Hapana",
}

export const STR_YES = {
  en: "Yes",
  sw: "Ndio",
}

export const STR_PROMISE_DESCRIPTION = {
  en: "Promise's description",
  sw: "Maelezo kuhusu ahadi",
}

export const STR_PROMISE_DUE_DATE = {
  en: "Promise's due date",
  sw: "Tarehe ya kutimiza ahadi",
}

export const STR_INVALID_FOLLOWUP_STEP = {
  en: "Invalid followup step",
  sw: "Hatua ya ufuatiliaji sio sahihi",
}

export const STR_INVALID_DESCRIPTION = {
  en: "Invalid description",
  sw: "Maelezo sio sahihi",
}

export const STR_INVALID_PROMISE_DESCRIPTION = {
  en: "Invalid promise description",
  sw: "Maelezo ya ahadi sio sahihi",
}

export const STR_INVALID_DATE = {
  en: "Invalid date",
  sw: "Tarehe sio sahihi",
}

export const STR_INVALID_PROMISE_DUE_DATE = {
  en: "Invalid promise due date",
  sw: "Tarehe ya kutimiza ahadi sio sahihi",
}

export const STR_REPORT_VISIT = {
  en: "Report Visit",
  sw: "Ripoti Kutembelea",
}

export const STR_INVALID_TITLE = {
  en: "Invalid title",
  sw: "Maelezo sio sahihi",
}

export const STR_SALE_TYPE = {
  en: "Sale type",
  sw: "Aina ya mauzo",
}

export const STR_REAL_SALE = {
  en: "Main Sale",
  sw: "Mauzo Kamili",
}

export const STR_AMOUNT = {
  en: "Amount",
  sw: "Kiasi",
}

export const STR_TITLE = {
  en: "Title",
  sw: "Maelezo",
}

export const STR_UP_SALE = {
  en: "Up Sale",
  sw: "Mauzo ya ziada",
}

export const STR_INVALID_AMOUNT = {
  en: "Invalid amount",
  sw: "Kiasi sio sahihi",
}

export const STR_SALES = {
  en: "Sales",
  sw: "Mauzo",
}

export const STR_EXPENSES = {
  en: "Expenses",
  sw: "Matumizi",
}

export const STR_SALES_TODAY = {
  en: "Today's Sales",
  sw: "Mauzo ya leo",
}

export const STR_AVAILABLE_STOCK = {
  en: "Available Stock",
  sw: "Stock Iliopo",
}

export const STR_STOCKLIST = {
  en: "Stock List",
  sw: "Listi ya stock",
}

export const STR_FILTER_BY_NAME = {
  en: "Filter by name or title",
  sw: "Chambua kwa jina au maelezo",
}

export const STR_ADD_STOCK = {
  en: "Add to Stock",
  sw: "Ongeza Stock",
}

export const STR_REDUCE_STOCK = {
  en: "Reduce Stock",
  sw: "Punguza Stock",
}

export const STR_UNIT = {
  en: "Unit",
  sw: "Kipimo",
}

export const STR_INVALID_UNIT = {
  en: "Invalid unit",
  sw: "Kipimo sio sahihi",
}

export const STR_UNIT_PRICE = {
  en: "Unit Price",
  sw: "Bei ya moja",
}

export const STR_INVALID_UNIT_PRICE = {
  en: "Invalid unit price",
  sw: "Bei sio sahihi",
}

export const STR_AVAILABLE_QUANTITY = {
  en: "Available quantity",
  sw: "Kiasi kilichopo",
}

export const STR_OPERATION_COSTS_TODAY = {
  en: "Today's Operating Costs",
  sw: "Gharama za uendeshaji kwa leo",
}

export const STR_RECORD_OPERATION_COST = {
  en: "Record operation cost",
  sw: "Rekodi gharama za uendeshaji",
}

export const STR_WORKING_CAPITAL = {
  en: "Working Capital",
  sw: "Mtaji Unaozunguka",
}

export const STR_PURCHASES = {
  en: "Purchases",
  sw: "Manunuzi",
}

export const STR_PRODUCTION = {
  en: "Production",
  sw: "Uzalishaji",
}

export const STR_ACCOUNTS_RECEIVABLE = {
  en: "Accounts Receivable",
  sw: "Akaunti unazodai",
}

export const STR_ACCOUNTS_PAYABLE = {
  en: "Accounts Payable",
  sw: "Akaunti zinazodai",
}

export const STR_INVESTMENT = {
  en: "Investments",
  sw: "Uwekezaji",
}

export const STR_FIXED_ASSETS = {
  en: "Fixed Assets",
  sw: "Mali zisizohamishika",
}

export const STR_STOCK = {
  en: "Stock",
  sw: "Stock",
}

export const STR_OPERATION_COSTS = {
  en: "Operating Costs",
  sw: "Gharama za uendeshaji",
}

export const STR_MAIN_MENU = {
  en: "Main Menu",
  sw: "Menyu Kuu",
}

export const STR_RECORD_CAPITAL = {
  en: "Record Capital",
  sw: "Rekodi Mtaji",
}

export const STR_RECORD_PURCHASE = {
  en: "Record Purchases",
  sw: "Rekodi Manunuzi",
}

export const STR_RECORD_PRODUCTION = {
  en: "Record Production",
  sw: "Rekodi Uzalishaji",
}

export const STR_RECORD_INVESTMENT = {
  en: "Record Investment",
  sw: "Rekodi Uwekezaji Uliofanyika",
}

export const STR_SALES_ALL = {
  en: "All-time Sales",
  sw: "Mauzo ya muda wote",
}

export const STR_OPERATION_COSTS_ALL = {
  en: "All-time Operating Costs",
  sw: "Gharama za uendeshaji kwa muda wote",
}

export const STR_SALES_RECORDS = {
  en: "Sales records",
  sw: "Rekodi za mauzo",
}

export const STR_OPERATION_COSTS_RECORDS = {
  en: "Operating cost records",
  sw: "Rekodi za gharama za matumizi",
}

export const STR_CREATE_RECEIVABLE = {
  en: "Create Account Receivable",
  sw: "Tengeneza Akaunti ya Unaemdai",
}

export const STR_CREATE_PAYABLE = {
  en: "Create Account Payable",
  sw: "Tengeneza Akaunti ya Anaekudai",
}

export const STR_USERS = {
  en: "Users",
  sw: "Watumiaji",
}

export const STR_CREATE_GOAL = {
  en: "Create Goal",
  sw: "Panga Malengo",
}

export const STR_START_DATE = {
  en: "Start Date",
  sw: "Tarehe ya kuanza",
}

export const STR_INVALID_START_DATE = {
  en: "Invalid Start date",
  sw: "Tarehe ya kuanza sio sahihi",
}

export const STR_END_DATE = {
  en: "End Date",
  sw: "Tarehe ya kumaliza",
}

export const STR_INVALID_END_DATE = {
  en: "Invalid End Date",
  sw: "Tarehe ya kumaliza sio sahihi",
}

export const STR_TRACK_CUSTOMERS_FOLLOWUP = {
  en: "Track customers followup",
  sw: "Fuatilia ufuatiliaji wa wateja",
}

export const STR_TRACK_UNIQUE_CUSTOMERS_ENGAGED = {
  en: "Track unique customers engaged",
  sw: "Fuatilia wateja tofauti tofauti walioguswa",
}

export const STR_UNIQUE_CUSTOMERS_ENGAGED_TARGET = {
  en: "Unique customers engaged target",
  sw: "Lengo kwa(Idadi ya) wateja tofauti tofauti wa kuguswa/kufikiwa",
}

export const STR_TRACK_SALES = {
  en: "Track Sales",
  sw: "Fuatilia mauzo",
}

export const STR_SALES_TARGET = {
  en: "Sales Target",
  sw: "Lengo kwa mauzo",
}

export const STR_APPLY_BONUS_ON_SALES = {
  en: "Apply bonus on sales",
  sw: "Toa bonasi kwenye mauzo",
}

export const STR_APPLY_BONUS_ON_UNIQUE_CUSTOMERS_ENGAGED = {
  en: "Apply Bonus on Unique Customers Engaged",
  sw: "Toa bonasi kwa kugusa wateja tofauti tofauti",
}

export const STR_SALES_BONUS_AMOUNT = {
  en: "Sales Bonus",
  sw: "Bonasi ya Mauzo",
}

export const STR_UNIQUE_CUSTOMERS_BONUS_AMOUNT = {
  en: "Bonus for reaching Unique Customers Engaged Target",
  sw: "Bonasi kwa kufikia lengo la kugusa wateja tofauti tofauti",
}

export const STR_APPLY_COMMISSION_ON_SALES = {
  en: "Apply Commission on Sales",
  sw: "Toa kamisheni kwenye mauzo",
}

export const STR_COMMISSION_PERCENT = {
  en: "Commission (in percent)",
  sw: "Kamisheni (kwa asilimia)",
}

export const STR_CHANGE_PASSWORD = {
  en: "Change Password",
  sw: "Badili Nenosiri",
}

export const STR_REMOVE = {
  en: "Remove",
  sw: "Ondoa",
}

export const STR_CREATE_TODO = {
  en: "Create TODO",
  sw: "Ongeza Jukumu",
}

export const STR_SUBSCRIPTION_PACKAGE = {
  en: "Subscription Package",
  sw: "Kifurushi cha Matumizi",
}

export const STR_BONUS_EARNED = {
  en: "Bonus earned",
  sw: "Bonasi iliokusanywa",
}

export const STR_COMMISSION_EARDNED = {
  en: "Commission earned",
  sw: "Kamisheni Iliokusanywa",
}

export const STR_MY_SALES = {
  en: "My Sales",
  sw: "Mauzo Yangu",
}

export const STR_UNIQUE_CUSTOMERS_ENGAGED = {
  en: "Unique Customers Engaged",
  sw: "Wateja Tofauti tofauti walioguswa",
}

export const STR_VIEW_CURRENT_GOALS = {
  en: "View Current Goals and Targets",
  sw: "Ona Malengo ya sasa",
}

export const STR_TODAY_TODOS = {
  en: "Today's Todos",
  sw: "Majukumu ya leo",
}

export const STR_COMPLETED_TODOS = {
  en: "Completed Todos",
  sw: "Majukumu yaliyotimizwa",
}

export const STR_PENDING_TODOS = {
  en: "Pending Todos",
  sw: "Majukumu ambayo hayajatimizwa",
}

export const STR_VIEW = {
  en: "View",
  sw: "Fungua",
}

export const STR_TODAY = {
  en: "Today",
  sw: "Leo",
}

export const STR_THIS_WEEK = {
  en: "This Week",
  sw: "Wiki hii",
}

export const STR_THIS_MONTH = {
  en: "This Month",
  sw: "Mwezi huu",
}

export const STR_CUSTOMER = {
  en: "Customer",
  sw: "Mteja",
}

export const STR_TOTAL_AMOUNT = {
  en: "Total amount in TZS",
  sw: "Jumla kuu kwa TZS",
}

export const STR_PARTICULARS = {
  en: "Particulars",
  sw: "Bidhaa/Huduma",
}

export const STR_QUANTITY = {
  en: "Quantity",
  sw: "Kiasi",
}

export const STR_SUB_TOTAL = {
  en: "Sub Total",
  sw: "Jumla",
}

export const STR_NO_OPTIONS = {
  en: "No options found",
  sw: "Hakuna machaguo",
}

export const STR_AMOUNT_PAID = {
  en: "Amount Paid (Cash) in TZS",
  sw: "Kiasi kilicholipwa (Cash) kwa TZS",
}

export const STR_ENTRANCE_DATE = {
  en: "Entrance Date",
  sw: "Terehe ya kuingia",
}

export const STR_SOURCE = {
  en: "Source",
  sw: "Chanzo",
}

export const STR_REFERRER = {
  en: "Referrer",
  sw: "Mrufaa",
}

export const STR_INVALID_ENTRANCE_DATE = {
  en: "Invalid entrance date",
  sw: "Tarehe ya kuingia sio sahihi",
}

export const STR_CUSTOMER_WILL_BE_CLIENT = {
  en: "This customer wil be turned to client",
  sw: "Mteja huyu atakuwa mteja kamili",
}

export const STR_PROMISE = {
  en: "Promise",
  sw: "Ahadi",
}

export const STR_VISITS = {
  en: "Visits",
  sw: "Matembezi",
}

export const STR_MY_RECORDED_CUSTOMERS = {
  en: "My Recorded Customers",
  sw: "Wateja Ulioingiza",
}

export const STR_RECEIVABLE = {
  en: "Account Receivable",
  sw: "Akaunti Unayodai",
}

export const STR_PAYABLE = {
  en: "Account Payable",
  sw: "Akaunti Inayodai",
}

export const STR_BALANCE = {
  en: "Balance",
  sw: "Salio",
}


export const STR_INITIAL_AMOUNT = {
  en: "Initial Amount",
  sw: "Kiasi cha Mwanzo",
}

export const STR_RECORD_PAYMENTS = {
  en: "Record Payments",
  sw: "Rekodi Malipo",
}

export const STR_ASK_CONTINUE = {
  en: "Are you sure you want to continue?",
  sw: "Uko tayari kuendelea?",
}

export const STR_PAYMENTS = {
  en: "Payments",
  sw: "Malipo",
}

export const STR_BALANCE_SHEET = {
  en: "Balance Sheet",
  sw: "Balance Sheet",
}

export const STR_INCOME_STATEMENT = {
  en: "Income Statement",
  sw: "Income Statement",
}

export const STR_CASH_FLOW_STATEMENT = {
  en: "Cashflow Statement",
  sw: "Cashflow Statement",
}

export const STR_CAPITAL = {
  en: "Capital",
  sw: "Mtaji",
}

export const STR_RETAIL = {
  en: "Retail",
  sw: "Reja reja",
}

export const STR_WHOLESALE = {
  en: "Wholesale",
  sw: "Jumla",
}

export const STR_BEEN_CLIENT_DURATION = {
  en: "Days as a Client",
  sw: "Siku ambazo amekuwa mteja kamili",
}

export const STR_BEEN_LEAD_DURATION = {
  en: "Days as a Lead",
  sw: "Siku ambazo amekuwa mteja tarajiwa",
}

export const STR_LAST_PURCHASE_DATE = {
  en: "Last purchase date",
  sw: "Tarehe ya mwisho kununua",
}

export const STR_FIRST_PURCHASE_DATE = {
  en: "First purchase date",
  sw: "Tarehe ya kwanza kununua",
}

export const STR_CUSTOMER_STATUS_NEW = {
  en: "New Client",
  sw: "Mteja Mpya",
}

export const STR_CUSTOMER_STATUS_ACTIVE = {
  en: "Active Client",
  sw: "Mteja wa Kujirudia",
}

export const STR_CUSTOMER_STATUS_PASSIVE = {
  en: "Passive Client",
  sw: "Mteja Potevu",
}

export const STR_PRODUCT_CODE = {
  en: "Code",
  sw: "Code",
}

export const STR_UNIT_BUYING_PRICE = {
  en: "Unit Buying Price",
  sw: "Bei ya kununulia (kwa moja)",
}

export const STR_UNIT_RETAIL_SELLING_PRICE = {
  en: "Unit Retail Selling Price",
  sw: "Bei ya kuuzia (moja) kwa rejareja",
}

export const STR_UNIT_WHOLESALE_SELLING_PRICE = {
  en: "Unit Wholesale Selling Price",
  sw: "Bei ya kuuzia (moja) kwa jumla",
}

export const STR_INVALID_CODE = {
  en: "Invalid code",
  sw: "Code sio sahihi",
}

export const STR_INVALID_AMOUNT_PAID = {
  en: "Invalid amount paid(cash)",
  sw: "Kiasi kilicholipwa(cash) sio sahihi",
}

export const STR_OUT_OF_STOCK = {
  en: "Item is out of stock",
  sw: "Bidhaa zimekwisha",
}

export const STR_PROCESSED = {
  en: "Processed",
  sw: "Zilizokamilishwa",
}

export const STR_UNPROCESSED = {
  en: "Unprocessed",
  sw: "Zisizokamilishwa",
}

export const STR_ALL = {
  en: "All",
  sw: "Zote",
}

export const STR_DISCOUNT = {
  en: "Discount",
  sw: "Punguzo",
}

export const STR_RECEIVE_PAYMENT = {
  en: "Receive Payment",
  sw: "Pokea Malipo",
}

export const STR_DAYS_SINCE_LAST_PURCHASE = {
  en: "Days since last purchase",
  sw: "Siku tangu anunue mara ya mwisho",
}

export const STR_DAYS_SINCE_FIRST_PURCHASE = {
  en: "Days since first purchase",
  sw: "Siku tangu anunue mara ya kwanza",
}

export const STR_DATE_DUE = {
  en: "Date Due",
  sw: "tarehe ya kutimiza",
}

export const STR_MARK_AS_COMPLETED = {
  en: "Mark as Completed",
  sw: "Kamilisha",
}

export const STR_NO_EMAIL = {
  en: "Email was not found",
  sw: "Hakuna anwani ya barua pepe",
}

export const STR_VENDOR = {
  en: "Vendor",
  sw: "Mzabuni",
}

export const STR_VIEW_MORE = {
  en: "View more..",
  sw: "Ona zaidi...",
}

export const STR_NO_PERMISSION = {
  en: "No Permission to view this section, please contact your Manager if you think this is a mistake",
  sw: "Hauna ruhusa ya kutumia sehemu hii. Kama unadhani imetokea kimakosa tafadhali wasiliana na Meneja wako.",
}

export const STR_LEDGER = {
  en: "Ledger",
  sw: "Leja",
}

export const STR_ADD_USER = {
  en: "Add New User",
  sw: "Ongeza Mtumiaji",
}

export const STR_ROLE = {
  en: "Role",
  sw: "Jukumu",
}

export const STR_MANAGER = {
  en: "Manager",
  sw: "Meneja",
}

export const STR_SELLER = {
  en: "Seller",
  sw: "Muuzaji",
}

export const STR_MARKETER = {
  en: "Marketer",
  sw: "Msakaji",
}

export const STR_CASHIER = {
  en: "Cashier",
  sw: "Cashier",
}

export const STR_ACCOUNTANT = {
  en: "Accountant",
  sw: "Muhasibu",
}

export const STR_OWNER = {
  en: "Owner",
  sw: "Mmiliki",
}

export const STR_MANAGE_ACTIVE_GOAL = {
  en: "Manage Active Goal",
  sw: "Hariri Malengo ya Sasa",
}

export const STR_ALL_GOALS = {
  en: "All Goals",
  sw: "Malengo Yote",
}

export const STR_ACTIVE_GOAL = {
  en: "Active Goal",
  sw: "Lengo la sasa",
}

export const STR_TARGET = {
  en: "Target",
  sw: "Lengo",
}

export const STR_ACTUAL = {
  en: "Actual",
  sw: "Iliofanyika",
}

export const STR_TOTAL_SALES = {
  en: "Total sales made",
  sw: "Jumla ya mauzo yote",
}

export const STR_BONUS_MANAGEMENT = {
  en: "Bonus Management",
  sw: "Thibiti Bonasi",
}

export const STR_COMMISSION_MANAGEMENT = {
  en: "Commission Management",
  sw: "Thibiti Kamisheni",
}

export const STR_PENDING = {
  en: "Pending",
  sw: "Zinazosubiri uthibitisho",
}

export const STR_PAID = {
  en: "Paid",
  sw: "Zilizolipwa",
}

export const STR_UNPAID = {
  en: "Unpaid",
  sw: "Ambazo hazijalipwa",
}


export const STR_ACCEPT = {
  en: "Accept",
  sw: "Kubali",
}

export const STR_MARK_AS_PAID = {
  en: "Mark as Paid",
  sw: "Thibitisha kulipwa",
}

export const STR_VIEW_GOAL = {
  en: "View Goal",
  sw: "Ona Malengo",
}

export const STR_GOALS = {
  en: "Goals",
  sw: "Malengo",
}

export const STR_BILLS = {
  en: "Bills",
  sw: "Bili",
}

export const STR_CLEARED = {
  en: "Cleared",
  sw: "Zilizolipwa kikamilifu",
}

export const STR_UNCLEARED = {
  en: "Uncleared",
  sw: "Ambazo hazijalipwa kikamilifu",
}

export const STR_VENDORS = {
  en: "Vendors",
  sw: "Wazabuni",
}

export const STR_ADD_NEW_VENDOR = {
  en: "Add new vendor",
  sw: "Ongeza mzabuni",
}

export const STR_CREATE_BILL = {
  en: "Create new bill",
  sw: "Tengeneza bill",
}

export const STR_DATE_CREATED = {
  en: "Date Created",
  sw: "Tarehe ya kutengenezwa",
}

export const STR_CUSTOMER_DEPOSITS = {
  en: "Customer Deposits",
  sw: "Amana za Wateja",
}

export const STR_DEPOSITS = {
  en: "Deposits",
  sw: "Amana",
}

export const STR_RECORD_DEPOSIT = {
  en: "Record Deposit",
  sw: "Rekodi Amana",
}

export const STR_DEPOSIT_FUNDS = {
  en: "Deposit Funds",
  sw: "Weka Amana",
}

export const STR_WITHDRAW_FUNDS = {
  en: "Withdraw Funds",
  sw: "Toa Amana",
}

export const STR_ACCOUNTS_MANAGER = {
  en: "Accounts Manager",
  sw: "Kidhibiti Akaunti",
}

export const STR_SELECT_ACCOUNT = {
  en: "Select Account",
  sw: "Chagua Akaunti",
}

export const STR_TRANSACTIONS = {
  en: "Transactions",
  sw: "Miamala",
}

export const STR_TOTAL_DEBIT = {
  en: "Total Debit",
  sw: "Tota Debit",
}

export const STR_TOTAL_CREDIT = {
  en: "Total Credit",
  sw: "Tota Credit",
}

export const STR_CREDIT = {
  en: "Credit",
  sw: "Credit",
}

export const STR_DEBIT = {
  en: "Debit",
  sw: "Debit",
}

export const STR_PROCESS_ORDER = {
  en: "Process Order",
  sw: "Kamilisha Oda",
}

export const STR_SELECT_REPORT = {
  en: "Select Report",
  sw: "Chagua Ripoti",
}

export const STR_GET_REPORT = {
  en: "Get Report",
  sw: "Pata Ripoti",
}

export const STR_INVALID_REPORT_TYPE = {
  en: "Invalid report type",
  sw: "Aina ya report sio sahihi",
}

export const STR_LOGOUT = {
  en: "Log out",
  sw: "Ondoka kwenye akaunti",
}

export const STR_ACTIVE_BUSINESSES = {
  en: "Active Businesses",
  sw: "Biashara ulizomo",
}

export const STR_INVALID_ACCOUNT = {
  en: "INvalid Account",
  sw: "Akaunti sio sahihi",
}

export const STR_ACCOUNT_NAME = {
  en: "Account Name",
  sw: "Jina la Akaunti",
}

export const STR_CASH_EXPENSES = {
  en: "Cash Expenses",
  sw: "Matumizi ya Cash",
}

export const STR_CASH_INCOME = {
  en: "Cash Income",
  sw: "Mapato ya Cash",
}

export const STR_TOTAL = {
  en: "Total",
  sw: "Jumla",
}

export const STR_CREATE_CASH_EXPENSE = {
  en: "Create cash expense",
  sw: "Rekodi matumizi ya Cash",
}

export const STR_CREATE_CASH_INCOME = {
  en: "Create cash income",
  sw: "Rekodi mapato ya Cash",
}

export const STR_ACCOUNTING_JOURNAL = {
  en: "Accounting Journal",
  sw: "Accounting Journal",
}

export const STR_ENTER_JOURNAL_RECORD = {
  en: "Record Transaction",
  sw: "Rekodi Muamala",
}

export const STR_TRANSACTION_TYPE = {
  en: "Transaction Type",
  sw: "Aina ya muamala",
}

export const STR_CASHFLOW_SECTION = {
  en: "Cashflow Section",
  sw: "Cashflow Section",
}

export const STR_INVALID_SEARCH_FILTER = {
  en: "Invalid search term",
  sw: "Utafutaji sio sahihi",
}

export const STR_INVALID_TIN = {
  en: "Invalid TIN",
  sw: "TIN sio sahihi",
}

export const STR_TRADING_NAME = {
  en: "Trading Name",
  sw: "Jina la biashara",
}

export const STR_TAXPAYER_NAME = {
  en: "Tax Payer Name",
  sw: "Jina la Mlipa Kodi",
}

export const STR_BUSINESS_NATURE = {
  en: "Nature of the Business",
  sw: "Aina ya Biashara",
}

export const STR_BUSINESS_START_DATE = {
  en: "Business Start Date",
  sw: "Tarehe ya kuanza biashara",
}

export const STR_DISTRICT = {
  en: "District",
  sw: "Wilaya",
}

export const STR_REGION = {
  en: "Region",
  sw: "Mkoa",
}


export const STR_FIRSTNAME = {
  en: "First name",
  sw: "Jina la kwanza",
}

export const STR_MIDDLENAME = {
  en: "Middle name",
  sw: "Jina la kati",
}

export const STR_LASTNAME = {
  en: "Last name",
  sw: "Jina la mwisho",
}

export const STR_INCORPORATION_NUMBER = {
  en: "Brela Incorporation Number",
  sw: "Usajili wa Brela",
}

export const STR_PHONE_NUMBERS = {
  en: "Phone numbers",
  sw: "Namba za simu",
}

export const STR_EMAIL = {
  en: "Email Address",
  sw: "Anwani ya barua pepe",
}

export const STR_TIN = {
  en: "TIN",
  sw: "TIN",
}

export const STR_SEARCH_BY_TIN = {
  en: "Get TIN information",
  sw: "Pata Taarifa za TIN",
}

export const STR_SEARCH_BY_OTHER_DETAILS = {
  en: "Search by business name, nature, location, etc..",
  sw: "Tafuta kwa jina la biashara, aina, mahali, nk..",
}

export const STR_GET_DETAILS = {
  en: "Get Details",
  sw: "Pata Taarifa",
}

export const STR_SEARCH = {
  en: "Search",
  sw: "Tafuta",
}

export const STR_SHAREHOLDERS_INFO = {
  en: "Shareholders Details",
  sw: "Taarifa za wamiliki",
}

export const STR_GET_SHAREHOLDERS_INFO = {
  en: "Get Shareholders Info",
  sw: "Pata taarifa za wamiliki",
}

export const STR_INFO_NOT_AVAILABLE = {
  en: "Information not available",
  sw: "Taarifa hazipo kwa sasa",
}

export const STR_ITEM_TYPE = {
  en: "Item Type",
  sw: "Aina",
}

export const STR_COACH = {
  en: "Coach",
  sw: "Kocha",
}

export const STR_ALL_CUSTOMERS = {
  en: "All Customers",
  sw: "Wateja Wote",
}

export const STR_ASSIGN_PERSONEL = {
  en: "Assign Personnel",
  sw: "Pangilia wafanyakazi",
}

export const STR_INVALID_PERSONNEL = {
  en: "Invalid Personnel",
  sw: "Wafanyakazi sio sahihi",
}

export const STR_EDIT_STOCK = {
  en: "Edit Details",
  sw: "Hariri Taarifa",
}

export const STR_VIEW_ACTIVITIES = {
  en: "View User Activities",
  sw: "Ona Ufanyajikazi",
}

export const STR_CUSTOMERS = {
  en: "Customers",
  sw: "Wateja",
}

export const STR_SALES_STATEMENT = {
  en: "Sales Statement",
  sw: "Ripoti ya Manunuzi",
}

export const STR_TOTAL_PROCESSED_SALES_IN_TZS = {
  en: "Total Processed Sales in TZS",
  sw: "Jumla ya Mauzo yaliokamilishwa (TZS)",
}

export const STR_TOTAL_PAID_AMOUNT = {
  en: "Total Paid Amount (TZS)",
  sw: "Jumla ya Malipo Yaliofanyika (TZS)",
}

export const STR_TOTAL_DEBT = {
  en: "Total Outstanding Debt (TZS)",
  sw: "Jumla ya Deni lililobaki (TZS)",
}

export const STR_TOTAL_DISCOUNT_OFFERED = {
  en: "Total Discount Offered (TZS)",
  sw: "Jumla ya punguzo lililotolewa (TZS)",
}

export const STR_PROCESSED_SALES = {
  en: "Processed Sales",
  sw: "Mauzo Yaliokamilishwa",
}

export const STR_UNPROCESSED_SALES = {
  en: "Unprocessed Sales",
  sw: "Mauzo ambayo hayajakamilishwa",
}

export const STR_ = {
  en: "",
  sw: "",
}