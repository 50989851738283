import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { AiOutlineUser } from 'react-icons/ai';
import { getInlineLoader, setCookie } from "../Helpers";
import {
  STR_ACTIVE_BUSINESSES,
  STR_LOGOUT,
  STR_PERSONAL_SETTINGS,
  STR_SELECT,
  STR_UPDATE_PERSONAL_INFO,
} from "../Strings";
import { MdInfoOutline, MdLogout, MdTranslate } from 'react-icons/md';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getCurrentUserData();
    setReady(true);
  }

  function logOut() {
    appContext.setShowOverlayLoader(true);
    setCookie('mauzocrm-userId', "", -10000000);
    setCookie('mauzocrm-userToken', "", -10000000);
    appContext.navTo({
      item: '',
    })
    appContext.refresh();
    appContext.setShowOverlayLoader(false);
  }


  useEffect(() => {
    init();
  }, [])



  if (ready) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "scroll",
        }}
        className="mNoScrollBar"
      >
        <div className="container">

          <div className="row">

            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h1
                  >
                    <AiOutlineUser className="text-primary" size={100} />

                    <span
                      style={{
                        display: "block",
                        fontSize: "18px"
                      }}
                      className="font-semi-bold text-primary"
                    >
                      {(appContext.currentUserData.phone) ? <>+{appContext.currentUserData.phone}</> : ""}
                      {(appContext.currentUserData.email) ? <span style={{ fontSize: "14px" }}>{appContext.currentUserData.email}</span> : ""}
                    </span>
                    <span
                      style={{
                        display: "block",
                        fontSize: "28px"
                      }}
                      className="text-primary"
                    >
                      {appContext.currentUserData.fullname}
                    </span>
                  </h1>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h6
                    className="card-title"
                  >
                    {STR_PERSONAL_SETTINGS[appContext.language]}
                  </h6>

                  {/** 
                  <div
                    className="d-flex mb-4 mt-4"
                    style={{
                      padding: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: 'account',
                        subItem: 'update-personal-info',
                      })
                    }}
                  >
                    <MdInfoOutline className="align-self-center mNoMargin" size={24} />
                    <h6
                      className="mNoMargin align-self-center font-semi-bold"
                      style={{
                        fontSize: "14px",
                        paddingLeft: "20px",
                      }}
                    >
                      {STR_UPDATE_PERSONAL_INFO[appContext.language]}
                    </h6>
                  </div>
                  */}

                  <div
                    className="d-flex mb-4 mt-4"
                    style={{
                      padding: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      appContext.activateDialog({
                        message: STR_LOGOUT[appContext.language] + "?",
                        onConfirm: logOut,
                      })
                    }}
                  >
                    <MdLogout className="align-self-center mNoMargin" size={24} />
                    <h6
                      className="mNoMargin align-self-center font-semi-bold"
                      style={{
                        fontSize: "14px",
                        paddingLeft: "20px",
                      }}
                    >
                      {STR_LOGOUT[appContext.language]}
                    </h6>
                  </div>

                  <div
                    className="d-flex mb-4 mt-4"
                    style={{
                      padding: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (appContext.language === 'en') {
                        appContext.setLanguage("sw");
                      } else {
                        appContext.setLanguage("en");
                      }
                    }}
                  >
                    <MdTranslate className="align-self-center mNoMargin" size={24} />
                    <h6
                      className="mNoMargin align-self-center font-semi-bold"
                      style={{
                        fontSize: "14px",
                        paddingLeft: "20px",
                      }}
                    >
                      {(appContext.language === 'en') ? "Tumia Kiswahili" : "Use English"}
                    </h6>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h6
                    className="card-title"
                  >
                    {STR_ACTIVE_BUSINESSES[appContext.language]}
                  </h6>
                  <hr />
                  {
                    JSON.parse(appContext.currentUserData.businesses).map((item, i) => {
                      return (
                        <div
                          style={{
                            width: "100%",
                            padding: "10px",
                          }}
                          key={i}
                        >
                          <h6
                            style={{
                              fontSize: "18px",
                              textTransform: "capitalize",
                            }}
                            className="font-semi-bold"
                          >
                            {item.name}
                          </h6>
                          <h6
                            style={{
                              fontSize: "14px",
                              textTransform: "uppercase",
                            }}
                            className="font-bold text-muted"
                          >
                            {item.role}
                          </h6>

                          {
                            (Number(appContext.currentBusinessId) !== Number(item.id)) ?
                              <div className="text-end">
                                <button
                                  className="btn btn-dark btn-sm btn-rounded"
                                  onClick={() => {
                                    appContext.changeCurrentBusinessId(item.id);
                                  }}
                                >
                                  {STR_SELECT[appContext.language]}
                                </button>
                              </div>
                              : ""
                          }
                          <hr />
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>

          </div>

        </div>


      </div>
    )
  } else {
    return (
      <div
        className="mSupportLoading"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {getInlineLoader()}
      </div>
    );
  }


}