import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { CDN_URL, formatDate, formatMoney } from "../Helpers";
import { MdCall } from "react-icons/md";
import { STR_BALANCE } from "../Strings";

export default function View({ data, type }) {
  const appContext = useContext(AppContext);

  /* Place states here */
  const [userInfo, setUserInfo] = useState(null);

  function view() {
    if (type === 'user') {
      appContext.navTo({
        item: "bottom-view",
        subItem: `user-options`,
        extraItem: data.uid,
      });
    } else if (type === 'receivable') {
      appContext.navTo({
        item: "view",
        subItem: `receivable`,
        extraItem: data.id,
      });
    } else if (type === 'payable') {
      appContext.navTo({
        item: "view",
        subItem: `payable`,
        extraItem: data.id,
      });
    } else if (type === 'sale') {
      appContext.navTo({
        item: "view",
        subItem: `sale`,
        extraItem: data.id,
      });
    } else if (type === 'todo') {
      appContext.navTo({
        item: "bottom-view",
        subItem: `todo`,
        extraItem: data.id,
      });
    } else if (type === 'bonus') {
      appContext.navTo({
        item: "bottom-view",
        subItem: `bonus-options`,
        extraItem: data.id,
      });
    } else if (type === 'commission') {
      appContext.navTo({
        item: "bottom-view",
        subItem: `commission-options`,
        extraItem: data.id,
      });
    } else if (type === 'bill') {
      appContext.navTo({
        item: "view",
        subItem: `bill`,
        extraItem: data.id,
      });
    } else if (type === 'vendor') {
      appContext.navTo({
        item: "view",
        subItem: `vendor`,
        extraItem: data.id,
      });
    } else if (type === 'customer-deposit') {
      appContext.navTo({
        item: "view",
        subItem: `manage-customer-deposits`,
        extraItem: data.uid,
      });
    } else if (type === 'cash_expense') {
      appContext.navTo({
        item: "view",
        subItem: `cash_expense`,
        extraItem: data.id,
      });
    } else if (type === 'cash_income') {
      appContext.navTo({
        item: "view",
        subItem: `cash_income`,
        extraItem: data.id,
      });
    } else if (type === 'lead') {
      appContext.navTo({
        item: "view",
        subItem: `lead`,
        extraItem: data.tin,
      });
    } else if (type === 'goal') {
      appContext.navTo({
        item: "view",
        subItem: `goal`,
        extraItem: data.id,
      });
    }
  }

  if (type === 'operation_cost') {
    return (
      <div
        className="One"
        onClick={view}
      >
        <h6
          className="font-semi-bold"
          style={{
            fontSize: "18px",
            textTransform: "capitalize",
          }}
        >
          {data.title}
        </h6>


        <h6
          className='badge bg-danger'
          style={{
            fontSize: "18px",
          }}
        >
          {formatMoney(data.totalAmount)} TZS
        </h6>

        <h6
          className='text-end'
          style={{
            fontSize: "12px",
          }}
        >
          {formatDate(data.dateCreated).substring(0, 10)}
        </h6>
      </div>
    );
  } else if (type === 'user') {
    return (
      <div
        className="One"
        onClick={view}
      >
        <h6
          className="font-regular"
          style={{
            fontSize: "20px",
            textTransform: "capitalize",
          }}
        >
          {appContext.getBusinessUserInfo(data).username}
        </h6>

        <h6
          className='font-semi-bold'
          style={{
            fontSize: "18px",
          }}
        >
          {data.phone}
        </h6>

        <span
          className="badge bg-secondary"
          style={{
            fontSize: "12px",
            textTransform: "capitalize",
          }}
        >
          {appContext.getBusinessUserInfo(data).role}
        </span>
      </div>
    );
  } else if (type === 'sale') {
    return (
      <div
        className="One"
        onClick={view}
      >



        <h6
          className='font-semi-bold'
          style={{
            fontSize: "24px",
          }}
        >
          {formatMoney(data.totalAmount)} TZS
        </h6>
        <h6
          className='font-bold text-muted'
          style={{
            fontSize: "14px",
          }}
        >
          {data.title}
        </h6>


        <h6
          className='text-end'
          style={{
            fontSize: "12px",
          }}
        >
          {formatDate(data.dateCreated).substring(0, 10)}
        </h6>
      </div>
    );
  } else if (type === 'bonus') {
    return (
      <div
        className="One"
        onClick={view}
      >



        <h6
          className='font-semi-bold'
          style={{
            fontSize: "24px",
          }}
        >
          {formatMoney(data.amount)} TZS
        </h6>
        <h6
          className='font-bold text-muted'
          style={{
            fontSize: "14px",
          }}
        >
          {data.name || data.email || data.phone}<br />
          {data.name}

        </h6>

        <h6
          className='text-start font-bold'
          style={{
            fontSize: "12px",
            borderLeft: "6px solid var(--primaryColor)",
            paddingLeft: "5px"
          }}
        >
          {data.status}
        </h6>

        <h6
          className='text-end'
          style={{
            fontSize: "12px",
          }}
        >
          {formatDate(data.dateRequested).substring(0, 10)}
        </h6>
      </div>
    );
  } else if (type === 'commission') {
    return (
      <div
        className="One"
        onClick={view}
      >



        <h6
          className='font-semi-bold'
          style={{
            fontSize: "24px",
          }}
        >
          {formatMoney(data.amount)} TZS
        </h6>
        <h6
          className='font-bold text-muted'
          style={{
            fontSize: "14px",
          }}
        >
          {data.name || data.email || data.phone}<br />
          {data.name}

        </h6>

        <h6
          className='text-start font-bold'
          style={{
            fontSize: "12px",
            borderLeft: "6px solid var(--primaryColor)",
            paddingLeft: "5px"
          }}
        >
          {data.status}
        </h6>

        <h6
          className='text-end'
          style={{
            fontSize: "12px",
          }}
        >
          {formatDate(data.dateRequested).substring(0, 10)}
        </h6>
      </div>
    );
  } else if (type === 'todo') {
    return (
      <div
        className="card"
        onClick={view}
      >

        <div className="card-body">

          <div className="text-end">
            <span className="badge bg-dark" style={{ fontSize: "16px" }}>{formatDate(data.dateDue).substring(0, 10)}</span>
          </div>

          <h6
            className='font-regular'
            style={{
              fontSize: "18px",
            }}
          >
            {data.title}
          </h6>


          <h6
            className='text-start font-bold'
            style={{
              fontSize: "12px",
              borderLeft: "6px solid var(--primaryColor)",
              paddingLeft: "5px"
            }}
          >
            {data.status}
          </h6>
        </div>
      </div>
    );
  } else if (type === 'vendor') {
    return (
      <div
        className="One"
        onClick={view}
      >

        <h6
          className='font-semi-bold'
          style={{
            fontSize: "16px",
          }}
        >
          <span style={{ textTransform: "uppercase", fontSize: "20px" }}>{data.name}</span>
          <br />
          <span className="text-muted">{data.phone}</span>
        </h6>

      </div>
    );
  } else if (type === 'bill') {
    return (
      <div
        className="One"
        onClick={view}
      >
        <h6
          className="font-semi-bold"
          style={{
            fontSize: "18px",
            textTransform: "capitalize",
          }}
        >
          {data.title}
        </h6>


        <h6
          className=''
          style={{
            fontSize: "18px",
          }}
        >
          {formatMoney(data.totalAmount)} TZS
        </h6>

        <h6
          className={(data.balance > 0) ? "font-semi-bold text-danger" : "font-semi-bold text-success"}
          style={{
            fontSize: "22px",
            marginTop: "20px",
          }}
        >
          {formatMoney(data.balance)}
          <span style={{ display: "block", fontSize: "14px" }} className="text-muted">{STR_BALANCE[appContext.language]} (TZS)</span>
        </h6>


        <h6
          className='text-end'
          style={{
            fontSize: "12px",
          }}
        >
          {formatDate(data.dateCreated).substring(0, 10)}
        </h6>
      </div>
    );
  } else if (type === 'customer-deposit') {
    return (
      <div
        className="One"
        onClick={view}
      >
        <h6
          className="font-semi-bold"
          style={{
            fontSize: "18px",
            textTransform: "capitalize",
          }}
        >
          {data.name}<br />
          {data.phone}
        </h6>



        <h6
          className="font-semi-bold text-secondary mt-4 text-end"
          style={{
            fontSize: "22px",
            marginTop: "20px",
          }}
        >
          {formatMoney(data.depositBalance)}
          <span style={{ display: "block", fontSize: "14px" }} className="text-muted">{STR_BALANCE[appContext.language]} (TZS)</span>
        </h6>


      </div>
    );
  } else if (type === 'cash_expense') {
    return (
      <div
        className="One"
        onClick={view}
      >
        <h6
          className="font-semi-bold"
          style={{
            fontSize: "18px",
            textTransform: "capitalize",
          }}
        >
          {data.title}
        </h6>


        <h6
          className=''
          style={{
            fontSize: "18px",
          }}
        >
          {formatMoney(data.totalAmount)} TZS
        </h6>


        <h6
          className='text-end'
          style={{
            fontSize: "12px",
          }}
        >
          {formatDate(data.dateCreated).substring(0, 10)}
        </h6>
      </div>
    );
  } else if (type === 'cash_income') {
    return (
      <div
        className="One"
        onClick={view}
      >
        <h6
          className="font-semi-bold"
          style={{
            fontSize: "18px",
            textTransform: "capitalize",
          }}
        >
          {data.title}
        </h6>


        <h6
          className=''
          style={{
            fontSize: "18px",
          }}
        >
          {formatMoney(data.totalAmount)} TZS
        </h6>


        <h6
          className='text-end'
          style={{
            fontSize: "12px",
          }}
        >
          {formatDate(data.dateCreated).substring(0, 10)}
        </h6>
      </div>
    );
  } else if (type === 'lead') {
    return (
      <div
        className="One"
        onClick={view}
      >
        <h6
          className="font-semi-bold text-primary"
          style={{
            fontSize: "16px",
            textTransform: "capitalize",
          }}
        >
          {data.tpName || data.tradingName || (data.firstName + " " + data.middleName + " " + data.lastName)}
        </h6>


        <h6
          className=''
          style={{
            fontSize: "14px",
          }}
        >
          {data.businessNature}
        </h6>


        <h6
          className='text-end mt-4'
          style={{
            fontSize: "12px",
          }}
        >
          {data.region}, {data.district}, {data.location}
        </h6>
      </div>
    );
  } else if (type === 'goal') {
    return (
      <div
        className="One"
        onClick={view}
      >
        <h6
          className="font-semi-bold"
          style={{
            fontSize: "18px",
          }}
        >
          {formatDate(data.startDate).substring(0, 10)} to {formatDate(data.endDate).substring(0, 10)} 
        </h6>


      </div>
    );
  } else {
    return (
      <div
        className="One"
        onClick={view}
      >
        <h6
          className="font-semi-bold"
          style={{
            fontSize: "18px",
            textTransform: "capitalize",
          }}
        >
          {data.title}
        </h6>


        <h6
          className=''
          style={{
            fontSize: "18px",
          }}
        >
          {formatMoney(data.totalAmount)} TZS
        </h6>

        <h6
          className='text-end'
          style={{
            fontSize: "12px",
          }}
        >
          {formatDate(data.dateCreated).substring(0, 10)}
        </h6>
      </div>
    );
  }
}
