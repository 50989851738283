import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatDateForInput, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_AMOUNT, STR_AMOUNT_PAID, STR_CALL, STR_CUSTOMER, STR_DASHBOARD, STR_DATE, STR_DESCRIPTION, STR_DISCOUNT, STR_EDIT, STR_INVALID_AMOUNT, STR_INVALID_DATE, STR_INVALID_DESCRIPTION, STR_INVALID_FOLLOWUP_STEP, STR_INVALID_PROMISE_DESCRIPTION, STR_INVALID_PROMISE_DUE_DATE, STR_INVALID_TITLE, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NAME, STR_NO, STR_NO_OPTIONS, STR_OUT_OF_STOCK, STR_PARTICULARS, STR_PLEASE_WAIT, STR_PRODUCT_CODE, STR_PROMISE_DESCRIPTION, STR_PROMISE_DUE_DATE, STR_QUANTITY, STR_REAL_SALE, STR_REPORT_FOLLOWUP, STR_REPORT_SALES, STR_REPORT_VISIT, STR_SALE_TYPE, STR_SEND_EMAIL, STR_SEND_SMS, STR_STEP, STR_SUB_TOTAL, STR_SUBMIT, STR_SUCCESS, STR_TITLE, STR_TODOS, STR_TOTAL_AMOUNT, STR_TURN_TO_CLIENT, STR_UNIT, STR_UNIT_PRICE, STR_UP_SALE, STR_VIEW_MORE_INFO, STR_WAS_ANY_PROMISE_MADE, STR_WATEJA, STR_YES } from "../Strings";
import CustomersHome from './CustomersHome';
import { MdAdd, MdCall, MdCheckCircle, MdDelete, MdEdit, MdEmail, MdFacebook, MdMessage, MdOutlineFacebook, MdOutlineWhatsapp, MdWhatsapp } from "react-icons/md";
import { BiInfoCircle, BiLogoLinkedin, BiLogoWhatsapp, BiPurchaseTag } from "react-icons/bi";
import { SlUserFollowing } from "react-icons/sl";
import { BsInstagram, BsTelegram } from "react-icons/bs";
import CustomerWidget from "../ones/CustomerWidget";
import Select from "react-select";


export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(null);
  const [dateCreated, setDateCreated] = useState(formatDateForInput(Date.now() / 1000));
  const [totalAmount, setTotalAmount] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [saleType, setSaleType] = useState("sale");
  const [id, setId] = useState(props.id);
  const [particulars, setParticulars] = useState([getParticularsTemp()]);
  const [stockListOptions, setStockListOptions] = useState([]);
  const [myCustomersOptions, setMyCustomersOptions] = useState([]);

  async function init() {
    //run initializations here
    setReady(false);

    await Promise.all([
      appContext.getStockList(),
      appContext.getMyCustomers(),
    ])
    if (id) {
      await getData()
    }

    setReady(true);
  }

  function handleParticularsFieldChange(_index, _field, _value) {

    setParticulars(_prev => {
      const _new = [..._prev];

      if (_field === 'item') {
        try {
          const stockItem = JSON.parse(_value.value);

          if (stockItem.itemType === 'stock') {
            if (Number(stockItem.quantity) > 0) {
              _new[_index].item = { ...stockItem };
              _new[_index].name = stockItem.name;
              _new[_index].code = stockItem.code;
              _new[_index].unit = stockItem.unit;
              _new[_index].unitPrice = (data.customerType === 'wholesale') ? stockItem.unitWholesaleSellingPrice : stockItem.unitRetailSellingPrice;
              _new[_index].quantity = 1;
              _new[_index].subTotal = 1 * Number(_new[_index].unitPrice);
            } else {
              appContext.tellError(STR_OUT_OF_STOCK[appContext.language]);
            }
          } else {
            _new[_index].item = { ...stockItem };
            _new[_index].name = stockItem.name;
            _new[_index].code = stockItem.code;
            _new[_index].unit = stockItem.unit;
            _new[_index].unitPrice = (data.customerType === 'wholesale') ? stockItem.unitWholesaleSellingPrice : stockItem.unitRetailSellingPrice;
            _new[_index].quantity = 1;
            _new[_index].subTotal = 1 * Number(_new[_index].unitPrice);
          }

        } catch (error) {
          _new[_index].item = null;
        }

      } else {


        if (_field === 'quantity') {
          if (_new[_index].item.itemType === 'stock') {
            if (Number(_new[_index].item.quantity) >= Number(_value)) {
              _new[_index][_field] = _value;
              _new[_index].subTotal = _value * Number(_new[_index].unitPrice);
            } else {
              appContext.tellError("Only " + _new[_index].item.quantity + " left");
            }
          } else {
            _new[_index][_field] = _value;
            _new[_index].subTotal = _value * Number(_new[_index].unitPrice);
          }


        } else if (_field === 'unitPrice') {
          _new[_index][_field] = _value;
          _new[_index].subTotal = _value * Number(_new[_index].quantity);
        } else {
          _new[_index][_field] = _value;
        }



      }

      return _new;
    })
  }

  function addParticularsRow() {
    setParticulars(_prev => {
      const _new = [..._prev, getParticularsTemp()];

      return _new;
    })
  }

  function removeParticularsRow(_index) {
    setParticulars(_prev => {
      const _new = [..._prev];

      _new.splice(_index, 1);

      return _new;
    })
  }

  function getParticularsTemp() {
    return ({
      item: null,
      code: "",
      name: "",
      unit: "",
      quantity: 0,
      unitPrice: 0,
      subTotal: 0,
    })
  }

  async function getData() {
    await callApi("get-customer-data", { uid: id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }

  async function reportSale() {
    const _runner = async () => {
      setLoading(true);
      await callApi(
        "report-sale",
        {
          totalAmount,
          dateCreated,
          discount,
          saleType,
          items: JSON.stringify(particulars),
          businessId: appContext.currentBusinessId,
          customerId: (data) ? data.id : '',
        }
      ).then(response => {
        if (response.status === 1) {
          appContext.tellMessage(STR_SUCCESS[appContext.language]);
          appContext.getMyTotalSalesThisMonth();
          appContext.getMyTotalSalesThisWeek();
          appContext.getMyTotalSalesToday();
          appContext.getSalesRecords();
          appContext.navBack();
        } else {
          appContext.tellError(response.msg);
        }
      })
      setLoading(false);
    }

    if (!loading) {
      if (dateCreated.trim().length > 0) {
        if (!isNaN(totalAmount) && Number(totalAmount) > 0) {
          await _runner();
        } else {
          appContext.tellError(STR_INVALID_AMOUNT[appContext.language]);
        }
      } else {
        appContext.tellError(STR_INVALID_DATE[appContext.language]);
      }

    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language])
    }
  }

  function getStockListOptions() {
    if (appContext.stockList && appContext.stockList.length > 0) {
      const options = appContext.stockList.map((item, i) => {
        return (
          {
            value: JSON.stringify(item),
            label: (item.itemType === 'stock') ? item.code + ' - ' + item.name : item.name,
          }
        )
      })

      setStockListOptions(options);
    }
  }

  function getMyCustomersOptions() {
    if (appContext.myCustomers && appContext.myCustomers.length > 0) {
      const options = appContext.myCustomers.map((item, i) => {
        return (
          {
            value: JSON.stringify(item),
            label: item.name
          }
        )
      })

      setMyCustomersOptions(options);
    }
  }

  function getTotalAmount() {
    let _total = 0;
    if (particulars && particulars.length > 0) {
      for (const _item of particulars) {
        _total += Number(_item.subTotal);
      }

    }
    setTotalAmount(_total);
  }

  useEffect(() => {
    init();
  }, [id])

  useEffect(() => {
    setId(props.id)
  }, [props.id])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  useEffect(() => {
    if (appContext.stockList) {
      getStockListOptions()
    }
  }, [appContext.stockList])

  useEffect(() => {
    if (appContext.myCustomers) {
      getMyCustomersOptions()
    }
  }, [appContext.myCustomers])

  useEffect(() => {
    getTotalAmount();
  }, [particulars])

  useEffect(() => {
    setParticulars([getParticularsTemp()]);
  }, [data])

  if (ready) {
    return (
      <div
        className="container"
      >
        <h6 className="mSectionTitle">{STR_REPORT_SALES[appContext.language]}</h6>

        {
          (data) ?
            <CustomerWidget data={data} />
            : ""
        }

        <div className="row">
          {
            (!props.id) ?
              <div className="col-md-12 mb-2 mt-2">
                <label className="form-label">{STR_CUSTOMER[appContext.language]}</label>

                <Select
                  className="form-control"
                  value={data ? { value: JSON.stringify(data), label: data.name } : null}
                  onChange={(_value) => {
                    if (_value) {
                      try {
                        setData(JSON.parse(_value.value));
                      } catch (error) {
                        setData(null);
                      }
                    } else {
                      setData(null);
                    }
                  }}
                  options={myCustomersOptions}
                  isClearable={true}

                  noOptionsMessage={() => STR_NO_OPTIONS[appContext.language]}
                />

              </div>
              : ""
          }


          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_DATE[appContext.language]}</label>
            <input
              className="form-control"
              value={dateCreated}
              onChange={(e) => setDateCreated(e.target.value)}
              type="date"
            />
          </div>

          {
            (data) ?
              <div className="col-md-12 mb-2 mt-2">
                <label className="form-label">{STR_PARTICULARS[appContext.language]}</label>

                <div className="mTableHolder">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>
                          {STR_PRODUCT_CODE[appContext.language]}
                        </th>
                        <th>
                          {STR_NAME[appContext.language]}
                        </th>
                        <th>
                          {STR_UNIT[appContext.language]}
                        </th>
                        <th>
                          {STR_QUANTITY[appContext.language]}
                        </th>
                        <th>
                          {STR_UNIT_PRICE[appContext.language]}
                        </th>
                        <th>
                          {STR_SUB_TOTAL[appContext.language]}
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    {
                      (particulars && particulars.length > 0) ?
                        <tbody>
                          {
                            particulars.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    style={{
                                      width: "200px"
                                    }}
                                  >
                                    <Select
                                      className="form-control"
                                      value={item.item ? { value: JSON.stringify(item.item), label: item.item.code } : null}
                                      onChange={(_value) => {
                                        if (_value) {
                                          handleParticularsFieldChange(i, 'item', _value);
                                        } else {
                                          removeParticularsRow(i)
                                        }
                                      }}
                                      options={stockListOptions}
                                      isClearable={true}

                                      noOptionsMessage={() => STR_NO_OPTIONS[appContext.language]}
                                      //..
                                      menuPortalTarget={document.body}
                                      menuPosition="absolute"
                                      styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                        container: base => ({ ...base, minWidth: '200px' }),  // Add minWidth here
                                      }}
                                    //..
                                    />


                                  </td>

                                  <td>
                                    <input
                                      className="form-control"
                                      value={item.name || ''}
                                      type="text"
                                      readOnly
                                      style={{
                                        minWidth: "150px"
                                      }}
                                    />
                                  </td>

                                  <td>
                                    <input
                                      className="form-control"
                                      onChange={(e) => {
                                        handleParticularsFieldChange(i, 'unit', e.target.value)
                                      }}
                                      value={item.unit || ''}
                                      type="text"
                                      style={{
                                        minWidth: "150px"
                                      }}
                                      readOnly
                                    />
                                  </td>

                                  <td>
                                    <input
                                      className="form-control"
                                      onChange={(e) => {
                                        handleParticularsFieldChange(i, 'quantity', e.target.value)
                                      }}
                                      value={item.quantity || 0}

                                      style={{
                                        minWidth: "80px"
                                      }}
                                    />
                                  </td>

                                  <td>
                                    <input
                                      className="form-control"
                                      value={item.unitPrice || 0}

                                      readOnly
                                      style={{
                                        minWidth: "150px"
                                      }}
                                    />
                                  </td>

                                  <td>
                                    <input
                                      className="form-control"
                                      onChange={(e) => {
                                        handleParticularsFieldChange(i, 'subTotal', e.target.value)
                                      }}
                                      value={item.subTotal || 0}

                                      readOnly={true}
                                      style={{
                                        minWidth: "150px"
                                      }}
                                    />
                                  </td>

                                  <td>
                                    <MdDelete
                                      size={24}
                                      className="text-danger"
                                      onClick={() => removeParticularsRow(i)}
                                      style={{
                                        cursor: "pointer"
                                      }}
                                    />
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                        : ""
                    }
                  </table>
                </div>

                <div className="text-end">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={addParticularsRow}
                  >
                    <MdAdd size={24} />
                  </button>
                </div>
              </div>
              : ""
          }

          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_TOTAL_AMOUNT[appContext.language]}</label>
            <input
              className="form-control"
              value={totalAmount}
              onChange={(e) => setTotalAmount(e.target.value)}
              readOnly={true}
            />
          </div>

          <div className="mb-2 mt-4">
            <label>{STR_DISCOUNT[appContext.language]}</label>
            <input
              className="form-control"
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
            />
          </div>


          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_SALE_TYPE[appContext.language]}</label>
            <select
              className="form-control"
              value={saleType}
              onChange={(e) => setSaleType(e.target.value)}
            >
              <option value="sale">{STR_REAL_SALE[appContext.language]}</option>
              <option value="upsale">{STR_UP_SALE[appContext.language]}</option>
            </select>
          </div>



          <div className="col-md-12 mb2 mt-2 text-end">
            <button
              className="btn btn-primary btn-padded btn-rounded"
              onClick={() => {
                appContext.activateDialog({
                  message: STR_REPORT_SALES[appContext.language] + "?",
                  onConfirm: reportSale
                })
              }}
            >
              {STR_SUBMIT[appContext.language]}
            </button>
          </div>

        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}