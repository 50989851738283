import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ACCOUNTING_JOURNAL, STR_ACCOUNTS_MANAGER, STR_ALL_CUSTOMERS, STR_BILLS, STR_BONUS_MANAGEMENT, STR_BUSINESS, STR_CASH_EXPENSES, STR_CASH_INCOME, STR_COMMISSION_MANAGEMENT, STR_CUSTOMER_DEPOSITS, STR_CUSTOMERS, STR_DASHBOARD, STR_GOALS, STR_LEADS_DISCOVERY, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NAME, STR_NO_RECORDS, STR_PHONE_NUMBER, STR_REPORTS, STR_ROLE, STR_SALES, STR_TODOS, STR_USERS, STR_VENDORS, STR_VIEW_ACTIVITIES, STR_WATEJA } from "../Strings";
import CustomersHome from '../views/CustomersHome';
import { MdBusiness, MdPeople } from "react-icons/md";
import BusinessNameStrip from "../ones/BusinessNameStrip";
import OneRecord from "../ones/OneRecord";
import OneCustomer from "../ones/OneCustomer";

export default function View({ businessUserId }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("todos");

  async function init() {
    //run initializations here
    setReady(false);
    await getBusinessUserActivities();
    setReady(true);
  }

	async function getBusinessUserActivities() {
		await callApi(
			'get-business-user-activities',
			{
				businessUserId,
				businessId: appContext.currentBusinessId,
			}
		).then((response) => {
      if(response.status === 1) {
        setData(response.data);
      } else {
        appContext.tellError(response.msg);
      }
    })
	}

  useEffect(() => {
    init();
  }, [ businessUserId ])

  if (ready && data) {
    return (
      <div
        className="container"
      >
        <BusinessNameStrip/>
        <div className="row">

          <div className="col-md-12">
            <h6
              className="mSectionTitle"
            >
              {STR_VIEW_ACTIVITIES[appContext.language]}
            </h6>
          </div>

          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-12 mt-2 mb-2">
                    <h6
                      className="font-regular"
                      style={{
                        fontSize: "18px"
                      }}
                    >
                      {data.businessUserData.username || 'N/A'}
                      <span
                        className="font-bold text-muted d-block"
                        style={{
                          fontSize: "14px"
                        }}
                      >
                        {STR_NAME[appContext.language]}
                      </span>
                    </h6>
                  </div>

                  <div className="col-6 col-md-6 mt-2 mb-2">
                    <h6
                      className="font-regular"
                      style={{
                        fontSize: "18px"
                      }}
                    >
                      {data.businessUserData.phone || 'N/A'}
                      <span
                        className="font-bold text-muted d-block"
                        style={{
                          fontSize: "14px"
                        }}
                      >
                        {STR_PHONE_NUMBER[appContext.language]}
                      </span>
                    </h6>
                  </div>

                  <div className="col-6 col-md-6 mt-2 mb-2">
                    <h6
                      className="font-regular"
                      style={{
                        fontSize: "18px"
                      }}
                    >
                      {data.businessUserData.role || 'N/A'}
                      <span
                        className="font-bold text-muted d-block"
                        style={{
                          fontSize: "14px"
                        }}
                      >
                        {STR_ROLE[appContext.language]}
                      </span>
                    </h6>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            
            <div className="mb-2 mt-2">
              <select
                className="form-control font-bold"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="todos">{STR_TODOS[appContext.language]}</option>
                <option value="sales">{STR_SALES[appContext.language]}</option>
                <option value="customers">{STR_CUSTOMERS[appContext.language]}</option>
                <option value="goals">{STR_GOALS[appContext.language]}</option>
              </select>
            </div>
          </div>

          <div className="col-md-12 mt-4 mb-2">

            {
              (selectedCategory === 'todos') ?
              (data.todos && data.todos.length > 0) ?
              data.todos.map((item, i) => {
                return (
                  <OneRecord type='todo' data={item} key={i}/>
                )
              })
              :
              <h6
                style={{
                  fontSize: "12px"
                }}
              >
                {STR_NO_RECORDS[appContext.language]}
              </h6>
              :""
            }

            {
              (selectedCategory === 'sales') ?
              (data.sales && data.sales.length > 0) ?
              data.sales.map((item, i) => {
                return (
                  <OneRecord type='sale' data={item} key={i}/>
                )
              })
              :
              <h6
                style={{
                  fontSize: "12px"
                }}
              >
                {STR_NO_RECORDS[appContext.language]}
              </h6>
              :""
            }

            {
              (selectedCategory === 'goals') ?
              (data.goals && data.goals.length > 0) ?
              data.goals.map((item, i) => {
                return (
                  <OneRecord type='goal' data={item} key={i}/>
                )
              })
              :
              <h6
                style={{
                  fontSize: "12px"
                }}
              >
                {STR_NO_RECORDS[appContext.language]}
              </h6>
              :""
            }

            {
              (selectedCategory === 'customers') ?
              (data.customers && data.customers.length > 0) ?
              data.customers.map((item, i) => {
                return (
                  <OneCustomer data={item} key={i}/>
                )
              })
              :
              <h6
                style={{
                  fontSize: "12px"
                }}
              >
                {STR_NO_RECORDS[appContext.language]}
              </h6>
              :""
            }

          </div>

        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}