import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_BUSINESS_NAME, STR_DASHBOARD, STR_INVALID_BUSINESS_NAME, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NO_BUSINESS_WARNING, STR_NO_PERMISSION, STR_PLEASE_WAIT, STR_START_FREE_TRIAL, STR_SUCCESS, STR_TITLE_REGISTER_BUSINESS, STR_TODOS, STR_WATEJA } from "../Strings";
import { TiWarningOutline } from "react-icons/ti";

export default function NoPermission(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [loading, setLoading] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    //..

    setReady(true);
  }


  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready) {
    return (

      <div className="container mw-700">

        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body text-center bg-secondary">

                <TiWarningOutline size={70} />

                <h6
                  style={{
                    fontSize: "16px",
                    padding: "20px",
                  }}
                  className="font-semi-bold"
                >
                  {STR_NO_PERMISSION[appContext.language]}
                </h6>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}