import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ASSIGN_PERSONEL, STR_CALL, STR_DASHBOARD, STR_DATE, STR_DESCRIPTION, STR_EDIT, STR_INVALID_DATE, STR_INVALID_DESCRIPTION, STR_INVALID_FOLLOWUP_STEP, STR_INVALID_PERSONNEL, STR_INVALID_PROMISE_DESCRIPTION, STR_INVALID_PROMISE_DUE_DATE, STR_MARKETER, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NO, STR_PLEASE_WAIT, STR_PROMISE_DESCRIPTION, STR_PROMISE_DUE_DATE, STR_REPORT_FOLLOWUP, STR_REPORT_SALES, STR_REPORT_VISIT, STR_SELLER, STR_SEND_EMAIL, STR_SEND_SMS, STR_STEP, STR_SUBMIT, STR_SUCCESS, STR_TODOS, STR_TURN_TO_CLIENT, STR_VIEW_MORE_INFO, STR_WAS_ANY_PROMISE_MADE, STR_WATEJA, STR_YES } from "../Strings";
import CustomersHome from './CustomersHome';
import { MdCall, MdCheckCircle, MdEdit, MdEmail, MdFacebook, MdMessage, MdOutlineFacebook, MdOutlineWhatsapp, MdWhatsapp } from "react-icons/md";
import { BiInfoCircle, BiLogoLinkedin, BiLogoWhatsapp, BiPurchaseTag } from "react-icons/bi";
import { SlUserFollowing } from "react-icons/sl";
import { BsInstagram, BsTelegram } from "react-icons/bs";
import CustomerWidget from '../ones/CustomerWidget';

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(null);
  const [marketerId, setMarketerId] = useState("");
  const [sellerId, setSellerId] = useState("");


  async function init() {
    //run initializations here
    setReady(false);
    await Promise.all([
      getData(),
      appContext.getBusinessUsers(),
    ])
    setReady(true);
  }

  async function getData() {
    await callApi("get-customer-data", { uid: id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }

  async function submit() {
    const _runner = async () => {
      setLoading(true);
      await callApi(
        "assign-customer-personnel",
        {
          marketerId,
          sellerId,
          customerId: data.id,
        }
      ).then(response => {
        if (response.status === 1) {
          appContext.tellMessage(STR_SUCCESS[appContext.language]);
          appContext.navBack();
        } else {
          appContext.tellError(response.msg);
        }
      })
      setLoading(false);
    }

    if (!loading) {
      if (marketerId && sellerId) {
        await _runner();
      } else {
        appContext.tellError(STR_INVALID_PERSONNEL[appContext.language]);
      }

    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language])
    }
  }


  useEffect(() => {
    init();
  }, [id])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  useEffect(() => {
    if (data) {
      setMarketerId(data.marketerId);
      setSellerId(data.sellerId);
    }
  }, [data])

  if (ready && data) {
    return (
      <div
        className="container mw-700"
      >
        <h6 className="mSectionTitle">{STR_ASSIGN_PERSONEL[appContext.language]}</h6>

        <CustomerWidget data={data} />

        <div className="row">



          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_MARKETER[appContext.language]}</label>
            <select
              className="form-control"
              value={marketerId || ""}
              onChange={(e) => setMarketerId(e.target.value)}
            >
              <option value=""></option>
              {
                (appContext.businessUsers && appContext.businessUsers.length > 0) ?
                  appContext.businessUsers.map((item, i) => {
                    return (
                      <option key={i} value={item.id}>{item.phone}</option>
                    )
                  })
                  : ""
              }
            </select>
          </div>

          <div className="col-md-12 mb-2 mt-2">
            <label className="form-label">{STR_SELLER[appContext.language]}</label>
            <select
              className="form-control"
              value={sellerId || ""}
              onChange={(e) => setSellerId(e.target.value)}
            >
              <option value=""></option>
              {
                (appContext.businessUsers && appContext.businessUsers.length > 0) ?
                  appContext.businessUsers.map((item, i) => {
                    return (
                      <option key={i} value={item.id}>{item.phone}</option>
                    )
                  })
                  : ""
              }
            </select>
          </div>



          <div className="col-md-12 mb2 mt-2 text-end">
            <button
              className="btn btn-primary btn-padded btn-rounded"
              onClick={() => {
                appContext.activateDialog({
                  message: STR_ASSIGN_PERSONEL[appContext.language] + "?",
                  onConfirm: submit
                })
              }}
            >
              {STR_SUBMIT[appContext.language]}
            </button>
          </div>

        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}