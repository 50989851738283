import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ADD_NEW, STR_BONUS_EARNED, STR_CLIENTS, STR_COMMISSION_EARDNED, STR_COMPLETED_TODOS, STR_CREATE_TODO, STR_DASHBOARD, STR_FILTER_BY_NAME_OR_PHONE, STR_LEADS, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_MY_CUSTOMERS, STR_MY_SALES, STR_NO_RECORDS, STR_PENDING_TODOS, STR_TODAY_TODOS, STR_TODOS, STR_UNIQUE_CUSTOMERS_ENGAGED, STR_VIEW, STR_VIEW_CURRENT_GOALS, STR_WATEJA } from "../Strings";
import { MdPlaylistAdd, MdSearch } from "react-icons/md";
import OneCustomer from '../ones/OneCustomer';
import { TbTarget } from "react-icons/tb";
import OneRecord from '../ones/OneRecord';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [today, setToday] = useState([]);
  const [pending, setPending] = useState([]);
  const [completed, setCompleted] = useState([]);

  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getMyTodos();
    setReady(true);
  }

  function groupTodos() {
    const todayDate = new Date();
    const startOfToday = new Date(todayDate.setHours(0, 0, 0, 0)).getTime() / 1000; // Start of today (timestamp in seconds)
    const endOfToday = new Date(todayDate.setHours(23, 59, 59, 999)).getTime() / 1000; // End of today (timestamp in seconds)

    const todosToday = [];
    const todosPending = [];
    const todosCompleted = [];

    if (appContext.myTodos) {
      const todos = appContext.myTodos;

      for (let i = 0; i < todos.length; i++) {
        const todo = todos[i];
        const { dateDue, status } = todo;

        if (status === "completed") {
          todosCompleted.push(todo);
        } else if (status === "pending") {
          // Check if due date falls within today
          if (dateDue >= startOfToday && dateDue <= endOfToday) {
            todosToday.push(todo);
          } else {
            todosPending.push(todo);
          }
        }
      }
    }

    setToday(todosToday);
    setPending(todosPending);
    setCompleted(todosCompleted);
  }


  useEffect(() => {
    groupTodos();
  }, [appContext.myTodos])

  useEffect(() => {
    init();
  }, [])

  if (ready) {
    return (
      <div
        className="container"

      >
        <div className="row">
          <div className="col-12 col-md-6 mb-3 mt-3">
            <h6
              className="mSectionTitle mNoMargin"
            >
              {STR_COMPLETED_TODOS[appContext.language]}
            </h6>
          </div>



          <div className="col-md-12  mb-3 mt-3">
            {
              (completed.length > 0) ?
                completed.map((item, i) => {
                  return (
                    <OneRecord key={i} data={item} type="todo" />
                  )
                })
                :
                <h6>{STR_NO_RECORDS[appContext.language]}</h6>
            }
          </div>


        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}