import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatDate, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_CALL, STR_CUSTOMER_WILL_BE_CLIENT, STR_DASHBOARD, STR_EDIT, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NO_RECORDS, STR_PROCESSED, STR_PROCESSED_SALES, STR_PROMISE, STR_PROMISE_DUE_DATE, STR_PURCHASES, STR_REPORT_FOLLOWUP, STR_REPORT_SALES, STR_REPORT_VISIT, STR_SALES_STATEMENT, STR_SEND_EMAIL, STR_SEND_SMS, STR_SUCCESS, STR_TODOS, STR_TOTAL_DEBT, STR_TOTAL_DISCOUNT_OFFERED, STR_TOTAL_PAID_AMOUNT, STR_TOTAL_PROCESSED_SALES_IN_TZS, STR_TURN_TO_CLIENT, STR_UNPROCESSED_SALES, STR_VIEW_MORE_INFO, STR_VISITS, STR_WATEJA } from "../Strings";
import CustomersHome from '../views/CustomersHome';
import { MdCall, MdCheckCircle, MdEdit, MdEmail, MdFacebook, MdMessage, MdOutlineFacebook, MdOutlineWhatsapp, MdWhatsapp } from "react-icons/md";
import { BiInfoCircle, BiLogoLinkedin, BiLogoWhatsapp, BiPurchaseTag } from "react-icons/bi";
import { SlUserFollowing } from "react-icons/sl";
import { BsInstagram, BsTelegram } from "react-icons/bs";
import CustomerWidget from '../ones/CustomerWidget';
import OneRecord from "../ones/OneRecord";

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState("processed");
  const [loading, setLoading] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    await getData()
    setReady(true);
  }

  async function getData() {
    await callApi("get-customer-sales-statement", { id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }


  useEffect(() => {
    init();
  }, [id])

  useEffect(() => {
    if (data) {
      //..
    }
  }, [data])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready && data) {
    return (
      <div
        className="container mw-700"
      >

				<h6
					className="mSectionTitle"
				>
					{STR_SALES_STATEMENT[appContext.language]}
				</h6>

        <CustomerWidget data={data.customerData} />

        <div className="row">
					
          <div className="col-md-6" style={{ height: "100%" }}>
						<div className="card" style={{ height: "100%" }}>
							<div className="card-body">
								<h6
									className="font-bold"
									style={{
										fontSize: "28px",
									}}
								>
									{formatMoney(data.totalTotalAmount)}
								</h6>
								<span
									style={{
										fontSize: "14px"
									}}
								>
									{STR_TOTAL_PROCESSED_SALES_IN_TZS[appContext.language]}
								</span>
							</div>
						</div>
					</div>

					<div className="col-md-6" style={{ height: "100%" }}>
						<div className="card" style={{ height: "100%" }}>
							<div className="card-body">
								<h6
									className="font-bold"
									style={{
										fontSize: "28px",
									}}
								>
									{formatMoney(data.totalAmountPaid)}
								</h6>
								<span
									style={{
										fontSize: "14px"
									}}
								>
									{STR_TOTAL_PAID_AMOUNT[appContext.language]}
								</span>
							</div>
						</div>
					</div>

					<div className="col-md-6" style={{ height: "100%" }}>
						<div className="card" style={{ height: "100%" }}>
							<div className="card-body">
								<h6
									className="font-bold"
									style={{
										fontSize: "28px",
									}}
								>
									{formatMoney(data.totalDiscount)}
								</h6>
								<span
									style={{
										fontSize: "14px"
									}}
								>
									{STR_TOTAL_DISCOUNT_OFFERED[appContext.language]}
								</span>
							</div>
						</div>
					</div>

					<div className="col-md-6" style={{ height: "100%" }}>
						<div className="card" style={{ height: "100%" }}>
							<div className="card-body">
								<h6
									className="font-bold"
									style={{
										fontSize: "28px",
									}}
								>
									{formatMoney(data.totalDebtBalance)}
								</h6>
								<span
									style={{
										fontSize: "14px"
									}}
								>
									{STR_TOTAL_DEBT[appContext.language]}
								</span>
							</div>
						</div>
					</div>

					<div className="col-md-12">
            
            <div className="mb-2 mt-2">
              <select
                className="form-control font-bold"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="processed">{STR_PROCESSED_SALES[appContext.language]}</option>
                <option value="unprocessed">{STR_UNPROCESSED_SALES[appContext.language]}</option>
              </select>
            </div>
          </div>

					<div className="col-md-12 mt-4 mb-2">
            {
              (selectedCategory === 'processed') ?
              (data.processedSales && data.processedSales.length > 0) ?
              data.processedSales.map((item, i) => {
                return (
                  <OneRecord type='sale' data={item} key={i}/>
                )
              })
              :
              <h6
                style={{
                  fontSize: "12px"
                }}
              >
                {STR_NO_RECORDS[appContext.language]}
              </h6>
              :""
            }

            {
              (selectedCategory === 'unprocessed') ?
              (data.unprocessedSales && data.unprocessedSales.length > 0) ?
              data.unprocessedSales.map((item, i) => {
                return (
                  <OneRecord type='sale' data={item} key={i}/>
                )
              })
              :
              <h6
                style={{
                  fontSize: "12px"
                }}
              >
                {STR_NO_RECORDS[appContext.language]}
              </h6>
              :""
            }

          </div>


        </div>

      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}