import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatDate, formatDateForInput, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ACCOUNT_NAME, STR_ACCOUNTS_RECEIVABLE, STR_AMOUNT, STR_AMOUNT_PAID, STR_ASK_CONTINUE, STR_BALANCE, STR_BILLS, STR_CALL, STR_CUSTOMER_WILL_BE_CLIENT, STR_DASHBOARD, STR_DATE, STR_DISCOUNT, STR_EDIT, STR_INITIAL_AMOUNT, STR_INVALID_AMOUNT, STR_INVALID_DATE, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NAME, STR_PARTICULARS, STR_PAYABLE, STR_PAYMENTS, STR_PRODUCT_CODE, STR_PROMISE, STR_PROMISE_DUE_DATE, STR_PURCHASES, STR_QUANTITY, STR_RECEIVABLE, STR_RECEIVE_PAYMENT, STR_RECORD_PAYMENTS, STR_REPORT_FOLLOWUP, STR_REPORT_SALES, STR_REPORT_VISIT, STR_SALES, STR_SEND_EMAIL, STR_SEND_SMS, STR_SUB_TOTAL, STR_SUBMIT, STR_SUCCESS, STR_TITLE, STR_TODOS, STR_TOTAL_AMOUNT, STR_TURN_TO_CLIENT, STR_UNIT, STR_UNIT_PRICE, STR_VENDOR, STR_VIEW_MORE_INFO, STR_VISITS, STR_WATEJA } from "../Strings";
import CustomersHome from '../views/CustomersHome';
import { MdCall, MdCheckCircle, MdEdit, MdEmail, MdFacebook, MdMessage, MdOutlineFacebook, MdOutlineWhatsapp, MdWhatsapp } from "react-icons/md";
import { BiInfoCircle, BiLogoLinkedin, BiLogoWhatsapp, BiPurchaseTag } from "react-icons/bi";
import { SlUserFollowing } from "react-icons/sl";
import { BsInstagram, BsTelegram } from "react-icons/bs";
import CustomerWidget from '../ones/CustomerWidget';

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [amountPaid, setAmountPaid] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [datePaid, setDatePaid] = useState(formatDateForInput(Date.now() / 1000));

  async function init() {
    //run initializations here
    console.log(appContext.currentUserRole);

    setReady(false);
    await getData()
    setReady(true);
  }

  async function getData() {
    await callApi("get-bill-data", { id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }

  async function recordPayment() {
    if (datePaid.trim().length > 0) {
      if (!isNaN(amountPaid) && Number(amountPaid) > 0 && Number(amountPaid) <= (Number(data.totalAmount) - Number(data.amountPaid))) {
        setLoading(true);
        await callApi("record-bill-payment", { id, amountPaid, datePaid }).then(response => {
          if (response.status === 1) {
            appContext.tellMessage(STR_SUCCESS[appContext.language]);
            setAmountPaid(0);
            getData()
            appContext.getAllBills();
          } else {
            appContext.tellError(response.msg);
          }
        })
        setLoading(false);
      } else {
        appContext.tellError(STR_INVALID_AMOUNT[appContext.language]);
      }
    } else {
      appContext.tellError(STR_INVALID_DATE[appContext.language]);
    }

  }


  useEffect(() => {
    init();
  }, [id])



  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready && data) {
    return (
      <div
        className="container"
      >
        <h6
          className="mSectionTitle"
        >
          {data.title}
          <span className="font-semi-bold text-muted" style={{ display: "block", fontSize: "14px" }}>{STR_BILLS[appContext.language]}</span>
        </h6>
        <hr />


        <div className="row">

          <div className="col-6 mb-2 mt-2">
            <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_TOTAL_AMOUNT[appContext.language]}</label>
            <h6
              className="font-regular"
              style={{
                fontSize: "24px",
              }}
            >
              {formatMoney(data.totalAmount)}
              <span className="font-semi-bold text-muted" style={{ display: "block", fontSize: "14px" }}>TZS</span>
            </h6>
          </div>


          <div className="col-6 mb-2 mt-2">
            <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_AMOUNT_PAID[appContext.language]}</label>
            <h6
              className="font-regular"
              style={{
                fontSize: "24px",
              }}
            >
              {formatMoney(data.amountPaid)}
              <span className="font-semi-bold text-muted" style={{ display: "block", fontSize: "14px" }}>TZS</span>
            </h6>
          </div>

          <div className="col-6 mb-2 mt-2">
            <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_BALANCE[appContext.language]}</label>
            <h6
              className="font-regular"
              style={{
                fontSize: "24px",
              }}
            >
              {formatMoney(Number(data.totalAmount) - Number(data.amountPaid))}
              <span className="font-semi-bold text-muted" style={{ display: "block", fontSize: "14px" }}>TZS</span>
            </h6>
          </div>

          {
            (data.vendorName) ?
              <div className="col-12 mb-2 mt-2">
                <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_VENDOR[appContext.language]}</label>
                <h6
                  className="font-regular"
                  style={{
                    fontSize: "20px",
                  }}
                >
                  {data.vendorName}
                </h6>
              </div>
              : ""
          }

          {
            (data.accountName) ?
              <div className="col-12 mb-2 mt-2">
                <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_ACCOUNT_NAME[appContext.language]}</label>
                <h6
                  className="font-regular"
                  style={{
                    fontSize: "20px",
                  }}
                >
                  {data.accountName}
                </h6>
              </div>
              : ""
          }

          <div className="col-12 mb-2 mt-2">
            <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_PARTICULARS[appContext.language]}</label>

            <div style={{ overflowX: 'auto' }}> {/* Wrapper for horizontal scrolling */}
              <table className="table table-striped" style={{ minWidth: '500px' }}> {/* Set minWidth */}
                <thead>
                  <tr>
                    <th style={{ fontSize: "12px" }} className="font-bold">
                      {STR_TITLE[appContext.language]}
                    </th>
                    <th style={{ fontSize: "12px" }} className="font-bold">
                      {STR_AMOUNT[appContext.language]}
                    </th>
                  </tr>
                </thead>

                {
                  (JSON.parse(data.items).length > 0) ?
                    <tbody>
                      {
                        JSON.parse(data.items).map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item.item || item.name || item.title}</td>
                              <td>{formatMoney(item.amount)}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                    : null

                }
              </table>
            </div>

          </div>


          {
            (
              (Number(data.totalAmount) - Number(data.amountPaid) > 0) &&
              (
                appContext.currentUserRole === 'manager' ||
                appContext.currentUserRole === 'accountant' ||
                appContext.currentUserRole === 'cashier'
              )
            ) ?
              <div className="col-12 mb-2 mt-2">
                <div className="card">
                  <div className="card-body">
                    <h6 className="card-title">{STR_RECORD_PAYMENTS[appContext.language]}</h6>

                    <div className="mb-2 mt-4">
                      <label>{STR_AMOUNT_PAID[appContext.language]}</label>
                      <input
                        className="form-control"
                        value={amountPaid}
                        onChange={(e) => setAmountPaid(e.target.value)}
                      />
                    </div>

                    <div className="mb-2 mt-2">
                      <label>{STR_DATE[appContext.language]}</label>
                      <input
                        className="form-control"
                        value={datePaid}
                        onChange={(e) => setDatePaid(e.target.value)}
                        type="date"
                      />
                    </div>

                    <div className="mb-2 mt-2 text-end">
                      <button
                        className="btn btn-secondary btn-rounded mt-4"
                        onClick={() => {
                          appContext.activateDialog({
                            message: STR_ASK_CONTINUE[appContext.language],
                            onConfirm: recordPayment,
                          })
                        }}
                      >
                        {STR_SUBMIT[appContext.language]}
                      </button>
                    </div>

                  </div>
                </div>
              </div>
              : ""
          }

          <div className="col-12 mb-2 mt-2">
            <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_PAYMENTS[appContext.language]}</label>

            <table className="table table-striped">
              <thead>
                <tr>
                  <th>{STR_AMOUNT_PAID[appContext.language]}</th>
                  <th>{STR_DATE[appContext.language]}</th>
                </tr>
              </thead>

              {
                (JSON.parse(data.transactionsHistory).length > 0) ?
                  <tbody>
                    {
                      JSON.parse(data.transactionsHistory).map((item, i) => {
                        return (
                          <tr
                            key={i}
                          >
                            <td>{formatMoney(item.amount)}</td>
                            <td>{formatDate(item.date).substring(0, 10)}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                  : ""
              }
            </table>
          </div>

        </div>

      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}