import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatDateForInput, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ACTUAL, STR_APPLY_BONUS_ON_SALES, STR_APPLY_BONUS_ON_UNIQUE_CUSTOMERS_ENGAGED, STR_APPLY_COMMISSION_ON_SALES, STR_CALL, STR_COMMISSION_PERCENT, STR_CREATE_GOAL, STR_DASHBOARD, STR_DATE, STR_DESCRIPTION, STR_EDIT, STR_END_DATE, STR_GOALS, STR_INVALID_DATE, STR_INVALID_DESCRIPTION, STR_INVALID_FOLLOWUP_STEP, STR_INVALID_PROMISE_DESCRIPTION, STR_INVALID_PROMISE_DUE_DATE, STR_MANAGE_ACTIVE_GOAL, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NO, STR_PLEASE_WAIT, STR_PROMISE_DESCRIPTION, STR_PROMISE_DUE_DATE, STR_REPORT_FOLLOWUP, STR_REPORT_SALES, STR_REPORT_VISIT, STR_SALES_BONUS_AMOUNT, STR_SALES_TARGET, STR_SEND_EMAIL, STR_SEND_SMS, STR_START_DATE, STR_STEP, STR_SUBMIT, STR_SUCCESS, STR_TARGET, STR_TODOS, STR_TOTAL_SALES, STR_TURN_TO_CLIENT, STR_UNIQUE_CUSTOMERS_BONUS_AMOUNT, STR_UNIQUE_CUSTOMERS_ENGAGED, STR_UNIQUE_CUSTOMERS_ENGAGED_TARGET, STR_VIEW_MORE_INFO, STR_WAS_ANY_PROMISE_MADE, STR_WATEJA, STR_YES } from "../Strings";
import CustomersHome from './CustomersHome';
import { MdCall, MdCheckCircle, MdEdit, MdEmail, MdFacebook, MdMessage, MdOutlineFacebook, MdOutlineWhatsapp, MdWhatsapp } from "react-icons/md";
import { BiInfoCircle, BiLogoLinkedin, BiLogoWhatsapp, BiPurchaseTag } from "react-icons/bi";
import { SlUserFollowing } from "react-icons/sl";
import { BsInstagram, BsTelegram } from "react-icons/bs";

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userMetrics, setUserMetrics] = useState();
  const [uniqueCustomersEngagedTarget, setUniqueCustomersEngagedTarget] = useState(0);
  const [applyBonusOnSales, setApplyBonusOnSales] = useState('no');
  const [applyBonusOnCustomers, setApplyBonusOnCustomers] = useState('no');
  const [salesBonusAmount, setSalesBonusAmount] = useState(0);
  const [customersBonusAmount, setCustomersBonusAmount] = useState(0);
  const [applyCommissionOnSales, setApplyCommissionOnSales] = useState('no');
  const [salesCommissionPercent, setSalesCommissionPercent] = useState(0);
  const [salesTarget, setSalesTarget] = useState(0);

  async function init() {
    //run initializations here
    setReady(false);
    await Promise.all([
      getData(),
    ])
    setReady(true);
  }

  async function getData() {
    await callApi("get-user-goal-data", { id }).then(async response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }



  useEffect(() => {
    init();
  }, [id])

  useEffect(() => {
    if (data) {
      setUserMetrics(JSON.parse(data.userMetrics));
      setStartDate(formatDateForInput(data.startDate));
      setEndDate(formatDateForInput(data.endDate));
      setUniqueCustomersEngagedTarget(data.uniqueCustomersEngagedTarget);
      setApplyBonusOnSales(data.applyBonusOnSales);
      setApplyBonusOnCustomers(data.applyBonusOnCustomers);
      setSalesBonusAmount(data.salesBonusAmount);
      setCustomersBonusAmount(data.customersBonusAmount);
      setApplyCommissionOnSales(data.applyCommissionOnSales);
      setSalesCommissionPercent(data.salesCommissionPercent);
      setSalesTarget(data.salesTarget);
    }
  }, [data])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])



  if (ready && data) {
    return (
      <div
        className="container mw-700"
      >
        <h6 className="mSectionTitle">{STR_GOALS[appContext.language]}</h6>
        <div className="card">
          <div className="card-body">

            <div className="row">
              <div className="col-6 mt-4 mb-4">
                <h6
                  style={{
                    fontSize: "16px",
                  }}
                  className="font-semi-bold text-secondary"
                >
                  <span className="badge bg-dark" style={{ fontSize: "18px" }}>{formatDateForInput(data.startDate)}</span>
                  <span className="text-muted" style={{ display: "block", fontSize: "12px" }}>{STR_START_DATE[appContext.language]}</span>
                </h6>
              </div>

              <div className="col-6 mt-4 mb-4 text-end">
                <h6
                  style={{
                    fontSize: "16px",
                  }}
                  className="font-semi-bold text-secondary"
                >
                  <span className="badge bg-dark" style={{ fontSize: "18px" }}>{formatDateForInput(data.endDate)}</span>
                  <span className="text-muted" style={{ display: "block", fontSize: "12px" }}>{STR_END_DATE[appContext.language]}</span>
                </h6>
              </div>

              <div className="col-12">
                <hr />
                <h6
                  style={{
                    fontSize: "14px",
                  }}
                  className="font-bold text-muted"
                >
                  {STR_TOTAL_SALES[appContext.language]}
                </h6>

                <div className="row mt-4">
                  <div className="col-6">
                    <h6
                      className="font-semi-bold"
                      style={{
                        fontSize: "24px"
                      }}
                    >
                      {formatMoney(data.salesTarget)}
                      <span className="font-light" style={{ display: 'block', fontSize: "14px" }}>{STR_TARGET[appContext.language]}</span>
                    </h6>
                  </div>

                  <div className="col-6">
                    <h6
                      className={(Number(data.salesTotal) < Number(data.salesTarget)) ? "font-semi-bold text-danger" : (Number(data.salesTotal) > Number(data.salesTarget)) ? "font-semi-bold text-success" : "font-semi-bold"}
                      style={{
                        fontSize: "24px"
                      }}
                    >
                      {formatMoney(data.salesTotal)}
                      <span className="font-light" style={{ display: 'block', fontSize: "14px" }}>{STR_ACTUAL[appContext.language]}</span>
                    </h6>
                  </div>

                </div>

              </div>

              <div className="col-12">
                <hr />
                <h6
                  style={{
                    fontSize: "14px",
                  }}
                  className="font-bold text-muted"
                >
                  {STR_UNIQUE_CUSTOMERS_ENGAGED[appContext.language]}
                </h6>

                <div className="row mt-4">
                  <div className="col-6">
                    <h6
                      className="font-semi-bold"
                      style={{
                        fontSize: "24px"
                      }}
                    >
                      {data.uniqueCustomersEngagedTarget}
                      <span className="font-light" style={{ display: 'block', fontSize: "14px" }}>{STR_TARGET[appContext.language]}</span>
                    </h6>
                  </div>

                  <div className="col-6">
                    <h6
                      className="font-semi-bold"
                      style={{
                        fontSize: "24px"
                      }}
                    >
                      {formatMoney(JSON.parse(data.uniqueCustomersEngagedList).length)}
                      <span className="font-light" style={{ display: 'block', fontSize: "14px" }}>{STR_ACTUAL[appContext.language]}</span>
                    </h6>
                  </div>

                </div>

              </div>

              {
                JSON.parse(data.userMetrics).map((item, i) => {
                  return (
                    <div className="col-12" key={i}>
                      <hr />
                      <h6
                        style={{
                          fontSize: "14px",
                        }}
                        className="font-bold text-muted"
                      >
                        {appContext.getUserMetricDescription(item.id)}
                      </h6>

                      <div className="row mt-4">
                        <div className="col-6">
                          <h6
                            className="font-semi-bold"
                            style={{
                              fontSize: "24px"
                            }}
                          >
                            {formatMoney(item.target)}
                            <span className="font-light" style={{ display: 'block', fontSize: "14px" }}>{STR_TARGET[appContext.language]}</span>
                          </h6>
                        </div>

                        <div className="col-6">
                          <h6
                            className={(Number(item.actual) < Number(item.target)) ? "font-semi-bold text-danger" : (Number(item.actual) > Number(item.target)) ? "font-semi-bold text-success" : "font-semi-bold"}
                            style={{
                              fontSize: "24px"
                            }}
                          >
                            {formatMoney(item.actual)}
                            <span className="font-light" style={{ display: 'block', fontSize: "14px" }}>{STR_ACTUAL[appContext.language]}</span>
                          </h6>
                        </div>

                      </div>

                    </div>
                  )
                })
              }
            </div>

          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}