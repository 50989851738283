import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { CDN_URL, formatDate, formatMoney } from "../Helpers";
import { MdCall } from "react-icons/md";
import { STR_BEEN_CLIENT_DURATION, STR_BEEN_LEAD_DURATION, STR_CUSTOMER_STATUS_ACTIVE, STR_CUSTOMER_STATUS_NEW, STR_CUSTOMER_STATUS_PASSIVE, STR_DAYS_SINCE_FIRST_PURCHASE, STR_DAYS_SINCE_LAST_PURCHASE, STR_DEPOSITS, STR_ENTRANCE_DATE, STR_RETAIL, STR_WHOLESALE } from "../Strings";

export default function View({ data }) {
  const appContext = useContext(AppContext);

  /* Place states here */
  const [beenLeadDuration, setBeenLeadDuration] = useState(0);
  const [beenClientDuration, setBeenClientDuration] = useState(0);
  const [daysSinceFirstPurchase, setDaysSinceFirstPurchase] = useState(0);
  const [daysSinceLastPurchase, setDaysSinceLastPurchase] = useState(0);


  useEffect(() => {
    if (data.customerLevel === 'client') {
      let timeDiff1 = Number(data.becomeClientDate) - Number(data.becomeLeadDate);
      let days1 = (timeDiff1 / (24 * 60 * 60)).toFixed(0);
      setBeenLeadDuration(days1);

      let timeDiff2 = Math.floor((Date.now() / 1000) - Number(data.becomeClientDate));
      let days2 = (timeDiff2 / (24 * 60 * 60)).toFixed(0);
      setBeenClientDuration(days2)

      let timeDiff3 = Math.floor((Date.now() / 1000) - Number(data.firstPurchaseDate));
      let days3 = (timeDiff3 / (24 * 60 * 60)).toFixed(0);
      setDaysSinceFirstPurchase(days3);

      let timeDiff4 = Math.floor((Date.now() / 1000) - Number(data.lastPurchaseDate));
      let days4 = (timeDiff4 / (24 * 60 * 60)).toFixed(0);
      setDaysSinceLastPurchase(days4);

    } else if (data.customerLevel === 'lead') {
      let timeDiff1 = Math.floor((Date.now() / 1000) - Number(data.becomeLeadDate));
      let days1 = (timeDiff1 / (24 * 60 * 60)).toFixed(0);
      setBeenLeadDuration(days1);
    }
  }, [data])

  return (
    <div className="card">
      <div className="card-body">
        <h6
          className="font-semi-bold"
          style={{
            fontSize: "18px",
            textTransform: "capitalize",
          }}
        >
          {data.name}
        </h6>
        <h6
          className="font-semi-bold text-muted"
          style={{
            fontSize: "14px",
            textTransform: "capitalize",
          }}
        >
          <MdCall /> {data.phone}
        </h6>

        <span
          className={(data.customerLevel === 'lead') ? 'badge bg-danger' : 'badge bg-success'}
        >
          {data.customerLevel}
        </span>

        {
          (Number(data.depositBalance) > 0) ?
            <h6
              className="font-bold text-success text-end"
              style={{
                fontSize: "30px",
                textTransform: "capitalize",
              }}
            >
              {formatMoney(data.depositBalance)}
              <span
                className="font-semi-bold text-muted"
                style={{
                  fontSize: "12px",
                  display: "block"
                }}
              >
                {STR_DEPOSITS[appContext.language]} (TZS)
              </span>
            </h6>
            : ""
        }

        <div className="row mt-3">
          <hr />
          <div className="col-6">
            <h6
              style={{
                fontSize: "28px",
              }}
            >
              {beenLeadDuration}
              <span
                style={{
                  display: 'block',
                  fontSize: "12px",
                }}
              >
                {STR_BEEN_LEAD_DURATION[appContext.language]}
              </span>
            </h6>
          </div>

          <div className="col-6">
            <h6
              style={{
                fontSize: "28px",
              }}
            >
              {beenClientDuration}
              <span
                style={{
                  display: 'block',
                  fontSize: "12px",
                }}
              >
                {STR_BEEN_CLIENT_DURATION[appContext.language]}
              </span>
            </h6>
          </div>

          {
            (data.firstPurchaseDate) ?
              <div className="col-6">
                <h6
                  style={{
                    fontSize: "28px",
                  }}
                >
                  {daysSinceFirstPurchase}
                  <span
                    style={{
                      display: 'block',
                      fontSize: "12px",
                    }}
                  >
                    {STR_DAYS_SINCE_FIRST_PURCHASE[appContext.language]}
                  </span>
                </h6>
              </div>
              : ""
          }

          {
            (data.lastPurchaseDate) ?
              <div className="col-6">
                <h6
                  style={{
                    fontSize: "28px",
                  }}
                >
                  {daysSinceLastPurchase}
                  <span
                    style={{
                      display: 'block',
                      fontSize: "12px",
                    }}
                  >
                    {STR_DAYS_SINCE_LAST_PURCHASE[appContext.language]}
                  </span>
                </h6>
              </div>
              : ""
          }

          <div className="col-12">
            <h6
              style={{
                borderLeft: "6px solid var(--primaryColor)",
                paddingLeft: "5px",
                fontSize: "16px",
              }}
              className="mt-3 font-bold"
            >
              {(data.customerType === 'retail') ? STR_RETAIL[appContext.language] : ""}
              {(data.customerType === 'wholesale') ? STR_WHOLESALE[appContext.language] : ""}
            </h6>
            {(data.status === 'new') ? <span style={{ fontSize: "14px", textTransform: "uppercase" }} className="font-bold badge bg-warning text-dark">{STR_CUSTOMER_STATUS_NEW[appContext.language]}</span> : ""}
            {(data.status === 'active') ? <span style={{ fontSize: "14px", textTransform: "uppercase" }} className="font-bold badge bg-success">{STR_CUSTOMER_STATUS_ACTIVE[appContext.language]}</span> : ""}
            {(data.status === 'passive') ? <span style={{ fontSize: "14px", textTransform: "uppercase" }} className="font-bold badge bg-danger">{STR_CUSTOMER_STATUS_PASSIVE[appContext.language]}</span> : ""}
          </div>
        </div>

      </div>
    </div>
  );
}
