import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ADD_NEW, STR_ADD_STOCK, STR_AMOUNT_PAID, STR_AVAILABLE_QUANTITY, STR_BUSINESS, STR_BUSINESS_NAME, STR_CLIENT, STR_COMPANY, STR_CUSTOMER_LEVEL, STR_CUSTOMER_TYPE, STR_DASHBOARD, STR_EMAIL_ADDRESS, STR_GOVERNMENT, STR_INCLUDE_COUNTRY_CODE_ON_PHONE, STR_INCLUDE_LINK_TO_PROFILE, STR_INVALID_AMOUNT_PAID, STR_INVALID_BUSINESS_NAME, STR_INVALID_CODE, STR_INVALID_NAME, STR_INVALID_PHONE, STR_INVALID_UNIT, STR_INVALID_UNIT_PRICE, STR_ITEM_TYPE, STR_LEAD, STR_LOCATION, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NAME, STR_NO_BUSINESS_WARNING, STR_NO_OPTIONS, STR_NOTES, STR_OTHER_COMM_CHANNELS, STR_PERSON, STR_PHONE_NUMBER, STR_PLEASE_WAIT, STR_PRODUCT_CODE, STR_START_FREE_TRIAL, STR_SUBMIT, STR_SUCCESS, STR_TITLE_RECORD_CUSTOMER, STR_TITLE_REGISTER_BUSINESS, STR_TODOS, STR_TOTAL_AMOUNT, STR_UNIT, STR_UNIT_BUYING_PRICE, STR_UNIT_PRICE, STR_UNIT_RETAIL_SELLING_PRICE, STR_UNIT_WHOLESALE_SELLING_PRICE, STR_VENDOR, STR_WATEJA } from "../Strings";
import { TiWarningOutline } from "react-icons/ti";
import { MdClose } from "react-icons/md";
import Select from "react-select";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(null); //vendor data
  const [id, setId] = useState(props.id); //vendor id
  const [name, setName] = useState("");
  const [itemType, setItemType] = useState("stock");
  const [code, setCode] = useState("");
  const [unit, setUnit] = useState("");
  const [unitBuyingPrice, setUnitBuyingPrice] = useState(0);
  const [unitRetailSellingPrice, setUnitRetailSellingPrice] = useState(0);
  const [unitWholesaleSellingPrice, setUnitWholesaleSellingPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);
  const [vendorOptions, setVendorOptions] = useState([]);


  async function init() {
    //run initializations here
    setReady(false);
    await Promise.all([
      appContext.getAllVendors(),
    ])
    if (id) {
      await getData()
    }

    setReady(true);
  }

  async function getData() {
    await callApi("get-vendor-data", { id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }

  function getVendorOptions() {
    if (appContext.allVendors && appContext.allVendors.length > 0) {
      const options = appContext.allVendors.map((item, i) => {
        return (
          {
            value: JSON.stringify(item),
            label: item.name
          }
        )
      })

      setVendorOptions(options);
    }
  }

  function getTotalAmount() {
    if (!isNaN(unitBuyingPrice) && !isNaN(quantity)) {
      let _total = Number(unitBuyingPrice) * Number(quantity);
      setTotalAmount(_total);
    }
  }

  async function addStock() {
    if (!loading) {
      if (name.trim().length > 0) {
        if (unit.trim().length > 0) {
          if ((!isNaN(unitBuyingPrice) && Number(unitBuyingPrice) > 0) || itemType === 'non-stock') {
            if (!isNaN(unitRetailSellingPrice) && Number(unitRetailSellingPrice) > 0) {
              if (!isNaN(unitWholesaleSellingPrice) && Number(unitWholesaleSellingPrice) > 0) {
                if (code.trim().length > 0 || itemType === 'non-stock') {
                  if (Number(amountPaid) <= Number(totalAmount)) {
                    setLoading(true);
                    await callApi(
                      "add-stock",
                      {
                        name,
                        code,
                        unit,
                        unitBuyingPrice,
                        unitRetailSellingPrice,
                        unitWholesaleSellingPrice,
                        quantity,
                        businessId: appContext.currentBusinessId,
                        amountPaid,
                        totalAmount,
                        vendorId: (data) ? data.id : "",
                        itemType,
                      }
                    ).then((response) => {
                      if (response.status === 1) {
                        appContext.tellMessage(STR_SUCCESS[appContext.language]);
                        appContext.getStockList();
                        appContext.getTotalStockValue();
                        setName("");
                        setCode("");
                        setUnit("");
                        setUnitBuyingPrice(0);
                        setUnitRetailSellingPrice(0);
                        setUnitWholesaleSellingPrice(0);
                        setQuantity("");
                        setAmountPaid(0);
                        setTotalAmount(0);
                        //setData(null);
                      } else {
                        appContext.tellError(response.msg);
                      }
                    })
                    setLoading(false);
                  } else {
                    appContext.tellError(STR_INVALID_AMOUNT_PAID[appContext.language])
                  }

                } else {
                  appContext.tellError(STR_INVALID_CODE[appContext.language])
                }
              } else {
                appContext.tellError(STR_INVALID_UNIT_PRICE[appContext.language])
              }
            } else {
              appContext.tellError(STR_INVALID_UNIT_PRICE[appContext.language])
            }
          } else {
            appContext.tellError(STR_INVALID_UNIT_PRICE[appContext.language])
          }
        } else {
          appContext.tellError(STR_INVALID_UNIT[appContext.language])
        }
      } else {
        appContext.tellError(STR_INVALID_NAME[appContext.language])
      }
    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language]);
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    setId(props.id)
  }, [props.id])

  useEffect(() => {
    if (appContext.allVendors) {
      getVendorOptions()
    }
  }, [appContext.allVendors])

  useEffect(() => {
    getTotalAmount();
  }, [unitBuyingPrice, quantity])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  useEffect(() => {
    if (itemType === 'non-stock') {
      setUnitBuyingPrice(0);
      setCode('');
      setAmountPaid(0);
      setTotalAmount(0);
      setQuantity(0);
    }
  }, [itemType])

  if (ready) {
    return (

      <div className="container mw-700">
        <h6 className="mSectionTitle">{STR_ADD_STOCK[appContext.language]}</h6>


        {
          (data) ?
            <div className="card">
              <div className="card-body">
                <h6
                  className='font-semi-bold'
                  style={{
                    fontSize: "16px",
                  }}
                >
                  <span style={{ textTransform: "uppercase", fontSize: "20px" }}>{data.name}</span>
                  <br />
                  <span className="text-muted">{data.phone}</span>
                </h6>
              </div>
            </div>
            : ""
        }

        <div className="row">

          <div className="col-md-12 mb-4">
            <label className="form-label">{STR_ITEM_TYPE[appContext.language]}*</label>
            <select
              className="form-control"
              value={itemType}
              onChange={(e) => setItemType(e.target.value)}
            >
              <option value="stock">Stock</option>
              <option value="non-stock">Non Stock</option>
            </select>
          </div>

          {
            (!props.id) ?
              <div className="col-md-12 mb-2 mt-2" style={{ display: (itemType === 'stock') ? 'block' : 'none' }}>
                <label className="form-label">{STR_VENDOR[appContext.language]}</label>

                <Select
                  className="form-control"
                  value={data ? { value: JSON.stringify(data), label: data.name } : null}
                  onChange={(_value) => {
                    if (_value) {
                      try {
                        setData(JSON.parse(_value.value));
                      } catch (error) {
                        setData(null);
                      }
                    } else {
                      setData(null);
                    }
                  }}
                  options={vendorOptions}
                  isClearable={true}

                  noOptionsMessage={() => STR_NO_OPTIONS[appContext.language]}
                />

              </div>
              : ""
          }

          <div className="col-md-6 mb-4" style={{ display: (itemType === 'stock') ? 'block' : 'none' }}>
            <label className="form-label">{STR_PRODUCT_CODE[appContext.language]}*</label>
            <input
              className="form-control"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="form-label">{STR_NAME[appContext.language]}*</label>
            <input
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="form-label">{STR_UNIT[appContext.language]}*</label>
            <input
              className="form-control"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
            />
          </div>

          <div className="col-md-6 mb-4" style={{ display: (itemType === 'stock') ? 'block' : 'none' }}>
            <label className="form-label">{STR_UNIT_BUYING_PRICE[appContext.language]}*</label>
            <input
              className="form-control"
              value={unitBuyingPrice}
              onChange={(e) => setUnitBuyingPrice(e.target.value)}

            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="form-label">{STR_UNIT_RETAIL_SELLING_PRICE[appContext.language]}*</label>
            <input
              className="form-control"
              value={unitRetailSellingPrice}
              onChange={(e) => setUnitRetailSellingPrice(e.target.value)}

            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="form-label">{STR_UNIT_WHOLESALE_SELLING_PRICE[appContext.language]}*</label>
            <input
              className="form-control"
              value={unitWholesaleSellingPrice}
              onChange={(e) => setUnitWholesaleSellingPrice(e.target.value)}

            />
          </div>

          <div className="col-md-6 mb-4" style={{ display: (itemType === 'stock') ? 'block' : 'none' }}>
            <label className="form-label">{STR_AVAILABLE_QUANTITY[appContext.language]}*</label>
            <input
              className="form-control"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}

            />
          </div>

          <div className="col-md-6 mb-4" style={{ display: (itemType === 'stock') ? 'block' : 'none' }}>
            <label className="form-label">{STR_TOTAL_AMOUNT[appContext.language]}</label>
            <input
              className="form-control"
              value={totalAmount}
              onChange={(e) => setTotalAmount(e.target.value)}

              readOnly={true}
            />
          </div>

          <div className="col-md-6 mb-4" style={{ display: (itemType === 'stock') ? 'block' : 'none' }}>
            <label className="form-label">{STR_AMOUNT_PAID[appContext.language]}</label>
            <input
              className="form-control"
              value={amountPaid}
              onChange={(e) => setAmountPaid(e.target.value)}

            />
          </div>

          <div className="col-md-12 mb-4 text-end">
            <button
              className="btn btn-primary btn-padded btn-rounded"
              onClick={addStock}
            >
              {STR_SUBMIT[appContext.language]}
            </button>
          </div>

        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}